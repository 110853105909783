import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Card, Divider, Grid, IconButton, Typography } from '@mui/material';
import globals from '../../styles/globals';
import '../../styles/styles.css';
import { IMAGES_URL } from '../../constants/constants';
import ReadSpeakerComponent from '../ReadSpeaker';
import SurveyForm from '../PageComponents/MyTasksContent/SurveyForm';
import { AuthContext } from '../../context/authcontext';
import PreviousSubmissions from '../PageComponents/MyTasksContent/PreviousSubmissions';
import Recommended from '../RecommendedList';
import LeadGuide from '../LeadGuideForm/LeadGuide';
import { getLeadGuideApi } from '../../api/auth';
import Act2Rec1 from '../../assets/lead assets/act2_rec_1.jpg';
import Act2Rec3 from '../../assets/lead assets/act2_rec_3.jpg';
import { Modal } from 'antd';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';

const Activity2: React.FC = () => {
    const { selectedData, tasksGlobalData, formDataAll, setFormDataAll, user, selectedUser, largeFontSize, mediumFontSize, smallFontSize } = useContext(AuthContext);
    const [leadFormData, setLeadFormData] = useState()
    const [openModal, setOpenModal] = useState(false);
    const [embedUrl, setEmbedUrl] = useState("");
    const data = selectedData ? selectedData?.data?.currentTask : tasksGlobalData?.progressData[tasksGlobalData?.currentTask]; 
    const[resourcesBlurbs, setResourcesBlurbs] = useState<any>([
        [ 
          {link: "https://www.youtube.com/watch?v=Eq_Er-tqPsA", linkLabel: "Understand Alzheimer’s disease: ", desc: "This video describes the cause of Alzheimer’s disease and how it progresses through the brain.", img: `${IMAGES_URL}recommended1.png`},
          {link: "", linkLabel: "Understanding different types of dementia: ", desc: "This infographic compares four different types of dementia and how they affect the brain.", img: `${IMAGES_URL}recommended1.png`},
          {link: "", linkLabel: "5 Myths about advance care planning: ", desc: "This infographic explains common misconceptions about advance care planning.", img: `${IMAGES_URL}recommended1.png`},
        ],
        [ 
          {link: "Signs of Alzheimer's Disease NIA.pdf", org: "NIA", linkLabel: "Stages of Alzheimer’s disease: ", desc: "This article explains what to expect at each stage of Alzheimer’s disease.", img: `${Act2Rec1}`},
          {link: "Getting Your Affairs in Order Checklist NIA.pdf", org: "NIA", linkLabel: "Documents to prepare for the future: ", desc: "This PDF helps you get your legal affairs in order and lists other important documents and care details.", img: `${Act2Rec3}`},
          {link: "next-steps-after-alzheimers-diagnosis.pdf", org: "NIH", linkLabel: "Next steps after Alzheimer’s diagnosis: ", desc: "This infographic provides suggestions and resources to help plan for life with Alzheimer’s disease.", img: `${IMAGES_URL}recommended2.png`},
        ],
        [ 
          {link: "https://www.nia.nih.gov/health/alzheimers-caregiving-changes-communication-skills", linkLabel: "Changes in communication skills: ", desc: "This article explains common communications issues in Alzheimer’s disease and how care partners can help.", img: `${IMAGES_URL}recommended3.png`},
          {link: "https://www.nia.nih.gov/health/making-decisions-someone-end-life?utm_source=nia-eblast&utm_medium=email&utm_campaign=caregiving-20220407", linkLabel: "Making decisions for someone else: ", desc: "This article offers guidance for caregivers on making end-of-life decisions.", img: `${IMAGES_URL}recommended3.png`},
          {link: "https://www.alzheimers.gov/life-with-dementia/planning-for-future?utm_source=nia-eblast&utm_medium=email&utm_campaign=alzgov-20220412#financial-planning", linkLabel: "Life with dementia: ", desc: "This website defines legal documents, describes medical decisions, and explains advance care planning documents.", img: `${IMAGES_URL}recommended3.png`},
        ],
        [ 
          {link: "https://www.compassionandchoices.org/docs/default-source/default-document-library/dementia-provision-only-final-6-29-20-pdf.pdf?sfvrsn=2aebdcb5_2", linkLabel: "Dementia Provision (Advance Directive Addendum): ", desc: "This addendum is for those with Alzheimer’s disease or another type of dementia.", img: `${IMAGES_URL}recommended4.png`},
          {link: "https://www.alz.org/help-support/resources/alznavigator", linkLabel: "Alzheimer’s Navigator: ", desc: "This resource helps build an action plan for all stages of Alzheimer’s disease.", img: `${IMAGES_URL}recommended4.png`},
          {link: "https://www.nia.nih.gov/health/choosing-health-care-proxy", linkLabel: "Choosing a health care proxy: ", desc: "This article details how to choose someone to make health care decisions on your behalf.", img: `${IMAGES_URL}recommended4.png`},
        ]
        ]);
    const taskVidRef = useRef<HTMLDivElement | null>(null);
    const recRef = useRef<HTMLDivElement | null>(null);
    const leadGuide = useRef<HTMLDivElement | null>(null);
    const formRef = useRef<HTMLDivElement | null>(null);
    const [taskInsClass, setTaskInsClass] = useState("");
    const taskContent = document.getElementById('tasks');
    const taskCard = document.getElementById('activityInsCard');
    let lastScrollPosition = -100;
    let leftPosition = 0; // Initial left position in percentage (centered)
    const tasksGridRef = useRef<HTMLDivElement | null>(null);
    // if (tasksGridRef.current)
    const [showFullContent, setShowFullContent] = useState(true);
    const [isLeft, setIsLeft] = useState(false);
    const [cardSize, setCardSize] = useState(240); 
    const [checkvalue, setcheckvalue] = useState(25);
    useEffect(() => {
      if(largeFontSize){
        if(largeFontSize==25){
          setCardSize(240);
          setcheckvalue(largeFontSize)
        }
        if(largeFontSize > checkvalue){
          setCardSize(cardSize+10 );
          setcheckvalue(largeFontSize)
        }else{
          setCardSize(cardSize-10 );
          setcheckvalue(largeFontSize)
        }
      }
      
      
    }, [largeFontSize]);
    const toggleFullContent = () => {
      setShowFullContent(!showFullContent);
    };
    useEffect(()=> {
      getLeadGuideApi(selectedUser, user?.token, "activity2", false).then((res:any)=> {
        setFormDataAll(res);
      })
      setTimeout(() => {
        tasksGridRef.current?.scrollIntoView({ behavior: 'smooth', block: "end" })
      }, 2000);
    },[]);
    
    // window.addEventListener('scroll', () => {
    //   const currentScrollPosition = window.scrollY;

    //   if (currentScrollPosition > lastScrollPosition) {
    //     // Scrolling down
    //     leftPosition -= 1; // Adjust the value as needed for smoother/faster movement
    //     if (leftPosition < 0 && window.scrollY > 200) {
    //       leftPosition = 0;
    //     }
    //   } else {
    //     // Scrolling up
    //     leftPosition += 1; // Adjust the value as needed
    //   }
    //   leftPosition = Math.max(-25, Math.min(0, leftPosition));
    //   if(taskContent){
    //     taskContent.style.left = `${leftPosition}%`;
    //     if(window.scrollY > 200){
    //       setIsLeft(true);
    //       taskContent.style.position = `fixed`;
    //       taskContent.style.width = `min-content`;
    //       setTaskInsClass("taskInsClass");
    //       if(taskCard){
    //         taskCard.style.width = `min-content`;
    //         taskCard.style.height = `fit-content`;
    //       }
    //     }
    //     else{
    //       setIsLeft(false);
    //       taskContent.style.position = `relative`;
    //       setTaskInsClass("");
    //       taskContent.style.width = `auto`;
    //       taskContent.style.left = `${leftPosition}%`;
    //       if(taskCard){
    //         taskCard.style.width = `auto`;
    //         taskCard.style.height = `auto`;
    //       }
    //     }
    //   }
    //   lastScrollPosition = currentScrollPosition;
    // });


    const scrollBeh = (index: number) => {
        if(index ===0)
            taskVidRef.current?.scrollIntoView({behavior: "smooth", block: "center"});
        else if(index ===1)
            leadGuide.current?.scrollIntoView({behavior: "smooth", block: "center"});
        else if(index ===2)
            recRef.current?.scrollIntoView({behavior: "smooth", block: "center"});
        else if(index ===3)
            formRef.current?.scrollIntoView({behavior: "smooth", block: "center"});
    }

    return (
        <>
        {selectedData?.previousSubmissions ? (
            <>
                <Grid id="task-content" sx={{ width: "100%" }}>
                    <PreviousSubmissions data={data?.taskFormFields} />
                </Grid>
            </>
        ): data ? (
            <>
            <Grid sx={{ width: "100%" }}>
              <Grid id="task-content" sx={{ width: "100%" }}>
                <ReadSpeakerComponent readId='readspeaker_task-content' readClass='' compId='task-content'/>
                <div ref={tasksGridRef}>
                  {/* {isLeft ? (
                      <Grid id="tasks" container spacing={2} sx={{ marginTop: 15, zIndex: 1, transition: "1s linear", width: 'min-content', position: 'fixed', marginLeft: "0px" }}>
                      {showFullContent && (
                        <>
                    <Box sx={{ textAlign: 'center', width: "100%" }}>
                      <Typography variant='h3' sx={{ ...globals.heading.largeText, fontSize: largeFontSize }}>{data?.taskName}</Typography>
                    </Box>
                    <Grid item md={12} sx={{ position: "relative" }}>
                      <Card style={{height: "max-content", width: "fit-content" ,overflowY: "auto", maxHeight: "320px"}} className='cardTask' id="activityInsCard">
                        <Grid>
                          <img alt='info icon' src={`${IMAGES_URL}info.png`} style={{ width: 35 }} />
                          <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize }}>Instructions</Typography>
                          <Typography className={`${taskInsClass}`} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>Your tasks this week are:</Typography>
                          <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(0)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>1. Watch the Activity 2 Instruction Video.</Typography>
                          <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(1)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>2. {user?._doc.userType === 0 ? `Complete the LEAD Guide as you were the ${user?._doc.userAssociated[0].name}. How do you think they'll answer the questions?` : "Complete your LEAD Guide."}</Typography>
                          <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(2)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>3. Review Recommended Resources</Typography>
                          <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(3)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>4. Complete the Activity 2 Feedback Form.</Typography>
                          <Typography className={`${taskInsClass}`} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer", mt: 2 }}>It will take about 60 minutes to complete these tasks.</Typography>
                        </Grid>
                      </Card>
                    </Grid>
                    </>
                  )}
                  <IconButton onClick={toggleFullContent} sx={{ position: 'absolute',top: '17%', left: "20px", padding: "0", zIndex: 10, background: "linear-gradient(180deg, #FFBF00 0%, #F6E1A4 100%)", width: showFullContent?"6px":"40px", height: showFullContent? "-webkit-fill-available":"40px", borderRadius: showFullContent?"10px 0 0 10px":"20px" }}>
                    {showFullContent ? <ArrowLeft /> : <ArrowRight />}
                  </IconButton>
                  </Grid>
                  ) : ( */}
                  <Grid id="tasks" container spacing={2} sx={{ marginTop: 15, zIndex: 1, transition: "1s linear" }}>
                    <Box sx={{ textAlign: 'center', width: "100%" }}>
                      <Typography variant='h3' sx={{ ...globals.heading.largeText, fontSize: largeFontSize }}>{data?.taskName}</Typography>
                    </Box>
                    <Grid item md={12} sx={{ position: "relative" }}>
                      <Card className='cardTask' id="activityInsCard" style={ { height: `${cardSize}px` ,overflowY: "auto", maxHeight: "240px"}}>
                        <Grid>
                          <img alt='info icon' src={`${IMAGES_URL}info.png`} style={{ width: 35 }} />
                          <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize }}>Instructions</Typography>
                          {/* {data?.taskDescription.split("\n").map((item:any,index:number) => ( */}
                          <Typography className={`${taskInsClass}`} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>Your tasks this week are:</Typography>
                          <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(0)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>1. Watch the Activity 2 Instruction Video.</Typography>
                          <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(1)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>2. {user?._doc.userType === 0 ? `Complete the LEAD Guide as you were ${user?._doc.userAssociated[0].name}. How do you think they'll answer the questions?` : "Complete your LEAD Guide."}</Typography>
                          <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(2)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>3. Review Recommended Resources</Typography>
                          <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(3)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>4. Complete the Activity 2 Feedback Form.</Typography>
                          <Typography className={`${taskInsClass}`} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer", mt: 2 }}>It will take about 60 minutes to complete these tasks.</Typography>
                          {/* ))} */}
                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>
                  {/* )} */}
                </div>
                {/* <Typography sx={{ ...globals.body.mediumText, whiteSpace: "break-spaces" }}>{data?.taskInstructions}</Typography> */}
                <Divider sx={{ marginY: 8 }} />
                <Box sx={{ position: "relative", marginBottom: 2 }}>                  
                  <Typography ref={taskVidRef} sx={{ ...globals.heading.largeText, fontSize: largeFontSize, textAlign: "center" }}>Task 1- Activity 2 Instructions</Typography>
                  <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(0)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" ,textAlign: "center", marginBottom: 5 }}>Watch the Activity 2 Instruction Video.</Typography>
                  <Typography sx={{...globals.heading.mediumText, fontSize: mediumFontSize, color: "black", marginBottom: "10px"}}><b><i>To view the video in full screen mode click on the expanding box at the bottom right corner of the video window.</i></b></Typography>
                  <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black", marginBottom: "10px" }}><b><i>Watch this video until the end or pause it before moving onto the next video.</i></b></Typography>
                  
                  <div style={{position:"relative",paddingTop:"56.25%" }}><iframe src=" https://share.descript.com/embed/mqJPLUV3Xa1" style={{ border:"1px solid",position:"absolute",top:0,height:"100%",width:"100%"}} allowFullScreen></iframe></div>                  
                  <br></br>
                  <br></br>
                </Box>
                <Divider sx={{ marginY: 5 }} />
                <div>
                  <Typography ref={leadGuide} sx={{ ...globals.heading.largeText, fontSize: largeFontSize, textAlign: "center"}}>Task 2- The LEAD Guide</Typography>
                  <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(1)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer", textAlign: "center", marginBottom: 5}}>{user?._doc.userType === 0 ? `Complete the LEAD Guide as you were ${user?._doc.userAssociated[0].name}. How do you think they'll answer the questions?` : "Complete your LEAD Guide."}</Typography>
                     {new Date(data?.startDate)?.getTime() < new Date().getTime() || data?.startDate ===null ? <LeadGuide activity={2} isShowHeader={false}/> : `You can't complete this LEAD Guide form until ${new Date(data?.startDate)}`}
                </div>
                <Divider sx={{ marginY: 5 }} />
                <div ref={recRef}>
                <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize, textAlign: "center"}}>Task 3- Recommended Resources</Typography>
                <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(2)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer", textAlign: "center", marginBottom: 5  }}>Review Recommended Resources</Typography>
                <Recommended
      data={resourcesBlurbs[1]} evenModal={(link)=> {setOpenModal(true); setEmbedUrl(link)}}/>

                </div>
                <Divider sx={{ marginY: 5 }} />
                <div style={{width: "95%", margin: "auto"}} >
                <Typography ref={formRef} sx={{ ...globals.heading.largeText, fontSize: largeFontSize, textAlign: "center"}}>Task 4 - Activity 2 Feedback Form</Typography>
                <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(3)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" , textAlign: "center", marginBottom: 5 }}>Complete the Activity 2 Feedback Form.</Typography>
                <SurveyForm activity={2} userId={data?.userId} taskId={data?.taskId} formValues={data?.taskFormFields[data?.taskFormFields?.length - 1]} />

                </div>
              </Grid>
            </Grid>
            <Modal
              title=""
              centered
              open={openModal}
              okButtonProps={{style: {display: "none"}}}
              onOk={() => setOpenModal(false)}
              onCancel={() => setOpenModal(false)}
              width={'80%'}
              style={{ maxHeight: '200vh' }}
              cancelText="Close"
            >
            { embedUrl !=="" ?
              <iframe title="Embedded Content" width="100%" height="700px" src={embedUrl} frameBorder="0" allowFullScreen></iframe>
              :
              <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize }}>Couldn't find link to the source</Typography>

            }
            </Modal>
          </>
          )
          :
          <Typography variant='h3' sx={{ ...globals.heading.largeText, fontSize: largeFontSize }}>All Tasks Are Completed!</Typography>
        }
        </>
    );
};

export default Activity2;