import React, { useContext,useState,useEffect } from 'react';
import { Card, CardMedia, CardContent, Typography, Button, Divider, Grid, Box } from '@mui/material';
import colors from '../../styles/colors';
import globals from '../../styles/globals';
import '../../styles/styles.css';
import { IMAGES_URL } from '../../constants/constants';
import { AuthContext } from '../../context/authcontext';

interface CustomCardProps {
    image: string;
    title: string;
    text: string;
    buttonText: string;
    activityType: string;
    completeTaskData: any;
}

const styles = {
    card: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        borderRadius: 2,
      },
      media: {
        height: 200,
        backgroundPosition: "0px -28px"
      },
      content: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
      divider: {
        marginTop: 2,
        marginBottom: 2,
      },
      bottomRow: { 
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    }
}

const TaskCard: React.FC<CustomCardProps> = ({ image, title, text, buttonText, activityType, completeTaskData }) => {
  const { setSelectedCardIndex, setSelectedData, user, smallFontSize, xSmallFontSize , mediumFontSize} = useContext(AuthContext);       
  
  const handleTask = () => {   
    if ((buttonText === "Completed" || buttonText === "Start") && completeTaskData) {      
      setSelectedCardIndex(1);
      setSelectedData({
          route: 'MyTasks',
          data: { currentTask: completeTaskData }
      });
    } 
  }
  const [cardSize, setCardSize] = useState(350);
  const [checkvalue, setcheckvalue] = useState(16);
  const [pad ,setPad]=useState(2)
  useEffect(() => {
    if (smallFontSize) {
      console.log("small font size :", smallFontSize)
      if (smallFontSize == 16) {
        setCardSize(40);
        setcheckvalue(smallFontSize)
        setPad(pad+3)
      }
      if (smallFontSize > checkvalue) {
        setCardSize(cardSize + 10);
        setcheckvalue(smallFontSize)
      } else {
        setCardSize(cardSize - 10);
        setcheckvalue(smallFontSize)
      }
    }
  }, [smallFontSize])
  useEffect(() => {

    const timer = setTimeout(() => {
      if (smallFontSize === 16) {
        console.log("Reset called :");
        setCardSize(40);
        // setCardWidth(300);
        setcheckvalue(smallFontSize);
      }
    }, 1000);

    // Clean up the timeout if the component unmounts or the effect dependencies change
    return () => clearTimeout(timer);

  }, [smallFontSize]);
  return (
    <Card onClick={handleTask} className='cursor' sx={{...styles.card, border: `1px solid ${colors.aqua}`, borderColor: buttonText === 'Completed' ? colors.aqua : buttonText === 'Pending' ? colors.error : colors.gold }} >
      <CardMedia sx={{...styles.media, backgroundPosition: title==="Activity 2" || title==="Activity 3"?"0px -95px": title==="Activity 1" ? "0px -10px" : "0px -28px"}} image={image} title="Image" />
      <CardContent sx={styles.content}>
        <Box onClick={handleTask}>
            <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" >
                <Typography variant="h6" style={{...globals.heading.smallText, fontSize: smallFontSize!!, fontWeight: 700}}>
                    {title}
                </Typography>
                {buttonText === 'Completed' || buttonText === 'Pending' || buttonText === 'Inactive' ? (
                  <Typography onClick={handleTask} variant="h6" style={{...globals.heading.smallText, fontSize: smallFontSize!!, color: buttonText === 'Completed' ? colors.aqua : 'red', fontWeight: 700}}>
                      {buttonText}
                  </Typography>
                ) : (
                  <Button onClick={handleTask} variant="contained" sx={{...globals.button, fontSize: xSmallFontSize, padding: 0 }}>
                      {buttonText}
                  </Button>
                )}
            </Grid>
            <Typography className='ellipsis-3' variant="body2" mt={2} style={{...globals.body.mediumText,  paddingBottom:`${pad}px`,fontSize: mediumFontSize!!}}>{title !== "Activity 2" ? text : user?._doc.userType === 1 ? "Complete the LEAD Guide for yourself. Do not discuss your answers with your activity partner.": "Complete the LEAD Guide from the perspective of the patient. Do not discuss your answers with your activity partner"}</Typography>
        </Box>
        <Divider sx={styles.divider} />
        <Box sx={styles.bottomRow}>
            {activityType === 'Solo activity' ? (
              <img src={`${IMAGES_URL}MyDocumentsImages/Solo.png`} alt={activityType} width={24} height={24} />
            ) : (
              <img src={`${IMAGES_URL}MyDocumentsImages/Partnered.png`} alt={activityType} width={24} height={24} />
            )}
            <Typography variant="body2" style={{...globals.heading.mediumText, fontSize: mediumFontSize!!, marginLeft: 10}}>{activityType}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TaskCard;