import React, { useContext, useEffect, useState } from 'react';
import { Typography, Box, Card, CardContent, TextField, Button, Divider, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import Navbar from '../../components/Navbar';
import {signIn, signInAdmin} from '../../api/auth';

import colors from '../../styles/colors';
import globals from '../../styles/globals';
import { AuthContext } from '../../context/authcontext';
import { useNavigate } from 'react-router-dom';

// Styles
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  fixedElements: {
    zIndex: 100,
  },
  loginBody: {
    width: "80%",
    margin: "auto"
  },
};


const Login: React.FC = () => {

    const {login, isAuthenticated} = useContext(AuthContext);
    const navigate = useNavigate();
    const [form, setForm] = useState({userName:"", pass:""});
    const [isValidEmail, setIsValidEmail] = useState(false)
    const [isAdminChecked, setIsAdminChecked] = useState(false)
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
    useEffect(()=> {
      if(isAuthenticated)
      navigate("/");
    }, [isAuthenticated, navigate])
  
    const handleCardClick = () => {

      if(isAdminChecked)
      signInAdmin(form.userName, form.pass).then((res:any)=> {
        if(res){
          navigate("/");
          login(res);
        }
      }).catch(err=> {
        alert("Invalid username or password. If you are not a site administrator, make sure the “Sign in as Admin” box is unchecked and try your username and password again.")
      })

      else
      signIn(form.userName, form.pass, navigator.userAgent).then((res:any)=> {
        if(res){
          navigate("/");
          login(res);
        }
      }).catch(err=> {
        alert("Invalid email or password")
      })
    };

  return (
    <Box sx={styles.container}>
      <div style={styles.fixedElements}>
        <Navbar login={isAuthenticated}/>
      </div>
      {/* <MyChildren props={user}/> */}
      <Card sx={styles.loginBody}>
      <CardContent>
        <Typography sx={{...globals.heading.mediumText, fontWeight: "400"}}>Sign Into Your Account</Typography>
        <Divider sx={{background: colors.dark, padding: "5px", marginBottom: "10px"}}/>
        <TextField
          label="UserName"
          variant="outlined"
          error={isValidEmail}
          fullWidth
          required
          onChange={(e)=> {
              // setIsValidEmail(!emailPattern.test(e.target.value))
              setForm({...form, userName: e.target.value})
            }
          }
        />
        <TextField
          label="Password"
          variant="outlined"
          margin="normal"
          type="password"
          fullWidth
          required
          onChange={(e)=> setForm({...form, pass: e.target.value})}
        />
          <br></br>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={isAdminChecked} onChange={(event) => setIsAdminChecked(event.target.checked)} title='Admin Signin'/>
              }
              label="Sign In as Admin"
            />
          </FormGroup>
          <br></br>
        <Button
          variant="contained"
          sx={{background: colors.dark, borderRadius: 0}}
          size="large"
          disabled={isValidEmail}
          onClick={handleCardClick}
        >
          Sign In
        </Button>
      </CardContent>
    </Card>
    </Box>
  );
};

export default Login;