import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Card, Divider, Grid, IconButton, Typography } from '@mui/material';
import globals from '../../styles/globals';
import '../../styles/styles.css';
import { IMAGES_URL } from '../../constants/constants';
import troubleshoot1 from '../../assets/lead assets/zoomT1.png';
import changecam from '../../assets/lead assets/changecam.jpeg';
import Mute from '../../assets/lead assets/Mute.jpeg';
import audio from '../../assets/lead assets/audio.jpeg';
import UnMute from '../../assets/lead assets/UnMute.jpeg';
import microphone from '../../assets/lead assets/microphone.jpeg';
import troubleMute from '../../assets/lead assets/TroubleMute.png';
import troubleUnmute from '../../assets/lead assets/TroubleUnmute.png';
import troubleMicTest from '../../assets/lead assets/troubleTestMic.png';
import troubleStartVideo from '../../assets/lead assets/troubleStartVideo.png';
import troubleStopVideo from '../../assets/lead assets/troubleStopVideo.jpg';
import troubleVideoTest from '../../assets/lead assets/troubleVideoTest.png';
import threedot from '../../assets/lead assets/threedot.jpeg';
import ReadSpeakerComponent from '../ReadSpeaker';
import SurveyForm from '../PageComponents/MyTasksContent/SurveyForm';
import { AuthContext } from '../../context/authcontext';
import PreviousSubmissions from '../PageComponents/MyTasksContent/PreviousSubmissions';
import Recommended from '../RecommendedList';
import MeetingWindow from '../Meeting/MeetingWindow';
import { getLeadGuideApi } from '../../api/auth';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Act3Rec2 from '../../assets/lead assets/act3_rec_2.jpg';
import Act3Rec3 from '../../assets/lead assets/act3_rec_3.jpg';
import { Modal } from 'antd';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';

const Activity3: React.FC = () => {
  const { selectedData, tasksGlobalData, user, setFormDataAll, selectedUser, largeFontSize, mediumFontSize, smallFontSize } = useContext(AuthContext);
  const data = selectedData ? selectedData?.data?.currentTask : tasksGlobalData?.progressData[tasksGlobalData?.currentTask];
  const taskVidRef = useRef<HTMLDivElement | null>(null);
  const zoomVidRef = useRef<HTMLDivElement | null>(null);
  const makeChangesZoomVidRef = useRef<HTMLDivElement | null>(null);
  const taskExVidRef = useRef<HTMLDivElement | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [embedUrl, setEmbedUrl] = useState("");
  const [taskInsClass, setTaskInsClass] = useState("");
  const [formData, setFormData] = useState();
  const [showFullContent, setShowFullContent] = useState(true);
  const [isLeft, setIsLeft] = useState(false);

  const toggleFullContent = () => {
    setShowFullContent(!showFullContent);
  };

  const [resourcesBlurbs, setResourcesBlurbs] = useState<any>([
    [
      { link: "https://www.youtube.com/watch?v=Eq_Er-tqPsA", linkLabel: "Understand Alzheimer’s disease: ", desc: "This video describes the cause of Alzheimer’s disease and how it progresses through the brain.", img: `${IMAGES_URL}recommended1.png` },
      { link: "", linkLabel: "Understanding different types of dementia: ", desc: "This infographic compares four different types of dementia and how they affect the brain.", img: `${IMAGES_URL}recommended1.png` },
      { link: "", linkLabel: "5 Myths about advance care planning: ", desc: "This infographic explains common misconceptions about advance care planning.", img: `${IMAGES_URL}recommended1.png` },
    ],
    [
      { link: "https://www.hopkinsmedicine.org/health/conditions-and-diseases/alzheimers-disease/stages-of-alzheimer-disease", linkLabel: "Stages of Alzheimer’s disease: ", desc: "This article explains what to expect at each stage of Alzheimer’s disease.", img: `${IMAGES_URL}recommended2.png` },
      { link: "", linkLabel: "Next steps after Alzheimer’s diagnosis: ", desc: "This infographic provides suggestions and resources to help plan for life with Alzheimer’s disease.", img: `${IMAGES_URL}recommended2.png` },
      { link: "https://www.nia.nih.gov/health/getting-your-affairs-order-checklist-documents-prepare-future?utm_source=nia-eblast&utm_medium=email&utm_campaign=caregiving-2023019#important-papers", linkLabel: "Documents to prepare for the future: ", desc: "This website helps you get your legal affairs in order and lists other important documents and care details.", img: `${IMAGES_URL}recommended2.png` },
    ],
    [
      { link: "Alz Care Giving Changes in Communication Skills.pdf", org: "NIH", linkLabel: "Changes in communication skills: ", desc: "This article explains common communications issues in Alzheimer’s disease and how care partners can help.", img: `${IMAGES_URL}recommended3.png` },
      { link: "Making Decisions for Someone at the End of Life -NIA.pdf", org: "NIH", linkLabel: "Making decisions for someone else: ", desc: "This article offers guidance for caregivers on making end-of-life decisions.", img: `${Act3Rec2}` },
      { link: "Planning after a dementia diagnosis - Alz.gov.pdf", org: "Alzheimers.gov", linkLabel: "Life with dementia: ", desc: "This website defines legal documents, describes medical decisions, and explains advance care planning documents.", img: `${Act3Rec3}` },
    ],
    [
      { link: "https://www.compassionandchoices.org/docs/default-source/default-document-library/dementia-provision-only-final-6-29-20-pdf.pdf?sfvrsn=2aebdcb5_2", linkLabel: "Dementia Provision (Advance Directive Addendum): ", desc: "This addendum is for those with Alzheimer’s disease or another type of dementia.", img: `${IMAGES_URL}recommended4.png` },
      { link: "https://www.alz.org/help-support/resources/alznavigator", linkLabel: "Alzheimer’s Navigator: ", desc: "This resource helps build an action plan for all stages of Alzheimer’s disease.", img: `${IMAGES_URL}recommended4.png` },
      { link: "https://www.nia.nih.gov/health/choosing-health-care-proxy", linkLabel: "Choosing a health care proxy: ", desc: "This article details how to choose someone to make health care decisions on your behalf.", img: `${IMAGES_URL}recommended4.png` },
    ]
  ]);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const taskContent = document.getElementById('tasks');
  const taskCard = document.getElementById('activityInsCard');
  const taskCardIns = document.getElementById('activityInsCard');
  const recRef = useRef<HTMLDivElement | null>(null);
  const leadGuide = useRef<HTMLDivElement | null>(null);
  let lastScrollPosition = -100;
  let leftPosition = 0; // Initial left position in percentage (centered)
  const tasksGridRef = useRef<HTMLDivElement | null>(null);
  // if (tasksGridRef.current)
  const formRef = useRef<HTMLDivElement | null>(null);
  const [cardSize, setCardSize] = useState(240);
  const [checkvalue, setcheckvalue] = useState(25);
  useEffect(() => {
    if (largeFontSize) {
      if (largeFontSize == 25) {
        setCardSize(240);
        setcheckvalue(largeFontSize)
      }
      if (largeFontSize > checkvalue) {
        setCardSize(cardSize + 10);
        setcheckvalue(largeFontSize)
      } else {
        setCardSize(cardSize - 10);
        setcheckvalue(largeFontSize)
      }
    }
  }, [largeFontSize])

  useEffect(() => {
    setTimeout(() => {

      tasksGridRef.current?.scrollIntoView({ behavior: 'smooth', block: "end" })
    }, 2000);
  }, []);

  useEffect(() => {
    let isCP = selectedUser === user?._doc._id ? false : true;
    getLeadGuideApi(selectedUser, user?.token, "activity3", isCP).then(async (res: any) => {
      setFormDataAll(res);
      const meeting_status = localStorage.getItem('move')
      if (meeting_status) {
        alert('Now you can restart your Zoom meeting with your activity partner.')
        localStorage.removeItem('move');
        
      }
    })
  }, [])

  // window.addEventListener('scroll', () => {
  //   const currentScrollPosition = window.scrollY;

  //   if (currentScrollPosition > lastScrollPosition) {
  //     // Scrolling down
  //     leftPosition -= 1; // Adjust the value as needed for smoother/faster movement
  //     if (leftPosition < 0 && window.scrollY > 200) {
  //       leftPosition = 0;
  //     }
  //   } else {
  //     // Scrolling up
  //     leftPosition += 1; // Adjust the value as needed
  //   }
  //   leftPosition = Math.max(-25, Math.min(0, leftPosition));
  //   if (taskContent) {
  //     taskContent.style.left = `${leftPosition}%`;
  //     if (window.scrollY > 200) {
  //       setIsLeft(true);

  //       taskContent.style.position = `fixed`;
  //       taskContent.style.width = `min-content`;
  //       setTaskInsClass("taskInsClass");
  //       if (taskCard) {
  //         taskCard.style.width = `min-content`;
  //         taskCard.style.height = `fit-content`;
  //       }
  //     }
  //     else {
  //       setIsLeft(false);

  //       taskContent.style.position = `relative`;
  //       setTaskInsClass("");
  //       taskContent.style.width = `auto`;
  //       taskContent.style.left = `${leftPosition}%`;
  //       if (taskCard) {
  //         taskCard.style.width = `auto`;
  //         taskCard.style.height = `auto`;
  //       }
  //     }
  //   }
  //   lastScrollPosition = currentScrollPosition;
  // });


  const scrollBeh = (index: number) => {
    if (index === 1)
      taskVidRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    if (index === 2)
      taskExVidRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    else if (index === 3)
      zoomVidRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    else if (index === 4)
      leadGuide.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    else if (index === 5)
      leadGuide.current?.scrollIntoView({ behavior: "smooth" });
    else if (index === 6)
      recRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    else if (index === 7)
      formRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <>
      {selectedData?.previousSubmissions ? (
        <>
          <Grid id="task-content" sx={{ width: "100%" }}>
            <PreviousSubmissions data={data?.taskFormFields} />
          </Grid>
        </>
      ) : data ? (
        <>
          <Grid sx={{ width: "100%" }}>
            <Grid id="task-content" sx={{ width: "100%" }}>
              <ReadSpeakerComponent readId='readspeaker_task-content' readClass='' compId='task-content' />
              <div ref={tasksGridRef}>
                {/* {isLeft ? (
                  <Grid id="tasks" container spacing={2} sx={{ marginTop: 5, zIndex: 1, transition: "1s linear", width: 'min-content', position: 'fixed', marginLeft: "0px" }}>
                    {showFullContent && (
                      <>
                        <Box sx={{ textAlign: 'center', width: "100%" }}>
                          <Typography variant='h3' sx={{ ...globals.heading.largeText, fontSize: largeFontSize }}>{data?.taskName}</Typography>
                        </Box>
                        <Grid item md={12} sx={{ position: "relative" }}>
                          <Card style={{ height: "max-content", width: "fit-content" ,overflowY: "auto", maxHeight: "320px"}} className='cardTask' id="activityInsCard">
                            <Grid>
                              <img alt='info icon' src={`${IMAGES_URL}info.png`} style={{ width: 35 }} />
                              <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize }}>Instructions</Typography>
                             
                              <Typography className={`${taskInsClass}`} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>Your tasks this week are:</Typography>
                              <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(1)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}><li><span style={{ fontWeight: 'bold' }}>Watch the Activity 3 Instruction Video.</span></li></Typography>
                              <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(2)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>2. <span style={{ fontWeight: 'bold' }}>Watch the Effective Communication videos</span> to learn helpful tips about talking with your activity partner about what kind of care they prefer. The videos are short and informative.</Typography>
                              <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(3)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>3. <span style={{ fontWeight: 'bold' }}>Watch the Using Zoom video.</span> There is also Zoom Troubleshooting information for more help using Zoom.</Typography>
                              <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(4)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>4. <span style={{ fontWeight: 'bold' }}>Compare and discuss your LEAD Guide answers.</span> <span style={{textDecoration: "underline"}}>Join the Zoom meeting </span>{` with your activity partner. If you are together and only using one computer for this meeting, make sure you are logged into the LEAD website using ${user?._doc.userType ===0 ? user._doc.userAssociated[0].name : user?._doc.name} login information.`}</Typography>
                              <Typography
                                className={`${taskInsClass}`}
                                onClick={() => scrollBeh(4)}
                                sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}
                              >
                                5. {user?._doc.userType === 0 ? (
                                  <>
                                    <span style={{ fontWeight: 'bold' }}>Make changes to LEAD Guide.</span> Based on your conversation, {user?._doc.userAssociated[0].name} may want to make changes to their LEAD Guide. This is not required. If {user?._doc.userAssociated[0].name} is happy with their answers, no changes are needed.
                                  </>
                                ) : (
                                  <>
                                    <span style={{ fontWeight: 'bold' }}>Make changes to LEAD Guide.</span> Based on your conversation, {user?._doc.name} may want to make changes to their LEAD Guide. This is not required. If {user?._doc.name} is happy with their answers, no changes are needed.
                                  </>
                                )}
                              </Typography>
                              <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(6)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>6. <span style={{ fontWeight: 'bold' }}>Review Recommended Resources.</span></Typography>
                              <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(7)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>7. Complete the Activity 3 <span style={{ fontWeight: 'bold' }}>Feedback Form.</span></Typography>
                              <Typography className={`${taskInsClass}`} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer", mt: 2 }}>It will take about 60 minutes to complete these tasks.</Typography>
                              
                            </Grid>
                          </Card>
                        </Grid>
                      </>
                    )}
                    <IconButton onClick={toggleFullContent} sx={{ position: 'absolute', top: '17%', left: "20px", padding: "0", zIndex: 10, background: "linear-gradient(180deg, #FFBF00 0%, #F6E1A4 100%)", width: showFullContent ? "6px" : "40px", height: showFullContent ? "-webkit-fill-available" : "40px", borderRadius: showFullContent ? "10px 0 0 10px" : "20px" }}>
                      {showFullContent ? <ArrowLeft /> : <ArrowRight />}
                    </IconButton>
                  </Grid>
                ) : ( */}
                <Grid id="tasks" container spacing={2} sx={{ marginTop: 15, zIndex: 1 }}>
                  <Box sx={{ textAlign: 'center', width: "100%" }}>
                    <Typography variant='h3' sx={{ ...globals.heading.largeText, fontSize: largeFontSize }}>{data?.taskName}</Typography>
                  </Box>
                  <Grid item md={12} sx={{ position: "relative" }}>
                    <Card className='cardTask' id="activityInsCard" style={{ height: `${cardSize}px`, overflowY: "auto", maxHeight: "240px" }}>
                      <Grid>
                        <img alt='info icon' src={`${IMAGES_URL}info.png`} style={{ width: 35 }} />
                        <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize }}>Instructions</Typography>
                        {/* {data?.taskDescription.split("\n").map((item: any, index: number) => (
                            <Typography key={index} className={`${taskInsClass}`} onClick={() => index > 0 && scrollBeh(index)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>{item}</Typography>
                          ))} */}
                        <Typography className={`${taskInsClass}`} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>Your tasks this week are:</Typography>
                        <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(1)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>1. <span style={{ fontWeight: 'bold' }}>Watch the Activity 3 Instruction Video.</span></Typography>

                        <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(2)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>2. <span style={{ fontWeight: 'bold' }}>Watch the Effective Communication videos</span> to learn helpful tips about talking with your activity partner about what kind of care they prefer. The videos are short and informative.</Typography>
                        <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(3)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>3. <span style={{ fontWeight: 'bold' }}>Watch the Using Zoom video.</span> There is also Zoom Troubleshooting information for more help using Zoom.</Typography>
                        <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(4)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>4. <span style={{ fontWeight: 'bold' }}>Compare and discuss your LEAD Guide answers. </span><span style={{ textDecoration: "underline" }}>Join the Zoom meeting </span>{`with your activity partner. If you are together and only using one computer for this meeting, make sure you are logged into the LEAD website using ${user?._doc.userType === 0 ? user._doc.userAssociated[0].name + "'s" : user?._doc.firstName + "'s"} login information.`}</Typography>
                        <Typography
                          className={`${taskInsClass}`}
                          onClick={() => scrollBeh(4)}
                          sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}
                        >
                          5. {user?._doc.userType === 0 ? (
                            <>
                              <span style={{ fontWeight: 'bold' }}>Make changes to LEAD Guide.</span> Based on your conversation, {user?._doc.userAssociated[0].name} may want to make changes to their LEAD Guide. This is not required. If {user?._doc.userAssociated[0].name} is happy with their answers, no changes are needed.
                            </>
                          ) : (
                            <>
                              <span style={{ fontWeight: 'bold' }}>Make changes to LEAD Guide.</span> Based on your conversation, {user?._doc.firstName} may want to make changes to their LEAD Guide. This is not required. If {user?._doc.firstName} is happy with their answers, no changes are needed.
                            </>
                          )}
                        </Typography>
                        <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(6)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>6. <span style={{ fontWeight: 'bold' }}>Review Recommended Resources.</span></Typography>
                        <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(7)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>7. Complete the Activity 3 <span style={{ fontWeight: 'bold' }}>Feedback Form.</span></Typography>
                        <Typography className={`${taskInsClass}`} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer", mt: 2 }}>It will take about 60 minutes to complete these tasks.</Typography>

                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
                {/* )} */}
              </div>
              {/* <Typography sx={{ ...globals.body.mediumText, whiteSpace: "break-spaces" }}>{data?.taskInstructions}</Typography> */}
              <Divider sx={{ marginY: 8 }} />
              <Box sx={{ position: "relative", marginBottom: 2 }}>
                <Typography ref={taskVidRef} sx={{ ...globals.heading.largeText, fontSize: largeFontSize, textAlign: "center" }}>Task 1- Activity 3 Instructions</Typography>
                <Typography ref={taskVidRef} sx={{ ...globals.body.smallText, fontSize: smallFontSize, textAlign: "center", marginBottom: 5 }}>Watch the Activity 3 Instruction Video.</Typography>
                <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black", marginBottom: "10px" }}><b><i>To view the video in full screen mode click on the expanding box at the bottom right corner of the video window.</i></b></Typography>
                <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black", marginBottom: "10px" }}><b><i>Watch this video until the end or pause it before moving onto the next video.</i></b></Typography>
                <div style={{ position: "relative", paddingTop: "56.25%" }}><iframe src="https://share.descript.com/embed/YaFmMWMz8IA" style={{ border: "1px solid", position: "absolute", top: 0, height: "100%", width: "100%" }} allowFullScreen></iframe></div>
                <br></br>
                <br></br>
                <Typography ref={taskExVidRef} sx={{ ...globals.heading.largeText, fontSize: largeFontSize, textAlign: "center" }}>Task 2- Effective Communication Videos</Typography>
                <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(2)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer", textAlign: "center", marginBottom: 5 }}> <b>Watch the Effective Communication videos</b>  to learn helpful tips about talking with your activity partner about what kind of care they prefer. The videos are short and informative.</Typography>
                <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize, color: "black", marginBottom: "10px", textAlign: "center" }}><b>Agree to Disagree</b></Typography>
                <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black", marginBottom: "10px" }}><b><i>To view the video in full screen mode click on the expanding box at the bottom right corner of the video window.</i></b></Typography>
                <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black", marginBottom: "10px" }}><b><i>Watch this video until the end or pause it before moving onto the next video.</i></b></Typography>
                <div style={{ position: "relative", paddingTop: "56.25%" }}><iframe src="https://share.descript.com/embed/UOVFkRZen2s" style={{ border: "1px solid", position: "absolute", top: 0, height: "100%", width: "100%" }} allowFullScreen></iframe></div>
                <br></br>
                <br></br>
                <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize, color: "black", marginBottom: "10px", textAlign: "center" }}><b>Discuss to Understand</b></Typography>
                <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black", marginBottom: "10px" }}><b><i>To view the video in full screen mode click on the expanding box at the bottom right corner of the video window.</i></b></Typography>
                <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black", marginBottom: "10px" }}><b><i>Watch this video until the end or pause it before moving onto the next video.</i></b></Typography>
                <div style={{ position: "relative", paddingTop: "56.25%" }}><iframe src="https://share.descript.com/embed/1hDwVSQAJH6" style={{ border: "1px solid", position: "absolute", top: 0, height: "100%", width: "100%" }} allowFullScreen></iframe></div>
                <br></br>
                <br></br>
                <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize, color: "black", marginBottom: "10px", textAlign: "center" }}><b>Strive for Clarity</b></Typography>
                <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black", marginBottom: "10px" }}><b><i>To view the video in full screen mode click on the expanding box at the bottom right corner of the video window.</i></b></Typography>
                <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black", marginBottom: "10px" }}><b><i>Watch this video until the end or pause it before moving onto the next video.</i></b></Typography>
                <div style={{ position: "relative", paddingTop: "56.25%" }}><iframe src="https://share.descript.com/embed/4mvQg8Mqwkw" style={{ border: "1px solid", position: "absolute", top: 0, height: "100%", width: "100%" }} allowFullScreen></iframe></div>
                <br />
                <br />
                <Divider sx={{ marginY: 8 }} />
                <div>
                  <Typography ref={zoomVidRef} variant='h3' sx={{ ...globals.heading.largeText, fontSize: largeFontSize, textAlign: "center" }}>Task 3- Using Zoom & Zoom Troubleshooting</Typography>
                  <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(3)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer", textAlign: "center" }}> <b>Watch the Using Zoom video. </b> There is also Zoom Troubleshooting information for more help using Zoom.</Typography>
                  <br></br>
                  <br></br>

                  <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black", marginBottom: "10px" }}><b><i>To view the video in full screen mode click on the expanding box at the bottom right corner of the video window.</i></b></Typography>
                  <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black", marginBottom: "10px" }}><b><i>Watch this video until the end or pause it before moving onto the next video.</i></b></Typography>
                  <div style={{ position: "relative", paddingTop: "56.25%" }}><iframe src="https://share.descript.com/embed/8mVZNlV4fW3" style={{ border: "1px solid", position: "absolute", top: 0, height: "100%", width: "100%" }} allowFullScreen></iframe></div>
                  <br />
                  <br />
                  <Typography ref={zoomVidRef} variant='h3' sx={{ ...globals.heading.largeText, fontSize: largeFontSize, textAlign: "center" }}>Zoom Troubleshooting</Typography>
                  <br></br>
                  <br></br>
                  <div style={{ border: "1px solid #000", width: "80%", margin: "auto", padding: "10px 30px" }}>

                    <Slider {...settings}>
                      <div>
                        <br></br>
                        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize }}>Technical issues are common experiences when using technology like Zoom. Here are some things you can try to resolve common technical issues. But if you’re spending more than a few minutes trying to fix frustrating issues, stop and contact the LEAD team by email at lead@utah.edu. They can set up a time to call you and help troubleshoot issues.</Typography>
                        <br></br>
                        <Typography fontStyle={"italic"} sx={{ ...globals.body.mediumText, fontSize: mediumFontSize }}>What to do if there’s a problem joining the Zoom meeting:</Typography>
                        <Box>
                          <ul>
                            <li>
                              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize }}>The patient is set as the “host” of the meeting, and the meeting will start once both the patient and the care partner have joined.</Typography>
                            </li>
                            <li>
                              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize }}>If technical issues or other problems cause your meeting to end before you’re done, you can go back into Activity 3 and click on “Join Meeting” again. Each meeting will be saved as a separate video that you can find in My Documents.</Typography>
                            </li>
                          </ul>
                        </Box>
                      </div>
                      <div>
                        <Typography fontStyle={"italic"} sx={{ ...globals.body.mediumText, fontSize: mediumFontSize }}>If you can’t hear your partner:</Typography>
                        <Box>
                          <ul>
                            <li>
                              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, display: "flex", alignItems: "center", whiteSpace: "break-spaces" }}>{"Check the leftmost icon on your Zoom screen. If it looks like a pair of headphones with a green arrow, like this: "}
                                <img alt='troubleshoot icon' src={microphone} style={{ width: "60px" }} />
                              </Typography>
                            </li>
                            <li>
                              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, display: "flex", alignItems: "center", whiteSpace: "break-spaces" }}>{"then click on the icon until it looks like a microphone.  "}
                                <img alt='troubleshoot icon' src={Mute} style={{ width: "60px" }} />
                              </Typography>
                            </li>
                            <li>
                              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, display: "flex", alignItems: "center", whiteSpace: "break-spaces" }}>{"If prompted, select “Join with computer audio.” "}

                              </Typography>
                            </li>
                            <li>
                              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize }}>Make sure your device’s sound is on and the volume is turned up.</Typography>
                            </li>
                            <li>
                              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize }}>Disconnect any headphones or wireless speakers that you’re not using.</Typography>
                            </li>
                            <li>
                              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize }}>Check your device’s sound output settings.</Typography>
                            </li>
                            <li>
                              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize }}>Click on the three dots to find Zoom’s audio settings. Make sure the correct speaker is selected.</Typography>
                            </li>
                            <img alt='troubleshoot icon' src={threedot} style={{ width: "35%" }} />

                          </ul>
                        </Box>
                        <br></br>
                      </div>
                      <div>
                        <Typography fontStyle={"italic"} sx={{ ...globals.body.mediumText, fontSize: mediumFontSize }}>If you are unmuted and your partner can’t hear you:</Typography>
                        <Box>
                          <ul>
                            <li>
                              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, display: "flex", alignItems: "center", whiteSpace: "break-spaces" }}>{"Double-check that you’re unmuted. If you’re unmuted, the microphone icon looks like this: "}
                                <img alt='troubleshoot icon' src={troubleMute} style={{ width: "60px" }} />
                              </Typography>
                            </li>
                            <li>
                              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, display: "flex", alignItems: "center", whiteSpace: "break-spaces" }}>{`If it looks like this  `}
                                <img alt='troubleshoot icon' src={troubleUnmute} style={{ width: "60px" }} />
                                {"  the microphone is turned off. Click it to unmute."}
                              </Typography>
                            </li>
                            <li>
                              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, display: "flex", alignItems: "center", whiteSpace: "break-spaces" }}>{`If it looks like this  `}
                                <img alt='troubleshoot icon' src={microphone} style={{ width: "60px" }} />
                                {"  then click on the icon until it looks like a microphone."}
                                <img alt='troubleshoot icon' src={Mute} style={{ width: "60px" }} />
                              </Typography>
                            </li>
                            <li>
                              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, display: "flex", alignItems: "center", whiteSpace: "break-spaces" }}>{`If prompted, select “Join with computer audio.”  `}

                              </Typography>
                            </li>
                            <li>
                              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize }}>Disconnect any headphones or wireless speakers that you’re not using.</Typography>
                            </li>
                            <li>
                              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize }}>Check your device’s sound input settings to see if the correct microphone is selected.</Typography>
                            </li>
                          </ul>
                        </Box>
                        <br></br>
                      </div>
                      <div>
                        <Box>
                          <ul>
                            <li>
                              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize }}>Click on the three dots to find Zoom’s settings, and then click on “Audio”.</Typography>
                            </li>
                            <li>
                              <img alt='troubleshoot icon' src={threedot} style={{ width: "35%" }} />
                            </li>

                            <li>
                              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, display: "flex", alignItems: "center", whiteSpace: "break-spaces" }}>{"Click on “Test microphone.” If the color on the sound bar moves when you speak, your microphone is working and the correct microphone is selected."}

                              </Typography>
                            </li>
                            <li>
                              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, alignItems: "center", whiteSpace: "break-spaces" }}>
                                <img alt='troubleshoot icon' src={audio} style={{ width: "35%" }} />

                              </Typography>
                            </li>
                          </ul>
                        </Box>
                      </div>
                      <div>
                        <Typography fontStyle={"italic"} sx={{ ...globals.body.mediumText, fontSize: mediumFontSize }}>If your partner can’t see you:</Typography>
                        <Box>
                          <ul>
                            <li>
                              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, display: "flex", alignItems: "center", whiteSpace: "break-spaces" }}>{"Make sure your video is turned on. If your video is on, the video icon looks like this:   "}
                                <img alt='troubleshoot icon' src={troubleStartVideo} style={{ width: "60px" }} />
                              </Typography>
                            </li>
                            <li>
                              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, display: "flex", alignItems: "center", whiteSpace: "break-spaces" }}>{`If it looks like this  `}
                                <img alt='troubleshoot icon' src={troubleStopVideo} style={{ width: "60px" }} />
                                {"  your camera is turned off. Click it to turn your video on. "}
                              </Typography>
                            </li>
                            <li>
                              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize }}>Check to make sure nothing’s covering your device’s camera.</Typography>
                            </li>
                            <li>
                              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize }}>Check your device’s camera settings to see if everything is working properly.</Typography>
                            </li>
                            <li>
                              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize }}>Click on the three dots to find Zoom’s settings. </Typography>
                            </li>
                            <img alt='troubleshoot icon' src={threedot} style={{ width: "35%" }} />
                            <li>
                              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize }}>Try changing the selected camera.</Typography>
                            </li>
                            <img alt='troubleshoot icon' src={changecam} style={{ width: "35%" }} />

                          </ul>
                        </Box>
                      </div>
                    </Slider>
                  </div>
                </div>

              </Box>
              <br></br>
              <Divider sx={{ marginY: 5 }} />
              <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize, textAlign: "center" }} style={{ textAlign: "center", marginLeft: "20px" }}>Task 4- Join Your Zoom Meeting and Discuss Your LEAD Guide Answers</Typography>
              <br />
              <Typography sx={{ whiteSpace: "break-spaces", cursor: "pointer", textAlign: "center ", ...globals.body.smallText, fontSize: smallFontSize }}><b>Compare and discuss your LEAD Guide answers.</b> <span style={{ textDecoration: "underline" }}>Join the Zoom meeting </span>{`with your activity partner. If you are together and only using one computer for this meeting, make sure you are logged into the LEAD website using ${user?._doc.userType === 0 ? user._doc.userAssociated[0].name + "'s" : user?._doc.firstName + "'s"} login information.`}</Typography>
              <Typography />
              <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize, textAlign: "center" }} style={{ textAlign: "center", marginLeft: "20px" }}>and</Typography>
              <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize, textAlign: "center" }} style={{ textAlign: "center", marginLeft: "20px" }}>Task 5- {user?._doc.userType === 0 ? user._doc.userAssociated[0].name : 'You'} can make changes to {user?._doc.userType === 0 ? "there" : 'your'} LEAD Guide if {user?._doc.userType === 0 ? 'they' : 'you'} want to </Typography>

              <Typography
                sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer", textAlign: "center" }}
              >
                {user?._doc.userType === 0 ? (
                  <>
                    <b >Make changes to LEAD Guide.</b> Based on your conversation, {user?._doc.userAssociated[0].name} may want to make changes to their LEAD Guide. This is not required. If {user?._doc.userAssociated[0].name} is happy with their answers, no changes are needed. Only {user._doc.userAssociated[0].name} can make changes to their LEAD Guide.
                  </>
                ) : (
                  <>
                    <b >Make changes to LEAD Guide.</b> Based on your conversation, {user?._doc.firstName} may want to make changes to their LEAD Guide. This is not required. If {user?._doc.firstName} is happy with their answers, no changes are needed. Only {user?._doc.firstName} can make changes to their LEAD Guide.
                  </>
                )}
              </Typography>
              <br></br>
              <br></br>
              <div style={{ border: "1px solid #000" }} ref={leadGuide}>

                {new Date(data?.startDate)?.getTime() < new Date().getTime() || data?.startDate === null ? <MeetingWindow data={formData} /> : `You can't complete this LEAD Guide form until ${new Date(data?.startDate)}`}
              </div>
              <Divider sx={{ marginY: 5 }} />
              <div ref={recRef}>
                <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize, textAlign: "center", }}>Task 6- Recommended Resources</Typography>
                <Typography sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer", textAlign: "center", marginBottom: 5 }}>Review Recommended Resources.</Typography>
                <Recommended
                  data={resourcesBlurbs[2]} evenModal={(link) => { setOpenModal(true); setEmbedUrl(link) }} />
              </div>
              <Divider sx={{ marginY: 5 }} />
              <div style={{ width: "95%", margin: "auto" }} ref={formRef}>
                <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize, textAlign: "center" }}>Task 7- Activity 3 Feedback Form</Typography>
                <Typography sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer", textAlign: "center", marginBottom: 5 }}>Complete the Activity 3 Feedback Form.</Typography>
                <SurveyForm activity={3} userId={data?.userId} taskId={data?.taskId} formValues={data?.taskFormFields[data?.taskFormFields?.length - 1]} />

              </div>
            </Grid>
          </Grid>
          <Modal
            title=""
            centered
            open={openModal}
            onOk={() => setOpenModal(false)}
            okButtonProps={{ style: { display: "none" } }}
            onCancel={() => setOpenModal(false)}
            width={"80%"}
            style={{ maxHeight: '200vh' }}
            cancelText="Close"
          >
            {embedUrl !== "" ?
              <iframe title="Embedded Content" width="100%" height="700px" src={embedUrl} frameBorder="0" allowFullScreen></iframe>
              :
              <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize }}>Couldn't find link to the source</Typography>

            }
          </Modal>
        </>
      )
        :
        <Typography variant='h3' sx={{ ...globals.heading.largeText, fontSize: largeFontSize }}>All Tasks Are Completed!</Typography>
      }
    </>
  );
};

export default Activity3;