import { Box, Divider, Grid, Typography } from '@mui/material';
import globals from '../../../styles/globals';
import '../../../styles/styles.css';
import ReadSpeakerComponent from '../../ReadSpeaker';
import TeamMemberCard from '../../TeamMemberCard';
import NancyImage from '../../../assets/lead assets/Nancy.jpg';
import SaraImage from '../../../assets/lead assets/SaraB.jpg';
import JordanaImage from '../../../assets/lead assets/Jordana.jpg';
import KaraImage from '../../../assets/lead assets/Kara.jpeg';
import EliImage from '../../../assets/lead assets/Eli.jpg';
import KathieImage from '../../../assets/lead assets/Kathie Supiano Faculty Photo 2021 copy.jpg';
import RebeccaImage from '../../../assets/lead assets/Becky.jpg';
import { useContext } from 'react';
import { AuthContext } from '../../../context/authcontext';
const MeetTeam: React.FC = () => {

  const {largeFontSize} = useContext(AuthContext);

  const teamMembers = [
    {
      name: 'Nancy Aruscavage',
      position: 'MS – Project Manager',
      description: 'I have an MS in Gerontology from the University of Utah. In my spare time, I teach fitness classes at the local senior center and thoroughly enjoy my time with them while helping improve their quality of life through laughter and sweat. But really, I benefit just as much from the interaction, and I encourage everyone to seek opportunities for social engagement and physical activity. You’ll most likely find me on the soccer pitch or out in the garden when not working.',
      imageSrc: NancyImage, // Replace with the actual image path
    },
    {
      name: 'Sara Bybee',
      position: 'PhD – Team Member',
      description: 'I am an Illinois native who moved to Utah in 2012. I have worked as a licensed clinical social worker and through those experiences, I have gained insight into the struggles and triumphs of families facing various types of illnesses. In 2021, I graduated from the University of Utah with my PhD in Nursing. My doctoral research focused on understanding how couples (including sexual and gender minority couples) may experience post-traumatic growth through the experience of cancer. As a postdoctoral fellow I contributed to research projects related to screening for and addressing unmet social needs among socioeconomically disadvantaged populations. My long-term goal is to conduct research that will ultimately improve the health and wellbeing of historically marginalized or disadvantaged populations. In my free time, I enjoy running, biking, hiking, cooking, baking, reading novels, speaking Spanish, and playing with dogs.',
      imageSrc: SaraImage, // Replace with the actual image path
    },
    {
      name: 'Jordana Clayton',
      position: 'BA - Graduate Research Assistant',
      description: 'I am an MS student in economics at the University of Utah. My primary research interest is in poverty and inequality, but I love the LEAD team’s research on surrogate decision-making in advance care planning. I’m a hobby naturalist and love identifying plants and animals and spending time outside, as well as caring for my “indoor jungle” in my sixth-floor apartment. I live in Salt Lake with my wife and two cats.',
      imageSrc: JordanaImage, // Replace with the actual image path
    },
    {
      name: 'Kara Dassel',
      position: 'PhD, FGSA, FAGHE, FAHSE – Project Director',
      description: 'I am a professor in the College of Nursing at the University of Utah. My research interests focus on Alzheimer’s disease and related dementias, end-of-life care planning, and caregiver health outcomes. I teach classes about gerontology or the study of aging. I am a Fellow of the Gerontological Society of America. On a community level, I have served on the professional advisory board of State Chapters of the Alzheimer’s Association. I enjoy spending time with my family skiing and hiking in the Utah mountains.',
      imageSrc: KaraImage, // Replace with the actual image path
    },
    {
      name: 'Eli Iacob',
      position: 'PhD – Team Member',
      description: 'I have a PhD in Neuroscience and an MS in clinical investigations. I have been a research assistant professor in the College of Nursing at the University of Utah since 2020, assisting multiple faculty members with research, including study design and statistics for telehealth interventions, end-of-life decisions, dementia, diabetes, depression, and cancer.',
      imageSrc: EliImage, // Replace with the actual image path
    },
    {
      name: 'Kathie Supiano',
      position: 'PhD, LCSW, FGSA, FT, APHSW-C – Team Member',
      description: 'I am an associate professor in the College of Nursing, and the director of Caring Connections: A Hope and Comfort in Grief Program at the University of Utah. My research includes clinical interventions in complicated/prolonged grief, prevention of adverse grief outcomes, suicide loss survivorship, overdose grief, prison hospice, and mental health care. I have been a practicing clinical social worker and psychotherapist for more than 40 years. My clinical practice includes care of older adults with depression and multiple chronic health concerns, end-of-life care, and bereavement care. I am a Fellow in the Gerontological Society of America, and a Fellow of Thanatology. I am an avid cyclist and birder.',
      imageSrc: KathieImage, // Replace with the actual image path
    },
    {
      name: 'Rebecca L. Utz',
      position: 'PhD – Team Member',
      description: 'I am a professor of sociology at the University of Utah. I teach students how to do research. My research projects focus on how to support families who are helping those with chronic health or aging-related needs. I became interested in studying aging and families at the age of 14 when I took my first part-time job at a retirement community in Ohio. I live in Utah with my husband and two adolescent girls.',
      imageSrc: RebeccaImage, // Replace with the actual image path
    },
  ];      

    return (
            <>
            <Grid>
              <Grid sx={{ width: "100%" }}>
                <ReadSpeakerComponent readId='readspeaker_taskContent' readClass='' compId='taskContent'/>
                <Grid id="taskContent" container spacing={2} sx={{ marginTop: 15 }}>
                  <Box sx={{ textAlign: 'center', width: "100%", marginBottom: 5 }}>
                    <Typography variant='h3' sx={{ ...globals.heading.largeText, fontSize: largeFontSize }}>Meet the Team</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                    <Grid  container spacing={2} justifyContent="center">
                      {teamMembers.map((member, index) => (
                        <Grid style={{display: "flex"}} item key={index} position="relative">
                          <TeamMemberCard {...member}/>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
                <Divider sx={{ marginY: 8 }} />
              </Grid>
            </Grid>
          </>
    );
};

export default MeetTeam;