import { ReactNode, createContext, useEffect, useState } from 'react';

interface AuthContextType {
  isAuthenticated: boolean;
  isAdmin: boolean;
  loadedReadSpeak: boolean;
  activityDone: boolean;
  login: (data:User) => void;
  readSpeakLoad: (input: boolean) => void;
  logout: () => void;
  checkLogin: () => void;
  user: User | null | undefined;
  selectedCardIndex: number;
  largeFontSize: number | null;
  mediumFontSize: number | null;
  smallFontSize: number | null;
  xSmallFontSize: number | null;
  saveFonts: (largeFontSize: number, mediumFontSize: number, smallFontSize: number, xSmallFontSize: number) => void;
  setSelectedCardIndex: (index: number) => void;
  setLargeFontSize: (index: number | null) => void;
  setMediumFontSize: (index: number | null) => void;
  setSmallFontSize: (index: number | null) => void;
  setXSmallFontSize: (index: number | null) => void;
  setLoadedReadSpeak: (index: boolean) => void;
  selectedData: any;
  setActivityDone: (index: boolean) => void;
  setSelectedData: (data: any) => void;
  tasksGlobalData: any;
  setTasksGlobalData: (data: any) => void;
  setFormDataAll: (data: any) => void;
  setSelectedUser: (data: any) => void;
  formDataAll: any;
  selectedUser: any;
}

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  isAdmin: false,
  loadedReadSpeak: false,
  login: () => {},
  readSpeakLoad: () => {},
  logout: () => {},
  checkLogin: () => {},
  user: null,
  setActivityDone: () => {},
  selectedCardIndex: 0,
  largeFontSize: 0,
  activityDone: false,
  mediumFontSize: 0,
  smallFontSize: 0,
  xSmallFontSize: 0,
  saveFonts: () => {},
  setSelectedCardIndex: () => {},
  setLargeFontSize: () => {},
  setMediumFontSize: () => {},
  setSmallFontSize: () => {},
  setXSmallFontSize: () => {},
  selectedData: null,
  setSelectedData: () => {},
  setLoadedReadSpeak: () => {},
  tasksGlobalData: null,
  setTasksGlobalData: () => {},
  setFormDataAll: () => {},
  setSelectedUser: () => {},
  formDataAll: () => {},
  selectedUser: () => {},
});
interface Props {
    children: ReactNode;
}
interface User{
    token:string,
    _doc:{
      _id: string | "",
      name: string,
      email: string,
      userType: number,
      adminType: number,
      userAssociated: [] | any,
      firstName: string,
      record_id: number,
      disabled: boolean
    }
}


const AuthProvider= ({ children }:Props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loadedReadSpeak, setLoadedReadSpeak] = useState(false);
  const [user, setUser] = useState<User | null>();
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const [largeFontSize, setLargeFontSize] = useState<number | null>(31);
  const [mediumFontSize, setMediumFontSize] = useState<number | null>(24);
  const [smallFontSize, setSmallFontSize] = useState<number | null>(22);
  const [xSmallFontSize, setXSmallFontSize] = useState<number | null>(20);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [tasksGlobalData, setTasksGlobalData] = useState<any>(null);
  const [formDataAll, setFormDataAll] = useState<any>();
  const[selectedUser, setSelectedUser] = useState(user?._doc?._id);
  const [activityDone, setActivityDone] = useState(false);

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else if(localStorage.getItem("user")){
      const data = localStorage.getItem("user");
      setUser(JSON.parse(data || ""));
      setSelectedUser(JSON.parse(data || "")._doc._id);
      if(JSON.parse(data || "")?._doc.userType == undefined){
        setIsAdmin(true);
      }else{
        if(localStorage.getItem('fontSizes')){
          const fonts = JSON.parse(localStorage.getItem('fontSizes') || "");
          setLargeFontSize(fonts?.largeFS);
          setMediumFontSize(fonts?.mediumFS);
          setSmallFontSize(fonts?.smallFS);
          setXSmallFontSize(fonts?.xSmallFS);
        }

      }
      setIsAuthenticated(true);
    }else{
    setIsAdmin(false);

    }
    if(localStorage.getItem("readSpeak")){
      const readSpeak = localStorage.getItem("readSpeak");
      setLoadedReadSpeak(JSON.parse(readSpeak || ""));
    }
  }, [user]);

  const login = (data: User) => {
    // Perform authentication logic, e.g., API calls, validation, etc.
    // Once authenticated, set the isAuthenticated state to true
        if(data._doc.disabled && (data._doc.userType===1 || data._doc.userType===0)){
          alert("Your account has been disabled. If you believe this is a mistake or need further assistance, please contact our support team at lead@utah.edu.");
          return;
        }
        setUser(data);
        setSelectedUser(data._doc._id);
        setIsAuthenticated(true);
        if(data?._doc?.userType == undefined) 
          setIsAdmin(true);
  };

  const saveFonts = (largeFS: number, mediumFS: number, smallFS: number, xSmallFS: number) => {
    localStorage.setItem('fontSizes', JSON.stringify({largeFS: largeFS, mediumFS: mediumFS, smallFS: smallFS, xSmallFS: xSmallFS}));
    setLargeFontSize(largeFS);
    setMediumFontSize(mediumFS);
    setSmallFontSize(smallFS);
    setXSmallFontSize(xSmallFS);
  }

  const readSpeakLoad = (input: boolean) => {
    localStorage.setItem('readspeak', JSON.stringify(input));
    setLoadedReadSpeak(input);
  } 
  const checkLogin = () => {
    // Perform authentication logic, e.g., API calls, validation, etc.
    // Once authenticated, set the isAuthenticated state to true
    if(localStorage.getItem("user")){
        setIsAuthenticated(true);
    } 
  };

  const logout = () => {
    // Perform logout logic, e.g., clearing tokens, session data, etc.
    // Once logged out, set the isAuthenticated state to false
    localStorage.removeItem("user");
    setFormDataAll(null)
    setIsAuthenticated(false);
    setSelectedUser(undefined);
    setIsAdmin(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, activityDone, setActivityDone, login, logout, checkLogin, user, selectedCardIndex, largeFontSize, setLargeFontSize, mediumFontSize, setMediumFontSize, smallFontSize, setSmallFontSize, xSmallFontSize, setXSmallFontSize, saveFonts, setSelectedCardIndex, selectedData, setSelectedData, tasksGlobalData, setTasksGlobalData,  setLoadedReadSpeak, loadedReadSpeak, readSpeakLoad, setFormDataAll, formDataAll, isAdmin, setSelectedUser, selectedUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };