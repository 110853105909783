import React, { useContext, useEffect, useState } from 'react';
// import { ZoomMtg } from '@zoomus/websdk';
import './styles.css'
import { getZoomMeeting } from '../../api/auth';
import { AuthContext } from '../../context/authcontext';
import ZoomMtgEmbedded from '@zoomus/websdk/embedded'
import { Button, Popconfirm } from 'antd';
import { Typography } from '@mui/material';
import globals from '../../styles/globals';

const ZoomMeeting = () => {

  const { user } = useContext(AuthContext);

  const [meetingInfo, setMeetingInfo] = useState<any>();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  function JoinVideo() {

    const interval = setInterval(() => {
      let joinVideoBtn = document.querySelector('button[title="Start Video"]') as HTMLButtonElement | null;;
      if (joinVideoBtn) {
        joinVideoBtn.click();
      }

      if (!document.querySelector('button[title="Stop Video"]')) {
        // JoinVideo();
      } else {
        clearInterval(interval); // Stop the interval once the "Stop Video" button is found
      }
    }, 2000);
    const intervalAudio = setInterval(() => {
      let joinVideoBtn = document.querySelector('button[title="Audio"]') as HTMLButtonElement | null;;
      console.log("chala", joinVideoBtn)
      if (joinVideoBtn) {
        joinVideoBtn.click();
      }

      if (!document.querySelector('button[title="Unmute"]')) {
        // JoinVideo();
      } else {
        clearInterval(intervalAudio); // Stop the intervalAudio once the "Stop Video" button is found
      }
    }, 2000);

  }

  useEffect(() => {
    const fetchHtmlContent = async () => {

      if (meetingInfo) {
        try {
          localStorage.setItem('meetingStatus', 'true');
          const client = ZoomMtgEmbedded.createClient()

          let meetingSDKElement = document.getElementById('meetingSDKElement') as HTMLElement;

          await client.init({ zoomAppRoot: meetingSDKElement, language: 'en-US', customize: { video: { isResizable: true, viewSizes: { default: { width: 700, height: 100 } } } } }).catch(err => {
            setLoading(false);
            alert("Meeting Failed. Please try again after sometime.")
          }).then(async (res) => {
            console.log("aya is mn")
            JoinVideo()
            setLoading(false);
            await client.join({
              sdkKey: "RrPzWFKcRvyG1UVifDEiw",
              signature: meetingInfo.signature, // role in SDK signature needs to be 1
              meetingNumber: meetingInfo.meeting_number,
              password: meetingInfo.pass,
              userName: user?._doc.name ?? "",
              success: (success: any) => {
                console.log('--> Success Joining zoom', success);
              },
              error: (error: any) => {
                console.log('--> Error Joining zoom', error);
              },
            }).catch(err => {
              console.log(err);
            })

          }).catch(errs => {
            console.log(errs);
          })
        } catch (err) {
          console.log(err);
        }
      }
    }
    fetchHtmlContent();
  }, [meetingInfo]);

  const confirm = (newOpen: boolean) => {
    if (!newOpen) {
      setOpen(newOpen);
      return;
    }

    if (user?._doc.userType === 0) {
      getSignature(false);
    }
    else
      setOpen(newOpen);
  }
  const getSignature = (joinInPerson: boolean) => {
    const meeting_status = localStorage.getItem('meetingStatus')
    if (meeting_status) {
      if (window.confirm(`This page is reloading. Don't worry, your answers are saved in the browser. If you haven't click the "Save my Answers" button, make sure to come back to this page and click on the button to save your LEAD Guide respones.`)) {
        localStorage.removeItem('meetingStatus');
        window.location.reload();
        localStorage.setItem('move', 'true');
      }

    } else {
      setLoading(true);
      getZoomMeeting(user?._doc._id, user?.token, joinInPerson).then((res: any) => {
        if (res) {
          setMeetingInfo(res)
        }
      }).catch(err => {
        setLoading(false);
        alert(err.message)
      })
    }

  }

  return (
    <div style={{ padding: "30px" }}>

      <br />
      {

        <Popconfirm
          title="Joining In-Person?"
          description="Are you joining the meeting in-person with your activity partner?"
          open={open}
          onOpenChange={confirm}
          onConfirm={() => getSignature(true)}
          onCancel={() => getSignature(false)}
          okText="Yes"
          cancelText="No"
        >
          <Button loading={loading} style={{ marginBottom: "40px" }} type='primary' size='large'>Join Meeting</Button>
        </Popconfirm>
      }
      <div id="meetingSDKElement">
      </div>
    </div>
  );

}


export default ZoomMeeting;