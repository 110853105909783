import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Card, Divider, Grid, IconButton, Typography } from '@mui/material';
import globals from '../../styles/globals';
import '../../styles/styles.css';
import { IMAGES_URL } from '../../constants/constants';
import ReadSpeakerComponent from '../ReadSpeaker';
import SurveyForm from '../PageComponents/MyTasksContent/SurveyForm';
import { AuthContext } from '../../context/authcontext';
import PreviousSubmissions from '../PageComponents/MyTasksContent/PreviousSubmissions';
import Recommended from '../RecommendedList';
import Act4Rec1 from '../../assets/lead assets/act4_rec_1.jpg';
import Act4Rec2 from '../../assets/lead assets/act4_rec_2.jpg';
import { Modal } from 'antd';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';

const Activity4: React.FC = () => {
  const { selectedData, tasksGlobalData, setSelectedCardIndex, largeFontSize, mediumFontSize, smallFontSize, user } = useContext(AuthContext);
  const data = selectedData ? selectedData?.data?.currentTask : tasksGlobalData?.progressData[tasksGlobalData?.currentTask];
  const taskVidRef = useRef<HTMLDivElement | null>(null);
  const taskAdvanceDirectiveRef = useRef<HTMLDivElement | null>(null);
  const taskLeadGuideRef = useRef<HTMLDivElement | null>(null);
  const [taskInsClass, setTaskInsClass] = useState("");
  const taskContent = document.getElementById('tasks');
  const [openModal, setOpenModal] = useState(false);
  const [embedUrl, setEmbedUrl] = useState("");
  const taskCard = document.getElementById('activityInsCard');
  let lastScrollPosition = -100;
  let leftPosition = 0; // Initial left position in percentage (centered)
  const tasksGridRef = useRef<HTMLDivElement | null>(null);
  // if (tasksGridRef.current)
  const [resourcesBlurbs, setResourcesBlurbs] = useState<any>([
    [
      { link: "https://www.youtube.com/watch?v=Eq_Er-tqPsA", linkLabel: "Understand Alzheimer’s disease: ", desc: "This video describes the cause of Alzheimer’s disease and how it progresses through the brain.", img: `${IMAGES_URL}recommended1.png` },
      { link: "", linkLabel: "Understanding different types of dementia: ", desc: "This infographic compares four different types of dementia and how they affect the brain.", img: `${IMAGES_URL}recommended1.png` },
      { link: "", linkLabel: "5 Myths about advance care planning: ", desc: "This infographic explains common misconceptions about advance care planning.", img: `${IMAGES_URL}recommended1.png` },
    ],
    [
      { link: "https://www.hopkinsmedicine.org/health/conditions-and-diseases/alzheimers-disease/stages-of-alzheimer-disease", linkLabel: "Stages of Alzheimer’s disease: ", desc: "This article explains what to expect at each stage of Alzheimer’s disease.", img: `${IMAGES_URL}recommended2.png` },
      { link: "", linkLabel: "Next steps after Alzheimer’s diagnosis: ", desc: "This infographic provides suggestions and resources to help plan for life with Alzheimer’s disease.", img: `${IMAGES_URL}recommended2.png` },
      { link: "https://www.nia.nih.gov/health/getting-your-affairs-order-checklist-documents-prepare-future?utm_source=nia-eblast&utm_medium=email&utm_campaign=caregiving-2023019#important-papers", linkLabel: "Documents to prepare for the future: ", desc: "This website helps you get your legal affairs in order and lists other important documents and care details.", img: `${IMAGES_URL}recommended2.png` },
    ],
    [
      { link: "https://www.nia.nih.gov/health/alzheimers-caregiving-changes-communication-skills", linkLabel: "Changes in communication skills: ", desc: "This article explains common communications issues in Alzheimer’s disease and how care partners can help.", img: `${IMAGES_URL}recommended3.png` },
      { link: "https://www.nia.nih.gov/health/making-decisions-someone-end-life?utm_source=nia-eblast&utm_medium=email&utm_campaign=caregiving-20220407", linkLabel: "Making decisions for someone else: ", desc: "This article offers guidance for caregivers on making end-of-life decisions.", img: `${IMAGES_URL}recommended3.png` },
      { link: "https://www.alzheimers.gov/life-with-dementia/planning-for-future?utm_source=nia-eblast&utm_medium=email&utm_campaign=alzgov-20220412#financial-planning", linkLabel: "Life with dementia: ", desc: "This website defines legal documents, describes medical decisions, and explains advance care planning documents.", img: `${IMAGES_URL}recommended3.png` },
    ],
    [
      { link: "AARP Family Caregiving Guide.pdf", org: "AARP", linkLabel: "Dementia Provision (Advance Directive Addendum): ", desc: "This guide provides information, resources, and checklists to help with your caregiving journey.", img: `${Act4Rec1}` },
      { link: "Choosing A Health Care Proxy NIA.pdf", org: "NIH", linkLabel: "Alzheimer’s Navigator: ", desc: "This resource helps build an action plan for all stages of Alzheimer’s disease.", img: `${Act4Rec2}` },
      { link: "POLST-vs-ADs-chart.pdf", org: "POLST", linkLabel: "Choosing a health care proxy: ", desc: "Learn the differences between an advance directive and POLST.", img: `${IMAGES_URL}recommended1.png` },
    ]
  ]);
  const recRef = useRef<HTMLDivElement | null>(null);
  const formRef = useRef<HTMLDivElement | null>(null);
  const [showFullContent, setShowFullContent] = useState(true);
  const [isLeft, setIsLeft] = useState(false);
  const [changed, setChanged] = useState(false);
  const [cardSize, setCardSize] = useState(240);
  const [checkvalue, setcheckvalue] = useState(25);
  useEffect(() => {
    if (largeFontSize) {
      if (largeFontSize == 25) {
        setCardSize(240);
        setcheckvalue(largeFontSize)
      }
      if (largeFontSize > checkvalue) {
        setCardSize(cardSize + 10);
        setcheckvalue(largeFontSize)
      } else {
        setCardSize(cardSize - 10);
        setcheckvalue(largeFontSize)
      }
    }


  }, [largeFontSize]);

  const toggleFullContent = () => {
    setShowFullContent(!showFullContent);
  };

  useEffect(() => {
    setTimeout(() => {
      setChanged(true);
      tasksGridRef.current?.scrollIntoView({ behavior: 'smooth', block: "end" })
    }, 2000);
  }, []);

  // useEffect(() => {

  //   window.addEventListener('scroll', () => {
  //     const currentScrollPosition = window.scrollY;

  //     if (currentScrollPosition > lastScrollPosition) {
  //       // Scrolling down
  //       leftPosition -= 1; // Adjust the value as needed for smoother/faster movement
  //       if (leftPosition < 0 && window.scrollY > 200) {
  //         leftPosition = 0;
  //       }
  //     } else {
  //       // Scrolling up
  //       leftPosition += 1; // Adjust the value as needed
  //     }
  //     leftPosition = Math.max(-25, Math.min(0, leftPosition));
  //     if (taskContent) {
  //       taskContent.style.left = `${leftPosition}%`;
  //       if (window.scrollY > 200) {
  //         setIsLeft(true);
  //         taskContent.style.position = `fixed`;
  //         taskContent.style.width = `min-content`;
  //         setTaskInsClass("taskInsClass");
  //         if (taskCard) {
  //           taskCard.style.width = `min-content`;
  //           taskCard.style.height = `fit-content`;
  //         }
  //       }
  //       else {
  //         setIsLeft(false);
  //         taskContent.style.position = `relative`;
  //         setTaskInsClass("");
  //         taskContent.style.width = `auto`;
  //         taskContent.style.left = `${leftPosition}%`;
  //         if (taskCard) {
  //           taskCard.style.width = `auto`;
  //           taskCard.style.height = `auto`;
  //         }
  //       }
  //     }
  //     lastScrollPosition = currentScrollPosition;
  //   });
  // }, [changed])


  const scrollBeh = (index: number) => {
    if (index === 1)
      taskVidRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    else if (index === 2)
      taskAdvanceDirectiveRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    // window.open("https://www.aarp.org/caregiving/financial-legal/free-printable-advance-directives/", '_blank');
    else if (index === 3)
      taskLeadGuideRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    // setSelectedCardIndex(3);
    else if (index === 4)
      recRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    else if (index === 5)
      formRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });

  }
  function openlink() {
    localStorage.setItem('linkedclicked', 'true');
    window.open('/', '_blank');
  }
  return (
    <>
      {selectedData?.previousSubmissions ? (
        <>
          <Grid id="task-content" sx={{ width: "100%" }}>
            <PreviousSubmissions data={data?.taskFormFields} />
          </Grid>
        </>
      ) : data ? (
        <>
          <Grid sx={{ width: "100%" }}>
            <Grid id="task-content" sx={{ width: "100%" }}>
              <ReadSpeakerComponent readId='readspeaker_task-content' readClass='' compId='task-content' />
              <div ref={tasksGridRef}>
                {/* {isLeft ? (
                  <Grid id="tasks" container spacing={2} sx={{ marginTop: 15, zIndex: 1, transition: "1s linear", width: 'min-content', position: 'fixed', marginLeft: "0px" }}>
                    {showFullContent && (
                      <>
                        <Box sx={{ textAlign: 'center', width: "100%" }}>
                          <Typography variant='h3' sx={{ ...globals.heading.largeText, fontSize: largeFontSize }}>{data?.taskName}</Typography>
                        </Box>
                        <Grid item md={12} sx={{ position: "relative" }}>
                          <Card style={{ height: "max-content", width: "fit-content" ,overflowY: "auto", maxHeight: "400px"}} className='cardTask' id="activityInsCard">
                            <Grid>
                              <img alt='info icon' src={`${IMAGES_URL}info.png`} style={{ width: 35 }} />
                              <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize }}>Instructions</Typography>
                              <Typography className={`${taskInsClass}`} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>Your tasks this week are:</Typography>
                              <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(1)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>1. Watch the Activity 4 Instruction Video.</Typography>
                              <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(2)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>2. Locate and download {user?._doc.userType === 0 ? user?._doc.userAssociated[0].name : "your"} <a rel='noreferrer' href='https://www.aarp.org/caregiving/financial-legal/free-printable-advance-directives/' target='_blank' style={{ textDecoration: "underline", color: "blue" }}>State Medical Advance Directive.</a> {user?._doc.userType === 0 && `If ${user?._doc.userAssociated[0].name} hasn’t already completed an advance directive, this is something else that you can do together.`}</Typography>
                              <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(4)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>3. Review Recommended Resources.</Typography>
                              <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(5)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>4. Complete the Activity 4 Feedback Form.</Typography>
                              <Typography className={`${taskInsClass}`} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer", mt: 2 }}>It will take about 60 minutes to complete these tasks.</Typography>
                              <Typography className={`${taskInsClass}`} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer", mt: 2 }}>**If you haven’t already done so, please go to My Documents and download your LEAD Guide and Zoom recording.</Typography>

                            </Grid>
                          </Card>
                        </Grid>
                      </>
                    )}
                    <IconButton onClick={toggleFullContent} sx={{ position: 'absolute', top: '17%', left: "20px", padding: "0", zIndex: 10, background: "linear-gradient(180deg, #FFBF00 0%, #F6E1A4 100%)", width: showFullContent ? "6px" : "40px", height: showFullContent ? "-webkit-fill-available" : "40px", borderRadius: showFullContent ? "10px 0 0 10px" : "20px" }}>
                      {showFullContent ? <ArrowLeft /> : <ArrowRight />}
                    </IconButton>
                  </Grid>
                ) : ( */}
                  <Grid id="tasks" container spacing={2} sx={{ marginTop: 15, zIndex: 1, transition: "1s linear" }}>
                    <Box sx={{ textAlign: 'center', width: "100%" }}>
                      <Typography variant='h3' sx={{ ...globals.heading.largeText, fontSize: largeFontSize }}>{data?.taskName}</Typography>
                    </Box>
                    <Grid item md={12} sx={{ position: "relative" }}>
                      <Card className='cardTask' id="activityInsCard" style={{ height: `${cardSize}px` ,overflowY: "auto", maxHeight: "240px"}}>
                        <Grid>
                          <img alt='info icon' src={`${IMAGES_URL}info.png`} style={{ width: 35 }} />
                          <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize }}>Instructions</Typography>
                          <Typography className={`${taskInsClass}`} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>Your tasks this week are:</Typography>
                          <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(1)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>1. Watch the Activity 4 Instruction Video.</Typography>
                          <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(2)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>2. Locate and download {user?._doc.userType === 0 ? user?._doc.userAssociated[0].name : "your"} <a rel='noreferrer' href='https://www.aarp.org/caregiving/financial-legal/free-printable-advance-directives/' target='_blank' style={{ textDecoration: "underline", color: "blue" }}>State Medical Advance Directive.</a> {user?._doc.userType === 0 && `If ${user?._doc.userAssociated[0].name} hasn’t already completed an advance directive, this is something else that you can do together.`}</Typography>
                          <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(4)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>3. Review Recommended Resources.</Typography>
                          <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(5)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" }}>4. Complete the Activity 4 Feedback Form.</Typography>
                          <Typography className={`${taskInsClass}`} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer", mt: 2 }}>It will take about 60 minutes to complete these tasks.</Typography>
                          <Typography className={`${taskInsClass}`} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer", mt: 2 }}>**If you haven’t already done so, please go to My Documents and download your LEAD Guide and Zoom recording.</Typography>

                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>
                {/* )} */}
              </div>
              {/* <Typography sx={{ ...globals.body.mediumText, whiteSpace: "break-spaces" }}>{data?.taskInstructions}</Typography> */}
              <Divider sx={{ marginY: 8 }} />
              <Box sx={{ position: "relative", marginBottom: 2 }}>
                <Typography ref={taskVidRef} sx={{ ...globals.heading.largeText, fontSize: largeFontSize, textAlign: "center"}}>Task 1- Activity 4 Instructions</Typography>
                <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(1)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer",textAlign: "center", marginBottom: 5  }}>Watch the Activity 4 Instruction Video.</Typography>
                <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black", marginBottom: "10px" }}><b><i>To view the video in full screen mode click on the expanding box at the bottom right corner of the video window.</i></b></Typography>
                <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black", marginBottom: "10px" }}><b><i>Watch this video until the end or pause it before moving onto the next video.</i></b></Typography>
                <div style={{ position: "relative", paddingTop: "56.25%" }}><iframe src="https://share.descript.com/embed/ZnUGEuK22qB" style={{ border: "1px solid", position: "absolute", top: 0, height: "100%", width: "100%" }} allowFullScreen></iframe></div>
              </Box>
              <Divider sx={{ marginY: 8 }} />
              <Box sx={{ position: "relative", marginBottom: 2 }} ref={taskAdvanceDirectiveRef}>
                <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize, textAlign: "center" }}>Task 2- Complete State Medical Advance Directive</Typography>
                <Typography sx={{ ...globals.body.smallText, fontSize: smallFontSize, textAlign: "center", marginBottom: 5  }}>Locate and complete {user?._doc.userType === 0 ? user?._doc.userAssociated[0].name+"'s" : "your"} <a rel='noreferrer' href='https://www.aarp.org/caregiving/financial-legal/free-printable-advance-directives/' target='_blank' style={{ textDecoration: "underline", color: "blue" }}>State Medical Advance Directive</a></Typography>
              </Box>
              <Divider sx={{ marginY: 8 }} />
              <div ref={recRef}>
                <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize, textAlign: "center" }}>Task 3- Recommended Resources</Typography>
                <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(4)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" , textAlign: "center", marginBottom: 5}}>Review Recommended Resources.</Typography>
                <Recommended
                  data={resourcesBlurbs[3]} evenModal={(link) => { setEmbedUrl(link); setOpenModal(true) }} />
              </div>
              <Divider sx={{ marginY: 5 }} />
              <Box sx={{ position: "relative", marginBottom: 2 }} ref={taskAdvanceDirectiveRef}>
                <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize, textAlign: "center"}}>Task 4- Download the LEAD Guide & Zoom Recording</Typography>
                
                <Typography sx={{ ...globals.body.smallText, fontSize: smallFontSize ,textAlign:"center"}}>Download {user?._doc.userType === 0 ? user?._doc.userAssociated[0].name+"'s" : "your"} completed <a rel='noreferrer' href='#' onClick={openlink}>Lead Guide.</a></Typography>
              </Box>
              <Divider sx={{ marginY: 5 }} />
              <div style={{ width: "95%", margin: "auto" }} ref={formRef}>
                <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize, textAlign: "center"}}>Task 5- Activity 4 Feedback Form</Typography>
                <Typography className={`${taskInsClass}`} onClick={() => scrollBeh(5)} sx={{ ...globals.body.smallText, fontSize: smallFontSize, whiteSpace: "break-spaces", cursor: "pointer" , textAlign: "center", marginBottom: 5 }}>Complete the Activity 4 Feedback Form.</Typography>
                <SurveyForm activity={4} userId={data?.userId} taskId={data?.taskId} formValues={data?.taskFormFields[data?.taskFormFields?.length - 1]} />
              </div>
            </Grid>
          </Grid>
          <Modal
            title=""
            centered
            open={openModal}
            onOk={() => setOpenModal(false)}
            okButtonProps={{ style: { display: "none" } }}
            onCancel={() => setOpenModal(false)}
            width={"80%"}
            style={{ maxHeight: '200vh' }}
            cancelText="Close"
          >
            {embedUrl !== "" ?
              <iframe title="Embedded Content" width="100%" height="700px" src={embedUrl} frameBorder="0" allowFullScreen></iframe>
              :
              <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize }}>Couldn't find link to the source</Typography>

            }
          </Modal>
        </>
      )
        :
        <Typography variant='h3' sx={{ ...globals.heading.largeText, fontSize: largeFontSize }}>All Tasks Are Completed!</Typography>
      }
    </>
  );
};

export default Activity4;