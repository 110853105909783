import React, { useContext, useEffect, useState } from 'react';
import { Typography, Box, Grid, List, ListItem, Divider, Link } from '@mui/material';
import globals from '../../../styles/globals';
import colors from '../../../styles/colors';
import { IMAGES_URL } from '../../../constants/constants';
import ReadSpeakerComponent from '../../ReadSpeaker';
import { getAllResouceDoc } from '../../../api/auth';
import { AuthContext } from '../../../context/authcontext';
import imageSrc1 from '../../../assets/lead assets/instruction1.png';
import imageSrc2 from '../../../assets/lead assets/instruction2.png';
import banner from '../../../assets/lead assets/banner.jpg';
import { Modal } from 'antd';
import LeadGuideBlank from '../../LeadGuideForm/LeadGuideBlank';


const ResourcesContent: React.FC = () => {
  const { user, largeFontSize, mediumFontSize } = useContext(AuthContext);
  
  const styles = {
    boxContainer: {
      width: '100%',
      height: 'auto',
      borderRadius: 5,
      mt: 10
    },
    listItem: {
      display: 'flex',
      alignItems: 'flex-start',
      marginTop: 10
    },
    listItemText: {
      ...globals.body.mediumText,
      fontSize: mediumFontSize!!,
      width: '100%',
      marginLeft: 5,
      marginTop: -5
    },
    square: {
      width: 50,
      height: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.aqua,
      color: colors.white,
      marginRight: 10,
    },
  }

  const [resources, setAllResources] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      getAllResouceDoc(user?.token).then((res: any) => {
        setAllResources(res);
      })
    }
    fetchData();
  }, [user?.token])


  return (
    <Grid>
      <ReadSpeakerComponent readId='readspeaker_resourcecontent' readClass='' compId='resourcecontent' />
      <Grid id="resourcecontent">
        <Box
          sx={styles.boxContainer}
        >
          <img
            src={`${banner}`}
            alt="Banner"
            style={{ width: '100%', borderRadius: 10 }}
          />
        </Box>

        <Box mt={5} mb={2}>
          <Typography variant="h5" style={{ ...globals.heading.largeText, fontSize: largeFontSize!! }}>
            Instructions
          </Typography>
          <Typography variant="h5" style={{ ...globals.body.mediumText, fontSize: mediumFontSize!!, color: colors.grey }}>
            Instructions for opening links
          </Typography>
          <Typography style={{ ...globals.body.mediumText, fontSize: mediumFontSize!!, color: colors.aqua, marginTop: 5 }}>
          When you click on a link, a new tab will open with your selected website. Your tabs typically appear at the top of your screen. To go back to the LEAD site, click on the tab that says “Lead.”
          </Typography>
        </Box>

        <Divider></Divider>
        {/* <Grid container mt={5} spacing={2} alignItems={"baseline"}> */}
          {/* <Grid mb={4} item xs={12} md={12} display={"flex"} flexDirection={"column"} alignItems={"baseline"}>
            <Typography style={globals.body.mediumText}>
              <br /><br />Click on the links below (in blue) to learn more.
              <br /><br />Instructions for opening links:
              <br /><br />When you click on a link, a new tab will open with your selected website. Your tabs typically appear at the top of your screen. To go back to the LEAD site, click on the tab that says “Lead.”</Typography>
          </Grid> */}
          {/* <Grid item xs={12} md={6} display={"flex"} alignItems={"center"}>
            <Box
              sx={{
                borderRadius: 5,
              }}
            >
              <img style={{ width: "100%" }} src={imageSrc1} alt={"Instruction 1"} />
              <img style={{ width: "100%" }} src={imageSrc2} alt={"Instruction 2"} />

            </Box>
          </Grid> */}
        {/* </Grid> */}
        <Divider></Divider>
        <br></br>
        {resources?.map((item: any, index: number) => (
          <Box key={index + "parent"}>
            <Typography variant="h5" style={{ ...globals.heading.largeText, fontSize: largeFontSize!!, marginTop: 15 }}>
              {item?.heading}
            </Typography>
            {
              index === 1 ? <Typography style={{...globals.body.mediumText, fontSize: mediumFontSize!!}}>It’s not easy being a caregiver. Caregivers often neglect their own health while caring for others. It’s important to take time for yourself; use local services such as adult day centers and find a caregiver support group – it’s nice knowing you’re not alone and that others have similar experiences.</Typography>
                : index === 0 ? <Typography style={{...globals.body.mediumText, fontSize: mediumFontSize!!}}>Here you will find helpful tips and guides for health care planning, financial planning, and end-of-life care planning. This includes information about advance directives, wills, and living trusts.</Typography>
                  : null
            }
            <List sx={{ mb: 5 }}>
              {item?.links?.map((link: any, linkIndex: number) => (

                link?.link.length > 1 ?

                  <ListItem key={linkIndex} disableGutters style={styles.listItem}>
                    <div style={styles.square}>{link?.indexNumber}</div>
                    <span style={styles.listItemText}>
                      {link?.linkDescription}
                      <br />
                      <ul>
                        {link?.link?.map((linkItem: any, indexLink: any) => (
                          <li key={indexLink + "link"}>
                            <Link target="_blank" href={linkItem} style={styles.listItemText}>
                              {link.linkLabel[indexLink]}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </span>
                  </ListItem>
                  :
                  <ListItem key={linkIndex} disableGutters style={styles.listItem}>
                    <div style={styles.square}>{link.indexNumber}</div>
                    <Typography style={styles.listItemText}>
                      <Link
                        href={link?.link[0]}
                        target="_blank"
                        style={{...globals.body.mediumText, fontSize: mediumFontSize!!}}
                      >
                        {link?.linkLabel[0]}
                      </Link>
                      {link.linkDescription}
                    </Typography>
                  </ListItem>
              ))}
            </List>
          </Box>
        ))
        }

        <Box>
          <Typography variant="h5" style={{ ...globals.heading.largeText, fontSize: largeFontSize!!, marginTop: 15 }}>
            Lead Guide(Blank)
          </Typography>
          <List sx={{ mb: 5 }}>
            <ListItem disableGutters style={styles.listItem}>
              <div style={styles.square}>1</div>
              <span style={styles.listItemText}>
                Here you can find the Lead Guide(Blank) form:
                <Typography onClick={()=> {setOpenModal(true)}} style={{ ...globals.body.mediumText, fontSize: mediumFontSize!!, marginTop: 15, textDecoration: "underline", color: "#00f", cursor: "pointer" }}>
                  Click here
                </Typography>
              </span>
            </ListItem>
          </List>
        </Box>
      </Grid>
      <Modal
        title=""
        centered
        open={openModal}
        onOk={() => setOpenModal(false)}
        onCancel={() => setOpenModal(false)}
        width={1000}
        styles={{ body: { height: "50%" } }}
      >
        <Box>
          {
            <LeadGuideBlank isShowHeader={true} />
          }
        </Box>
      </Modal>
    </Grid>
  );
};

export default ResourcesContent;