import colors from "./colors";

const globals = {
    heading: {
        xSmallText: {
            fontSize: 14,
            fontWeight: 400,
            fontFamily: 'Source Sans Pro'
        },
        smallText: {
            fontSize: 16,
            fontWeight: 600,
            fontFamily: 'Source Sans Pro'
        },
        mediumText: {
            fontSize: 18,
            fontWeight: 700,
            fontFamily: 'Source Sans Pro'
        },
        largeText: {
            fontSize: 25,
            fontWeight: 700,
            fontFamily: 'Source Sans Pro'
        }
    },
    body: {
        xSmallText: {
            fontSize: 14,
            fontWeight: 400,
            fontFamily: 'Source Sans Pro'
        },
        smallText: {
          fontSize: 16,
          fontWeight: 400,
          fontFamily: 'Source Sans Pro'
      },
        mediumText: {
          fontSize: 18,
          fontWeight: 400,
          fontFamily: 'Source Sans Pro'
      }
    },
    button: { fontFamily: 'Source Sans Pro', width: 'auto', height: 'auto', borderRadius: 5, fontSize: 16, fontWeight: '700', textTransform: 'capitalize', color: colors.black, bgcolor: colors.gold },
    card: {
        backgroundColor: 'black',
        width: '300px',
        margin: "0 auto",
        height: '40px',
        overflow: 'hidden',
        position:"relative",
        textAlign: "center",
        display: "grid",
        alignItems: "center"
      },
      'card::before': {
        content: '""',
        width: 50,
        height: 84,
        backgroundColor: "white",
        top: -24,
        rotate: "216deg",
        left: -40,
        position: "absolute",
      },
      'card::after': {
        content: '""',
        width: 50,
        height: 84,
        backgroundColor: "white",
        bottom: -24,
        rotate: "216deg",
        right: -40,
        position: "absolute",
      },
      taskPagination: {
        width: 'fit-content',
        margin: "20px auto",
      },
      pageCard:{
        padding: 1,
        borderRadius: 0,
        width:'70px',
        alignContent: "center",
        justifyItems: "center",
        display: "grid",
        cursor: "pointer"
      },
      taskBtn: {
        backgroundColor: colors.aqua,
        width: "fit-content",
        height: "fit-content",
        padding: "2px 10px",
        borderRadius: "4px",
        margin: "15px 0px"
      },
      taskBtnText: {
        fontSize: "18px", 
        fontWeight: "700 !important", 
        fontFamily: 'Source Sans Pro',
        color: "white"
      },
      recommendedCards: {
        display:"grid", 
        borderRadius:"15px", 
        border: "1px solid rgba(0, 0, 0, 0.24)",
      }
};
  
  export default globals;  