import React, { useContext } from 'react';
import { Box, Card, Grid, Typography } from '@mui/material';
import globals from '../../../styles/globals';
import '../../../styles/styles.css';
import colors from '../../../styles/colors';
import { AuthContext } from '../../../context/authcontext';

interface Props {
    data: any
  }
const PreviousSubmissions: React.FC<Props> = ({ data }) => {

    const {largeFontSize, mediumFontSize} =  useContext(AuthContext);
    const firstSubmission = data[0];
    const lastSubmission = data[data.length - 1];

    const capitalizeAndAddSpace = (text: string) => {
        return text
          .replace(/([A-Z])/g, ' $1') // Add space before capital letters
          .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
    };

  return (
    <>
        <Grid container spacing={2} sx={{ marginTop: 15, marginBottom: 10 }}>
            <Box sx={{ textAlign: 'center', width: "100%", mb: 5 }}>
                <Typography variant='h3' sx={{ ...globals.heading.largeText, fontSize: largeFontSize }}>Previous Submissions</Typography>
            </Box>

            <Grid item sm={12} md={6} lg={6} sx={{position:"relative"}}>
                <Card className='cardTaskSubmission'>
                    <Grid>
                        <Typography sx={{...globals.heading.largeText, fontSize: largeFontSize}}>First Submission</Typography>
                        <Typography variant="body1" sx={{ ...globals.body.mediumText, fontSize: mediumFontSize}}>
                            <span style={{ fontWeight: 'bold', color: colors.aqua }}>Task Completed: </span>
                            <span>{firstSubmission?.taskCompleted === 0 ? "No" : "Yes"}</span>
                        </Typography>
                        {firstSubmission?.taskCompleted === 0 && (
                            <Typography variant="body1" sx={{ ...globals.body.mediumText, fontSize: mediumFontSize}}>
                                <span style={{ fontWeight: 'bold', color: colors.aqua }}>Reason For Incomplete: </span>
                                <span>{capitalizeAndAddSpace(firstSubmission?.reasonForIncomplete)}</span>
                            </Typography>
                        )}
                        {firstSubmission?.reasonForIncomplete === "other" && (
                            <Typography variant="body1" sx={{ ...globals.body.mediumText, fontSize: mediumFontSize}}>
                                <span style={{ fontWeight: 'bold', color: colors.aqua }}>Others: </span>
                                <span>{capitalizeAndAddSpace(firstSubmission?.others)}</span>
                            </Typography>
                        )}
                        <Typography variant="body1" sx={{ ...globals.body.mediumText, fontSize: mediumFontSize}}>
                            <span style={{ fontWeight: 'bold', color: colors.aqua }}>Clear Instructions: </span>
                            <span>{capitalizeAndAddSpace(firstSubmission?.clearInstructions)}</span>
                        </Typography>
                        <Typography variant="body1" sx={{ ...globals.body.mediumText, fontSize: mediumFontSize}}>
                            <span style={{ fontWeight: 'bold', color: colors.aqua }}>Easy Activity: </span>
                            <span>{capitalizeAndAddSpace(firstSubmission?.easyActivity)}</span>
                        </Typography>
                        <Typography variant="body1" sx={{ ...globals.body.mediumText, fontSize: mediumFontSize}}>
                            <span style={{ fontWeight: 'bold', color: colors.aqua }}>Understood Purpose: </span>
                            <span>{capitalizeAndAddSpace(firstSubmission?.understoodPurpose)}</span>
                        </Typography>
                        <Typography variant="body1" sx={{ ...globals.body.mediumText, fontSize: mediumFontSize}}>
                            <span style={{ fontWeight: 'bold', color: colors.aqua }}>Helpful Activity: </span>
                            <span>{capitalizeAndAddSpace(firstSubmission?.helpfulActivity)}</span>
                        </Typography>
                        <Typography variant="body1" sx={{ ...globals.body.mediumText, fontSize: mediumFontSize}}>
                            <span style={{ fontWeight: 'bold', color: colors.aqua }}>Comments: </span>
                            <span>{capitalizeAndAddSpace(firstSubmission?.comments)}</span>
                        </Typography>
                    </Grid>
                </Card>
            </Grid>
            {data?.length > 1 && (
                <Grid item sm={12} md={6} lg={6} sx={{position:"relative",}}>
                    <Card className='cardTaskSubmission' >
                        <Grid>
                            <Typography sx={{...globals.heading.largeText, fontSize: largeFontSize}}>Last Submission</Typography>
                            <Typography variant="body1" sx={{ ...globals.body.mediumText, fontSize: mediumFontSize}}>
                                <span style={{ fontWeight: 'bold', color: colors.aqua }}>Task Completed: </span>
                                <span>{lastSubmission?.taskCompleted === 0 ? "No" : "Yes"}</span>
                            </Typography>
                            {lastSubmission?.taskCompleted === 0 && (
                                <Typography variant="body1" sx={{ ...globals.body.mediumText, fontSize: mediumFontSize}}>
                                    <span style={{ fontWeight: 'bold', color: colors.aqua }}>Reason For Incomplete: </span>
                                    <span>{capitalizeAndAddSpace(lastSubmission?.reasonForIncomplete)}</span>
                                </Typography>
                            )}
                            {lastSubmission?.reasonForIncomplete === "other" && (
                                <Typography variant="body1" sx={{ ...globals.body.mediumText, fontSize: mediumFontSize}}>
                                    <span style={{ fontWeight: 'bold', color: colors.aqua }}>Others: </span>
                                    <span>{capitalizeAndAddSpace(lastSubmission?.others)}</span>
                                </Typography>
                            )}
                            <Typography variant="body1" sx={{ ...globals.body.mediumText, fontSize: mediumFontSize}}>
                                <span style={{ fontWeight: 'bold', color: colors.aqua }}>Clear Instructions: </span>
                                <span>{capitalizeAndAddSpace(lastSubmission?.clearInstructions)}</span>
                            </Typography>
                            <Typography variant="body1" sx={{ ...globals.body.mediumText, fontSize: mediumFontSize}}>
                                <span style={{ fontWeight: 'bold', color: colors.aqua }}>Easy Activity: </span>
                                <span>{capitalizeAndAddSpace(lastSubmission?.easyActivity)}</span>
                            </Typography>
                            <Typography variant="body1" sx={{ ...globals.body.mediumText, fontSize: mediumFontSize}}>
                                <span style={{ fontWeight: 'bold', color: colors.aqua }}>Understood Purpose: </span>
                                <span>{capitalizeAndAddSpace(lastSubmission?.understoodPurpose)}</span>
                            </Typography>
                            <Typography variant="body1" sx={{ ...globals.body.mediumText, fontSize: mediumFontSize}}>
                                <span style={{ fontWeight: 'bold', color: colors.aqua }}>Helpful Activity: </span>
                                <span>{capitalizeAndAddSpace(lastSubmission?.helpfulActivity)}</span>
                            </Typography>
                            <Typography variant="body1" sx={{ ...globals.body.mediumText, fontSize: mediumFontSize}}>
                                <span style={{ fontWeight: 'bold', color: colors.aqua }}>Comments: </span>
                                <span>{capitalizeAndAddSpace(lastSubmission?.comments)}</span>
                            </Typography>
                        </Grid>
                    </Card>
                </Grid>
            )}
        </Grid>
    </>
  );
};

export default PreviousSubmissions;