import React, { useContext } from 'react';
import Layout from '../../components/Layout';
import DashboardContent from '../../components/PageComponents/Dashboard/DashboardContent';
import ResourcesContent from '../../components/PageComponents/Dashboard/ResourcesContent';
import MyDocuments from '../../components/PageComponents/Dashboard/MyDocuments';
import MyTasks from '../../components/PageComponents/Dashboard/MyTasks';
import { AuthContext } from '../../context/authcontext';
import MeetTeam from '../../components/PageComponents/Dashboard/MeetTeam';

const Dashboard: React.FC = () => {
  const { setSelectedCardIndex, selectedCardIndex, setSelectedData, user } = useContext(AuthContext);

  const handleSelectedCard = (cardIndex: number) => {

    if (selectedCardIndex !== cardIndex) {
      setSelectedCardIndex(cardIndex);
      setSelectedData(null);
    }
  };

  // Check if the user is logged in and set the selected card index to 0
  React.useEffect(() => {
    if (user) {
        setSelectedCardIndex(0);
        setSelectedData(null);
    }
  }, [user, setSelectedCardIndex, setSelectedData]);

  const renderSelectedContent = () => {
    switch (selectedCardIndex) {
      case 0:
        return <DashboardContent />;
      case 1:
        return <MyTasks />;
      case 2:
        return <ResourcesContent />;
      case 3:
        return <MyDocuments />;
      case 4:
        return <MeetTeam />;
      // case 5:
      //   return <Typography variant="body1">Contact the LEAD team with any questions at lead@utah.edu or 801-587-7353.</Typography>;
      default:
        return null;
    }
  };

  return (
    <Layout sendSelectedCard={handleSelectedCard}>
      {renderSelectedContent()}
    </Layout>
  );
};

export default Dashboard;