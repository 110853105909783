import React, { useContext, useEffect, useState } from 'react';
import { Typography, Box, Grid, Divider, CardMedia } from '@mui/material';
import globals from '../../../styles/globals';
import colors from '../../../styles/colors';
import { AuthContext } from '../../../context/authcontext';
import { Card, Modal } from 'antd';
import LeadGuideCover from '../../../assets/lead assets/leadGuideCover.png';
import LeadGuidePage1 from '../../../assets/lead assets/leadGuide_page1.png';
import LeadGuidePageCombine from '../../../assets/lead assets/leadGuideCombine.png';
import AdvanceDirPage from '../../../assets/lead assets/advanceDirectivePage.png';
import ZoomCover from '../../../assets/lead assets/zoomCover.webp';
import LeadGuideDownload from '../../LeadGuideForm/LeadGuideIndividualDownload';
import './styles.css'
import { getZoomMeetingRecording } from '../../../api/auth';
import LeadGuideCombine from '../../LeadGuideForm/LeadGuideCombine';
import Loader from '../../Loader';
import ReadSpeakerComponent from '../../ReadSpeaker';
const { Meta } = Card;

const MyDocuments: React.FC = () => {
  const { user, selectedUser, tasksGlobalData, largeFontSize, mediumFontSize } = useContext(AuthContext);
  const [openModal, setOpenModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [leadGuide, setLeadGuide] = useState(0)
  const [allRecordings, setAllRecordings] = useState<any>()
  const [allDocs, setAllDocs] = useState([
    {
      docName: "Your Individual Lead Guide",
      thumbnail: LeadGuideCover
    },
    {
      docName: "Blank Lead Guide",
      thumbnail: LeadGuidePage1
    },
    {
      docName: "Combine Lead Guide",
      thumbnail: LeadGuidePageCombine
    },
    {
      docName: "Advance Directives",
      thumbnail: AdvanceDirPage
    }
  ])

  const styles = {
    container: {
      mt: 5,
      mb: 15
    },
    boxContainer: {
      width: '100%',
      height: 'auto',
      borderRadius: 5,
    },
    listItem: {
      padding: 5,
    },
    listItemText: {
      width: '80%',
      marginLeft: 5,
    },
    square: {
      width: 30,
      height: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.aqua,
      color: colors.white,
      marginRight: 10,
    },
  }

  useEffect(() => {
    setIsLoading(true);
    if(!tasksGlobalData.progressData.find((item: any) => item?.taskName === "Activity 2" && item?.currentTask === 0 && item?.progress === 1)){
      alert("Currently, there are no document stored here. Please come back after you complete Activity 2.");
    }
    getZoomMeetingRecording(user?.token, user?._doc.userType === 1 ? 1 : user?._doc._id !== selectedUser ? 1 : 0, selectedUser).then((res: any) => {
      if (res.meetings)
        setAllRecordings(res.meetings);
      setIsLoading(false)
    }).catch(err => {
      console.log("err", err)
      // if (err?.response?.data?.message === "Currently, there are no document stored here. Please come back after you complete Activity 2.") {
      //   alert(err?.response?.data?.message);
      // }
      setIsLoading(false)
    })
  }, [])

  const getTitleName = (date: string) => {
    const dateNew = new Date(date);
    const options = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    } as Intl.DateTimeFormatOptions;

    const formattedDate = dateNew.toLocaleDateString('en-US', options);
    return formattedDate;
  }

  if (isLoading)
    return (
      <Loader />
    )

  return (
    <Grid sx={styles.container}>
      <ReadSpeakerComponent readId='readspeaker_resourcecontent' readClass='' compId='documentcontent' />
      <Grid id="documentcontent">
        <Box
          sx={styles.boxContainer}
        >
          <Typography variant="h5" style={{ ...globals.heading.largeText, fontSize: largeFontSize!!, textAlign: 'center' }} >
            Documents
          </Typography>
          <Typography variant="body1" style={{ ...globals.body.mediumText, fontSize: mediumFontSize!!, color: colors.aqua, marginTop: 5, whiteSpace: "break-spaces" }}>
            {"Here you can find your completed LEAD Guide and recorded Zoom conversation video from Activity 3.\nYou can download these items to your computer to share them with others (friends, family, or medical providers)."}
          </Typography>
          <Typography variant="body1" style={{ ...globals.body.mediumText, fontSize: mediumFontSize!!, color: colors.aqua, marginTop: 5, whiteSpace: "break-spaces" }}>
            {"Each time that you update your LEAD Guides make sure to review them for accuracy here."}
          </Typography>
        </Box>
        <Divider sx={{ marginTop: 3 }} />
        <Typography mt="20px" style={{ ...globals.heading.mediumText, fontSize: mediumFontSize!!, textAlign: 'center' }}>
          My Lead Guide
        </Typography>
        <Grid container mt="0px" sx={{ width: "100%", margin: "auto" }}>
          {tasksGlobalData?.progressData?.find((item: any) => item?.taskName === "Activity 2" && item?.currentTask === 0 && item?.progress === 1) &&
            <Grid item xs={12} md={4} sm={6}>
              <Box mt={5}>
                <Card
                  className={`${openModal && "selectedCard"}`}
                  hoverable
                  onClick={() => {
                    setOpenModal(true);
                    setLeadGuide(2);
                    // if(index === 3)
                    //   window.open("https://www.aarp.org/caregiving/financial-legal/free-printable-advance-directives/", '_blank');
                  }}
                  style={{ width: 240 }}
                  cover={<CardMedia sx={{ height: "300px" }} title="Lead Image" image={LeadGuideCover} />}
                >
                  <Meta title={"My Activity 2 Lead Guide"} description={`${"Click here and find your document"}`} />
                </Card>
              </Box>
            </Grid>
          }
          {tasksGlobalData?.progressData?.find((item: any) => item?.taskName === "Activity 3" && item?.currentTask === 0 && item?.progress === 1) &&
            <Grid item xs={12} md={4} sm={6}>
              <Box mt={5}>
                <Card
                  className={`${openModal && "selectedCard"}`}
                  hoverable
                  onClick={() => {
                    setOpenModal(true);
                    setLeadGuide(3)
                    // if(index === 3)
                    //   window.open("https://www.aarp.org/caregiving/financial-legal/free-printable-advance-directives/", '_blank');
                  }}
                  style={{ width: 240 }}
                  cover={<CardMedia sx={{ height: "300px" }} title="Lead Image" image={LeadGuideCover} />}
                >
                  <Meta title={"My Activity 3 Updated Lead Guide"} description={`${"Click here and find your document"}`} />
                </Card>
              </Box>
            </Grid>
          }
        </Grid>
        <Divider sx={{ marginTop: 3 }} />
        {
          allRecordings &&
          <>
            <Typography mt="20px" style={{ ...globals.heading.mediumText, fontSize: mediumFontSize!!, textAlign: 'center' }}>
              My Zoom Recording
            </Typography>
            <Grid container mt="0px" sx={{ width: "100%", margin: "auto" }}>
              {
                allRecordings?.map((recording: any, indexRecord: number) => (
                  recording?.recording_files?.map((item: any, index: number) => (
                    item.file_extension === "MP4" &&
                    <Grid item key={index} xs={12} md={3} sm={6}>
                      <Box mt={5} mr={30}>
                        <Card
                          // className={`${leadGuideIndex === index && leadGuideIndex !== 3 && "selectedCard"}`}
                          hoverable
                          onClick={() => {
                            window.open(`${item.play_url}?pwd=${recording.recording_play_passcode}`, '_blank');
                          }}
                          style={{ width: 240, marginRight: "40px" }}
                          cover={
                            <CardMedia sx={{ height: "300px" }} image={ZoomCover} title="Zoom Image" />
                          }
                        >
                          <Meta title={getTitleName(item.recording_start)} description={`${"Click here to redirect to your meeting recording."}`} />
                        </Card>
                      </Box>
                    </Grid>
                  ))
                ))
              }
            </Grid>
          </>
        }
        <Divider sx={{ marginY: 8 }} />
        {/* {
        leadGuideIndex === 0 ? 
        <LeadGuideDownload isShowHeader={true}/>
        : 
        leadGuideIndex === 1 ?
        <LeadGuideBlank isShowHeader={true}/>
        :
        leadGuideIndex === 2 ?
        <LeadGuideCombine activity={3}/>
        :
        <></>
      } */}
        <Modal
          title=""
          centered
          open={openModal}
          onOk={() => setOpenModal(false)}
          onCancel={() => setOpenModal(false)}
          width={1000}
          styles={{ body: { height: "50%" } }}
        >
          <Box>
            {
              leadGuide === 2 ?
                <LeadGuideDownload isShowHeader={true} />
                :
                leadGuide === 3 ?
                  <LeadGuideCombine activity={3} />
                  :
                  <></>
            }
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
};

export default MyDocuments;