import Dashboard from "../pages/SuperDashboard";
import Login from "../pages/Login";


const routes = [
  {
    path: '/',
    component: Dashboard,
    protected: true, // Indicate if the route is protected
  },
  {
    path: '/login',
    component: Login,
    protected: false, // Public route, doesn't require authentication
  }
];

export default routes;