import React, { useContext, useEffect, useState } from 'react';
import { Table, TableProps, Tag } from "antd";
import { ColumnsType } from 'antd/es/table';
import { getAllUsers } from '../../../api/auth';
import { AuthContext } from '../../../context/authcontext';
import './styles.css'

interface DataType {
    key: React.Key;
    name: string;
    email: string;
    age: number;
    userType: string;
    record_id: number;
}


const UserListTable = () => {

    const { user } = useContext(AuthContext);
    const [allUsers, setAllUsers] = useState([]);
    const [usersLoading, setUsersLoading] = useState(false);

    const columns: ColumnsType<DataType> = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Age',
            dataIndex: 'age',
            sorter: (a, b) => a.age - b.age,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
        },
        {
            title: 'User Type',
            dataIndex: 'userType',
            filters: [
                {
                    text: 'Patient',
                    value: 'Patient',
                },
                {
                    text: 'Care Partner',
                    value: 'Care Partner',
                },
            ],
            // @ts-ignore
            onFilter: (value: string | number | boolean, record: DataType) => record.userType.indexOf(String(value)) === 0,
        },
        {
            title: 'Record Id',
            dataIndex: 'record_id',
            sorter: (a, b) => a.record_id - b.record_id,
        },
    ];


    useEffect(() => {
        setUsersLoading(true);
        getAllUsers(user?.token).then((res: any) => {
            setAllUsers(res.allusers);
            setUsersLoading(false);
        }).catch(err => {
            setUsersLoading(false);
            setAllUsers([]);
        })
    }, [])

    const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {

    };

    const rowClassName = (record: DataType, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    return <Table rowClassName={rowClassName} loading={usersLoading} columns={columns} dataSource={allUsers} onChange={onChange} />;
};


export default UserListTable;