import { Table, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useContext, useEffect, useState } from 'react';
import { getUsersMeetingInfo } from '../../../api/auth';
import { AuthContext } from '../../../context/authcontext';



interface DataType {
    key: React.Key;
    patient: string;
    patient_email: string,
    care_partner_email: string,
    care_partner: string;
    meetingId: number;
    joinInPerson: boolean;
}

const UsersZoomRecording = () => {
    const[loading, setLoading] =  useState(false);
    const[allUsersRecInfo, setAllUsersRedInfo] = useState([]);
    const {user} = useContext(AuthContext);

    const columns: ColumnsType<DataType> = [
        {
            title: 'Patient Name',
            dataIndex: 'patient',
            sorter: (a, b) => a.patient.localeCompare(b.patient),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Patient Email',
            dataIndex: 'patient_email',
            sorter: (a, b) => a.patient_email.localeCompare(b.patient_email),
        },
        {
            title: 'Care Partner Name',
            dataIndex: 'care_partner',
            sorter: (a, b) => a.care_partner.localeCompare(b.care_partner),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Care Partner Email',
            dataIndex: 'care_partner_email',
            sorter: (a, b) => a.care_partner_email.localeCompare(b.care_partner_email),
        },
        {
            title: 'Meeting ID',
            dataIndex: 'meetingId',
            render: (meetingIds: string[]) => meetingIds.join(', '),
        },
        {
            title: 'Join In-Person',
            dataIndex: 'joinInPerson',
            filters: [
                {
                    text: 'Yes',
                    value: 'Yes',
                },
                {
                    text: 'No',
                    value: 'No',
                },
            ],
            // @ts-ignore
            onFilter: (value: string | number | boolean, record: DataType) => record.joinInPerson === value,
        },
        
    ];

    const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {

    };

    const rowClassName = (record: DataType, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };


    useEffect(()=> {
        setLoading(true)
        getUsersMeetingInfo(user?.token).then((res: any)=> {
            setLoading(false);
            if(res.allUsersRecInfo){
                setAllUsersRedInfo(res.allUsersRecInfo)
            }
        }).catch(err=> {
            setLoading(false);
        })

    }, [])

    return(
        <>
            <Table tableLayout='fixed' pagination={{pageSize: 5}} style={{height: "100%", fontSize: "0.85vw"}} rowClassName={rowClassName} loading={loading} columns={columns} dataSource={allUsersRecInfo} onChange={onChange} />
        </>
    )
}



export default UsersZoomRecording;