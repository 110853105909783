import React from 'react';
import './App.css';
import AppRouter from './routes/config';
import { AuthProvider } from './context/authcontext';
import GoogleFontLoader from 'react-google-font-loader';

function App() {

  return (
    <AuthProvider>
      <GoogleFontLoader
        fonts={[
          {
            font: 'Source Sans Pro',
            weights: [400, 600, 700],
          },
        ]}
      />
      <AppRouter />
    </AuthProvider>
  );
}

export default App;
