import React, { useContext, useEffect, useRef, useState } from 'react';
import '../../../styles/styles.css';
import { AuthContext } from '../../../context/authcontext';
import Activity1 from '../../Activities/Activity1';
import Activity2 from '../../Activities/Activity2';
import Activity3 from '../../Activities/Activity3';
import Activity4 from '../../Activities/Activity4';

const MyTasks: React.FC = () => {
    const { selectedData, tasksGlobalData } = useContext(AuthContext);
    const data = selectedData ? selectedData?.data?.currentTask : tasksGlobalData?.progressData[tasksGlobalData?.currentTask];        
    const [taskInsClass, setTaskInsClass] = useState("");
    const taskContent = document.getElementById('tasks');
    const taskCard = document.getElementById('activityInsCard');
    let lastScrollPosition = -100;
    let leftPosition = 0; // Initial left position in percentage (centered)
    const tasksGridRef = useRef<HTMLDivElement | null>(null);
    // if (tasksGridRef.current)
    
    useEffect(()=> {
      setTimeout(() => {
        
        tasksGridRef.current?.scrollIntoView({ behavior: 'smooth' })
      }, 2000);
    },[]);
    
    // window.addEventListener('scroll', () => {
    //   const currentScrollPosition = window.scrollY;

    //   if (currentScrollPosition > lastScrollPosition) {
    //     // Scrolling down
    //     leftPosition -= 1; // Adjust the value as needed for smoother/faster movement
    //     if (leftPosition < 0 && window.scrollY > 200) {
    //       leftPosition = 0;
    //     }
    //   } else {
    //     // Scrolling up
    //     leftPosition += 1; // Adjust the value as needed
    //   }
    //   leftPosition = Math.max(-25, Math.min(0, leftPosition));
    //   if(taskContent){
    //     taskContent.style.left = `${leftPosition}%`;
    //     if(window.scrollY > 200){
    //       taskContent.style.position = `fixed`;
    //       taskContent.style.width = `fit-content`;
    //       setTaskInsClass("taskInsClass");
    //       if(taskCard)
    //         taskCard.style.width = `fit-content`;
    //     }
    //     else{
    //       taskContent.style.position = `relative`;
    //       setTaskInsClass("");
    //       taskContent.style.width = `auto`;
    //       taskContent.style.left = `${leftPosition}%`;
    //       if(taskCard)
    //         taskCard.style.width = `auto`;
    //     }
    //   }
    //   lastScrollPosition = currentScrollPosition;
    // });

    return (
        <>
        {
          data?.taskName === "Activity 1" ?
        <Activity1/> :
        data?.taskName === "Activity 2" ?
        <Activity2/> :
        data?.taskName === "Activity 3" ?
        <Activity3/> :
        <Activity4/>
        }
        </>
    );
};

export default MyTasks;