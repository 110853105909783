import React, { useContext } from 'react';
import {
    Box, Grid, Typography,
} from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import LeadGuide3 from '../LeadGuideForm/LeadGuide3';
import webMeeting from '../../assets/lead assets/webMeeting.jpg';
import { AuthContext } from '../../context/authcontext';
import LeadGuide3CP from '../LeadGuideForm/LeadGuide3CP';
import ZoomMeeting from './ZoomMeeting';
import globals from '../../styles/globals';


interface MeetingWindowProps {
    data: any
  }

const MeetingWindow: React.FC<MeetingWindowProps> = ({data}) => {

  const {user, selectedUser, largeFontSize} = useContext(AuthContext);

  return (
    <Grid>
        <Grid textAlign={"center"} item xs={8}>
            {/* <img alt={webMeeting} src={webMeeting} style={{width: "60%", height: "100%"}}/> */}
            <ZoomMeeting/>
        </Grid>
        <Grid item xs={4}>  {/* sx={{overflowY: "scroll", height:"700px"}} */}
       
            <Box >
              { user?._doc.userType === 1 || selectedUser !== user?._doc._id?
                <LeadGuide3 activity={3} formData={data}/>
                :
                <LeadGuide3CP activity={3} formData={data}/>
              }
            </Box>
        </Grid>
    </Grid>
  );
};

export default MeetingWindow;