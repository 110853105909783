import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../context/authcontext';

const ReadSpeakerComponent: React.FC<{ readId: string, readClass: string, compId: string }> = ({ readId, readClass, compId }) => {
  const url = `https://app-na.readspeaker.com/cgi-bin/rsent?customerid=7083&lang=en_us&voice=Sophie&${compId !==""? `readid=${compId}`:`readclass=${compId}`}&url=${encodeURIComponent(
    window.location.href
  )}`;

  const {setLoadedReadSpeak, loadedReadSpeak, selectedCardIndex, readSpeakLoad} = useContext(AuthContext);

  useEffect(() => {
    if(!loadedReadSpeak){
    const script = document.createElement('script');
    script.type = 'text/javascript';
  
    // Set the content of the script element
    script.innerHTML = `
      window.rsConf = {
        params: "//cdn1.readspeaker.com/script/7083/webReader/webReader.js?pids=wr",
        general: { usePost: true }
      };
    `;
  
    // Append the script element to the body
    document.body.appendChild(script);
    var oHead = document.getElementsByTagName('HEAD').item(0);
    if(oHead !== null){
    var oScript= document.createElement("script");
    oScript.type = "text/javascript";
    oScript.src=`https://cdn1.readspeaker.com/script/7083/webReader/webReader.js`;
    oHead.appendChild(oScript);
    }
    readSpeakLoad(true);
    // Cleanup: Remove the script element when the component unmounts
    return () => {
      document.body.removeChild(script);
      document.head.removeChild(oScript);
    };
  }
  }, [loadedReadSpeak, readSpeakLoad, selectedCardIndex, setLoadedReadSpeak]);

  return (
    <div id={readClass!=="" ? readClass : readId} style={{maxWidth: "-webkit-fill-available", display:"flex",}} className="rs_skip rsbtn rs_preserve">
      <a rel="nofollow"
         className="rsbtn_play"
         title="Listen to this page using ReadSpeaker webReader"
         href={url}
      >
        <span className="rsbtn_left rsimg rspart">
          <span className="rsbtn_text">
            <span>Listen</span>
          </span>
        </span>
        <span className="rsbtn_right rsimg rsplay rspart"></span>
      </a>
    </div>
  );
};

export default ReadSpeakerComponent;
