import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import NotFound from "../pages/NotFound";


const routes = [
  {
    path: '/',
    component: Dashboard,
    protected: true, // Indicate if the route is protected
  },
  {
    path: '/login',
    component: Login,
    protected: false, // Public route, doesn't require authentication
  },
  {
    path: '/notfound',
    component: NotFound,
    protected: false, 
  },
];

export default routes;