import React, { useContext, useEffect } from 'react';
import { Box, Card, CardContent, Grid, Link, Tooltip, Typography } from '@mui/material';
import globals from '../../styles/globals';
import color from '../../styles/colors';
import '../../styles/styles.css'
import { IMAGES_URL } from '../../constants/constants';
import { AuthContext } from '../../context/authcontext';

interface Props {
    data: any[],
    evenModal: (link: string) => void
  }
const Recommended: React.FC<Props> = ({data, evenModal}) => {
  
  const {smallFontSize, xSmallFontSize} = useContext(AuthContext);
  useEffect(() => {
   
  }, [])

  return (
    <Grid container spacing={2}>
        {data.map((item,index)=> (
        <Grid key={index} item md={4} sm={6} xs={12} lg={4} style={{position: "relative", display: "flex"}}>
            <Card onClick={()=> evenModal(item.link)} className='cursor' sx={{...globals.recommendedCards}}>
                <img alt='list card' style={{height:"240px",width: "100%"}} src={item.img}/>
                <CardContent sx={{padding: "16px 10px"}}>
                  
                    <Box flexDirection={"row"}>
                    <Tooltip title={<Typography style={{fontSize: xSmallFontSize!!}}> {item.desc} </Typography>} arrow>
                      <Typography className='ellipsis-3' sx={{ ...globals.body.smallText, fontSize: smallFontSize, color: "black" }}>
                    {item.desc}
                    </Typography>
                    </Tooltip>
                    </Box>
                    <Box sx={{display:"flex", justifyContent:"space-between", marginTop: "10px"}}>
                        <Typography variant="h5" sx={{ ...globals.heading.xSmallText, fontSize: xSmallFontSize, color: color.grey }}>{item.org}</Typography>
                        <img alt='subscribe' src={`${IMAGES_URL}subscribe.png`}/>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
        ))}
    </Grid>
  );
};

export default Recommended;