import React, { useContext } from 'react';
import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import Navbar from '../Navbar';
import { AuthContext } from '../../context/authcontext';
import TabBar from '../Navbar/TabBar';

// Styles
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  fixedElements: {
    zIndex: 100,
  },
  content: {
    flex: 1,
  },
  childrenContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    margin: '0 auto'
  },
};

// Custom Layout component
interface LayoutProps {
  children: React.ReactNode;
  sendSelectedCard: (selectedCard: number) => void;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  sendSelectedCard
}) => {
    const { isAuthenticated, selectedCardIndex, isAdmin } = useContext(AuthContext);

    const handleCardClick = (index: number) => {
      sendSelectedCard(index);
    };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
      <Box sx={styles.container}>
        <div style={{...styles.fixedElements, position: isMobile ? "relative" :"fixed", width: "100%"}}>
          <Navbar login={isAuthenticated} />
          { !isAdmin && <TabBar
            selectedCard={selectedCardIndex}
            handleCardClick={handleCardClick}
          />}
        </div>
        {  
          isAdmin ?
          <div style={{...styles.content, padding: 0,marginTop: isMobile ? "0px" : "90px"}}>
            <Box sx={{...styles.childrenContainer, padding: 0}}>
              {children}
            </Box>
          </div>
          :
          <Container sx={{...styles.content, marginTop: isMobile ? "0px" : "170px", maxWidth: "80% !important"}}>
            <Box sx={styles.childrenContainer}>
              {children}
            </Box>
          </Container>
        }
      </Box>
    );
};

export default Layout;