import React, { useContext, useRef, useState } from 'react';
import {
    Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import globals from '../../../styles/globals';
import colors from '../../../styles/colors';
import { createTaskApi } from '../../../api/auth';
import { AuthContext } from '../../../context/authcontext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface SurveyFormProps {
    userId: string;
    taskId: string;
    formValues: any;
    activity: number;
  }

const SurveyForm: React.FC<SurveyFormProps> = ({ userId, taskId, formValues, activity }) => {
    
    const taskCompletedValue = formValues?.taskCompleted === 1 ? 'yes' : formValues?.taskCompleted === 0 ? 'no' : '';
  const {user, selectedData, tasksGlobalData, setSelectedCardIndex, selectedUser, formDataAll, setFormDataAll, largeFontSize, mediumFontSize,setActivityDone, xSmallFontSize } = useContext(AuthContext);  
  const [taskCompleted, setTaskCompleted] = useState(taskCompletedValue as string || '');
  const [reasonForIncomplete, setReasonForIncomplete] = useState(formValues?.reasonForIncomplete || '');
  const [clearInstructions, setClearInstructions] = useState(formValues?.clearInstructions || '');
  const [easyActivity, setEasyActivity] = useState(formValues?.easyActivity || '');
  const [understoodPurpose, setUnderstoodPurpose] = useState(formValues?.understoodPurpose || '');
  const [helpfulActivity, setHelpfulActivity] = useState(formValues?.helpfulActivity || '');
  const [others, setOthers] = useState(formValues?.others || '');
  const [comments, setComments] = useState(formValues?.comments || '');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const data = selectedData ? selectedData?.data?.currentTask : tasksGlobalData?.progressData[tasksGlobalData?.currentTask];

  // Create refs for each form field
  const taskCompletedRef = useRef<HTMLDivElement | null>(null);
  const reasonForIncompleteRef = useRef<HTMLInputElement>(null);
  const clearInstructionsRef = useRef<HTMLInputElement>(null);
  const easyActivityRef = useRef<HTMLInputElement>(null);
  const understoodPurposeRef = useRef<HTMLInputElement>(null);
  const helpfulActivityRef = useRef<HTMLInputElement>(null);
  const commentsRef = useRef<HTMLInputElement>(null);

  const showSuccessMessage = (message: string) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      hideProgressBar: false,
    });
  };

  const showErrorMessage = (message: string) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      hideProgressBar: false,
    });
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if(activity === 2 || activity === 3)
    if(!formDataAll?.userLeadGuide){
      showErrorMessage(`Please fill out the Lead Guide form.`);
        return;
    }
    setFormSubmitted(true);
    
    // Check which form fields are empty
    const emptyFields = [
    //   { field: taskCompleted, ref: taskCompletedRef, label: "Task Completion" },
    //   { field: taskCompleted === 'yes' ? 'empty' : reasonForIncomplete, ref: reasonForIncompleteRef, label: "Reason for Incompletion" },
    //   { field: clearInstructions, ref: clearInstructionsRef, label: "Clear Instructions" },
    //   { field: easyActivity, ref: easyActivityRef, label: "Easy Activity" },
    //   { field: understoodPurpose, ref: understoodPurposeRef, label: "Understood Purpose" },
    //   { field: helpfulActivity, ref: helpfulActivityRef, label: "Helpful Activity" },
    //   { field: comments, ref: commentsRef, label: "Any Comments" },
      // Add other fields here as needed
    ].filter(({ field }) => !field);
  
    // if (emptyFields.length > 0) {
    //   // Highlight empty fields with red color and scroll to the first empty field
    //   emptyFields[0].ref.current?.scrollIntoView({ behavior: 'smooth' });
  
    //   // Show an alert for the first empty field
    //   showErrorMessage(`The field "${emptyFields[0].label}" cannot be empty`);
    // } else {
      // All fields are filled, proceed with the date validation
      if (data.currentTask === 1) {
        const startDate = new Date(data?.startDate);
        const currentDate = new Date().getTime();
  
        if (startDate.getTime() > currentDate) {
          toast.warning(`You can't complete this task until ${new Date(data.startDate).toDateString()}`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            hideProgressBar: false,
          });
        } else {
          submitForm();
        }
      } else {
        submitForm();
      }
    // }
  };  

 const submitForm = () => {
        const taskCompletedValue = taskCompleted === 'yes' ? 1 : 0;
        const reasonIncom = reasonForIncomplete === 'empty' ? '' : reasonForIncomplete;
        // Retrieve the form values
        const formValues = {
            userId,
            taskId,
            taskCompleted: taskCompletedValue,
            reasonForIncomplete: reasonIncom,
            clearInstructions,
            easyActivity,
            understoodPurpose,
            helpfulActivity,
            others,
            comments
        };
        
        // You can perform further actions with the form values
        createTaskApi(formValues, user?.token).then((res:any)=> {
            if(res){            
                showSuccessMessage(res?.message);
                if(activity === 4){
                    setActivityDone(true);
                }
                setSelectedCardIndex(0);
            }
        }).catch((err) => {
            const errorMessage = err.response?.data?.message || 'Invalid form values';
            showErrorMessage(errorMessage);
        })
    
  };

  return (
    <Box>
        <ToastContainer />
    { new Date(data?.startDate)?.getTime() < new Date().getTime() || data?.startDate ===null ?

        <form onSubmit={handleFormSubmit}>
        <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize, fontWeight: "700" }}>Fill out the Activity Feedback Form below</Typography>
       

        <Typography variant="h6" sx={{...globals.heading.mediumText, fontSize: mediumFontSize, marginTop: "20px"}}>Agreement Level</Typography>
        
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <div ref={clearInstructionsRef}>
            <FormControl component="fieldset" sx={{marginY: '10px'}}>
                <FormLabel component="legend" sx={{...globals.body.mediumText, fontSize: mediumFontSize, color: formSubmitted ? (clearInstructions ? colors.aqua : colors.error) : colors.aqua}}>The instructions were clear.</FormLabel>
                <RadioGroup
                    aria-label="clear-instructions"
                    name="clearInstructions"
                    value={clearInstructions}
                    onChange={(event) => setClearInstructions(event.target.value)}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                    }}
                >
                    <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="stronglyAgree" control={<Radio />} label={<span style={{fontSize: mediumFontSize+"px"}} >Strongly Agree</span>}  />
                    <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="agree" control={<Radio />} label={<span style={{fontSize: mediumFontSize+"px"}} >Agree</span>} />
                    <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="neitherAgreeNorDisagree" control={<Radio />} label={<span style={{fontSize: mediumFontSize+"px"}} >Neither Agree nor Disagree</span>} />
                    <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="disagree" control={<Radio />} label={<span style={{fontSize: mediumFontSize+"px"}} >Disagree</span>} />
                    <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="stronglyDisagree" control={<Radio />} label={<span style={{fontSize: mediumFontSize+"px"}} >Strongly Disagree</span>} />
                </RadioGroup>
            </FormControl>
            </div>

            <div ref={easyActivityRef}>
            <FormControl component="fieldset" sx={{marginY: '10px'}}>
                <FormLabel component="legend" sx={{...globals.body.mediumText, fontSize: mediumFontSize, color: formSubmitted ? (easyActivity ? colors.aqua : colors.error) : colors.aqua}}>The activity was easy to complete.</FormLabel>
                <RadioGroup
                    aria-label="easy-activity"
                    name="easyActivity"
                    value={easyActivity}
                    onChange={(event) => setEasyActivity(event.target.value)}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                    }}
                >
                    <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="stronglyAgree" control={<Radio />} label={<span style={{fontSize: mediumFontSize+"px"}} >Strongly Agree</span>}  />
                    <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="agree" control={<Radio />} label={<span style={{fontSize: mediumFontSize+"px"}} >Agree</span>} />
                    <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="neitherAgreeNorDisagree" control={<Radio />} label={<span style={{fontSize: mediumFontSize+"px"}} >Neither Agree nor Disagree</span>} />
                    <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="disagree" control={<Radio />} label={<span style={{fontSize: mediumFontSize+"px"}} >Disagree</span>} />
                    <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="stronglyDisagree" control={<Radio />} label={<span style={{fontSize: mediumFontSize+"px"}} >Strongly Disagree</span>} />
                </RadioGroup>
            </FormControl>
            </div>

            <div ref={understoodPurposeRef}>
            <FormControl component="fieldset" sx={{marginY: '10px'}}>
                <FormLabel component="legend" sx={{...globals.body.mediumText, fontSize: mediumFontSize, color: formSubmitted ? (understoodPurpose ? colors.aqua : colors.error) : colors.aqua}}>I understood the purpose of the activity.</FormLabel>
                <RadioGroup
                    aria-label="understood-purpose"
                    name="understoodPurpose"
                    value={understoodPurpose}
                    onChange={(event) => setUnderstoodPurpose(event.target.value)}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                    }}
                >
                    <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="stronglyAgree" control={<Radio />} label={<span style={{fontSize: mediumFontSize+"px"}} >Strongly Agree</span>} />
                    <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="agree" control={<Radio />} label={<span style={{fontSize: mediumFontSize+"px"}} >Agree</span>} />
                    <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="neitherAgreeNorDisagree" control={<Radio />} label={<span style={{fontSize: mediumFontSize+"px"}} >Neither Agree nor Disagree</span>}  />
                    <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="disagree" control={<Radio />} label={<span style={{fontSize: mediumFontSize+"px"}} >Disagree</span>} />
                    <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="stronglyDisagree" control={<Radio />} label={<span style={{fontSize: mediumFontSize+"px"}} >Strongly Disagree</span>} />
                </RadioGroup>
            </FormControl>
            </div>

            <div ref={helpfulActivityRef}>
            <FormControl component="fieldset" sx={{marginY: '10px'}}>
                <FormLabel component="legend" sx={{...globals.body.mediumText, fontSize: mediumFontSize, color: formSubmitted ? (helpfulActivity ? colors.aqua : colors.error) : colors.aqua}}>The activity was helpful.</FormLabel>
                <RadioGroup
                    aria-label="helpful-activity"
                    name="helpfulActivity"
                    value={helpfulActivity}
                    onChange={(event) => setHelpfulActivity(event.target.value)}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                    }}
                >
                    <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="stronglyAgree" control={<Radio />} label={<span style={{fontSize: mediumFontSize+"px"}} >Strongly Agree</span>}  />
                    <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="agree" control={<Radio />} label={<span style={{fontSize: mediumFontSize+"px"}} >Agree</span>} />
                    <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="neitherAgreeNorDisagree" control={<Radio />} label={<span style={{fontSize: mediumFontSize+"px"}} >Neither Agree nor Disagree</span>} />
                    <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="disagree" control={<Radio />} label={<span style={{fontSize: mediumFontSize+"px"}} >Disagree</span>} />
                    <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="stronglyDisagree" control={<Radio />} label={<span style={{fontSize: mediumFontSize+"px"}} >Strongly Disagree</span>} />
                </RadioGroup>
            </FormControl>
            </div>
        </Box>

            <Box sx={{marginY: 5}}>
                <div ref={commentsRef}>
                <Typography sx={{...globals.body.mediumText, fontSize: mediumFontSize, fontWeight: "700", marginBottom: "15px", color: formSubmitted ? (comments ? colors.black : colors.error) : colors.black}}>We want and value your opinion. Please provide any comments you may have about these activities. This information will help us improve the LEAD Intervention:</Typography>
                <TextField
                    type="text"
                    label={<span style={{fontSize: mediumFontSize+"px"}} >Any comments</span>}
                   
                    fullWidth
                    multiline
                    rows={4}
                    value={comments}
                    inputProps={{ maxLength: 400, style:{fontSize: mediumFontSize+"px", paddingTop: "10px"}}}
                    onChange={(event) => setComments(event.target.value)}
                />
                </div>
            </Box>
            <Typography variant="h6" sx={{...globals.heading.mediumText, fontSize: mediumFontSize, marginTop: "20px"}}>Activity Completion</Typography>
        <div ref={taskCompletedRef}>
            <FormControl component="fieldset">
                <FormLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize, color: formSubmitted ? (taskCompleted ? colors.aqua : colors.error) : colors.aqua}}>I completed all of the activity tasks. Yes/No. If not, why?</FormLabel>
                <RadioGroup
                aria-label="completed-tasks"
                name="taskCompleted"
                value={taskCompleted}
                onChange={(event) => {
                    if(event.target.value === "yes")
                        setReasonForIncomplete('empty')
                    else
                        setReasonForIncomplete('')
                    setTaskCompleted(event.target.value)}
                }
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                }}
                >
                <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="yes" control={<Radio />} label={<span style={{fontSize: mediumFontSize!!}}>Yes</span>} />
                <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="no" control={<Radio />} label={<span style={{fontSize: mediumFontSize!!}}>No</span>}/>
                </RadioGroup>

                {taskCompleted === 'no' && (
                    <div ref={reasonForIncompleteRef}>
                        <FormControl component="fieldset" sx={{marginY: "10px"}}>
                            <FormLabel component="legend" sx={{...globals.heading.mediumText, fontSize: mediumFontSize, color: formSubmitted ? (reasonForIncomplete ? colors.aqua : colors.error) : colors.aqua}}>Reason for incomplete:</FormLabel>
                            <RadioGroup
                                aria-label="reason-for-incomplete"
                                name="reasonForIncomplete"
                                value={reasonForIncomplete}
                                onChange={(event) => setReasonForIncomplete(event.target.value)}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '10px',
                                }}
                            >
                                <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="didNotWantTo" control={<Radio />} label={<span style={{fontSize: mediumFontSize!!}}>I didn’t want to</span>} />
                                <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="forgot" control={<Radio />} label={<span style={{fontSize: mediumFontSize!!}}>I forgot</span>} />
                                <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="didNotHaveTime" control={<Radio />} label={<span style={{fontSize: mediumFontSize!!}}>I wanted to but didn’t have time</span>} />
                                <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="technicalDifficulties" control={<Radio />} label={<span style={{fontSize: mediumFontSize!!}}>I had technical difficulties</span>} />
                                <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="tooStressful" control={<Radio />} label={<span style={{fontSize: mediumFontSize!!}}>It was too stressful</span>} />
                                <FormControlLabel sx={{...globals.body.mediumText, fontSize: mediumFontSize}} value="other" control={<Radio />} label={<span style={{fontSize: mediumFontSize!!}}>Other</span>} />
                            </RadioGroup>
                            {reasonForIncomplete === 'other' && (
                                <TextField
                                    label="Other reason"
                                    value={others}
                                    inputProps={{style: {fontSize: mediumFontSize!!}}}
                                    onChange={(event) => setOthers(event.target.value)}
                                    sx={{ width: '50%', color: formSubmitted ? (others ? colors.black : colors.error) : colors.black }}
                                />
                            )}
                        </FormControl>
                    </div>
                )}
            </FormControl>
        </div>
            <Button disabled={user?._doc.userType === 1 ? false : user?._doc._id === selectedUser ? false : true} variant="contained" type='submit' sx={{...globals.button, fontSize: xSmallFontSize, marginBottom: 10 }}>
                Submit Form
            </Button>
        </form>
    :
    <Box>
    <Typography sx={{...globals.body.mediumText, fontSize: mediumFontSize, fontWeight: "700", marginBottom: "15px"}}>{`You can't complete this task until ${new Date(data?.startDate).toDateString()}`}</Typography>
    </Box>
}
    </Box>
  );
};

export default SurveyForm;