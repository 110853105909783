import React, { useContext, useState } from 'react';
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  useMediaQuery,
  useTheme,
  Link
} from '@mui/material';
import globals from '../../styles/globals';
import colors from '../../styles/colors';
import { makeStyles } from '@mui/styles';
import { Menu as MenuIcon } from '@mui/icons-material';
import { IMAGES_URL } from '../../constants/constants';
import { AuthContext } from '../../context/authcontext';

// Custom styles
const useStyles = makeStyles((theme) => ({
  navbar: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: colors.aqua,
    height: 90,
    padding: 1,
  },
  mobileNav: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.aqua,
    height: 60,
    padding: 1,
  },
  selected: {
    backgroundColor: 'gold',
    color: colors.black,
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20%',
    cursor: 'pointer',
    position: "relative"
  },
  icon: {
    marginRight: 10,
    color: colors.black,
  },
  typography: {
    color: colors.white,
  },
}));

interface TabBarProps {
  selectedCard: number;
  handleCardClick: (index: number) => void;
}

const TabBar: React.FC<TabBarProps> = ({
  selectedCard,
  handleCardClick
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isPad = useMediaQuery(theme.breakpoints.down('md'));

  // Mobile Menu State
  const [mobileMenuAnchor, setMobileMenuAnchor] = React.useState<null | HTMLElement>(null);
  const { largeFontSize, mediumFontSize, smallFontSize } = useContext(AuthContext);
  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMenuAnchor(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchor(null);
  };

  // Modal State
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [showChildren, setShowChildren] = useState("ADVANCE CARE PLANNING DOCUMENTS");

  const handleMouseEnter = (label: string) => {
    console.log("event called")
    if (label === 'Resources') {
      setAnchorEl(true);
    }
    setShowChildren(label);
  };

  const handleMouseLeave = () => {
    setAnchorEl(false);
    setShowChildren("");
  };
  const handleMouseLeaveSubChild = () => {
    setShowChildren("");
  };

  const menuItems = [
    {
      label: 'Dashboard',
      logo: `${IMAGES_URL}DashLogo.png`,
      index: 0,
    },
    // {
    //   label: 'My Tasks',
    //   logo: `${IMAGES_URL}TaskLogo.png`,
    //   index: 1,
    // },
    {
      label: 'Resources',
      logo: `${IMAGES_URL}ResLogo.png`,
      index: 2,
    },
    {
      label: 'My Documents',
      logo: `${IMAGES_URL}DocLogo.png`,
      index: 3,
    },
    {
      label: 'Meet the Team',
      logo: `${IMAGES_URL}TaskLogo.png`,
      index: 4,
    },
    // {
    //   label: 'Contact',
    //   logo: `${IMAGES_URL}ContactLogo.png`,
    //   index: 5,
    // }
  ];

  const renderMenuItems = () => {
    return menuItems.map((item) => (
      <Box
        key={item.index}
        className={`${classes.box} ${selectedCard === item.index ? classes.selected : ''}`}
        onClick={() => {
          // handleMouseLeave();
          handleCardClick(item.index);
        }}
        onMouseEnter={() => handleMouseEnter(item.label)}
        onMouseLeave={() => handleMouseLeave()}
      >
        <img src={item.logo} alt="Frame" />
        <Typography
          variant="h6"
          style={{
            ...isPad ? globals.heading.smallText : globals.heading.largeText,
            fontSize: isPad ? smallFontSize!! : largeFontSize!!,
            color: selectedCard === item.index ? colors.black : colors.white,
            marginLeft: 10,
            lineHeight: 1
          }}
        >
          {item.label}
        </Typography>
        {item.index === 2 && anchorEl && renderResourcesModal}
      </Box>
    ));
  };

  const renderResourcesModal = (
    <Box
      sx={{
        position: 'absolute',
        top: '68px',
        left: '0px',
        width: 375 + (largeFontSize!! * 1.5),
        display: 'flex',
        bgcolor: '#03888E',
        border: '2px solid #03888E',
        boxShadow: 24,
        p: '20px',
        height: '400px',
        overflowY: 'auto',
        zIndex: 9,
      }}
      onMouseLeave={handleMouseLeaveSubChild}
    >
      <ul>
        <li>
          <Typography
            className="resourcecontent"
            variant="h3"
            style={{ ...globals.heading.mediumText, fontSize: mediumFontSize!!, color: 'white' }}
            onClick={(e: any) => {
              e.preventDefault();
              handleMouseEnter('ADVANCE CARE PLANNING DOCUMENTS')
            }}
          >
            ADVANCE CARE PLANNING DOCUMENTS
          </Typography>
          {showChildren =='ADVANCE CARE PLANNING DOCUMENTS'?(
            <Box mt={1} >
              <ul>
                <li>
                  <Link
                    href="https://www.alzheimers.gov/life-with-dementia/planning-for-future?utm_source=nia-eblast&utm_medium=email&utm_campaign=alzgov-20220412#financial-planning"
                    target="_blank"
                    style={{ ...globals.body.smallText, fontSize: smallFontSize!!, color: 'white' }}
                  >
                    Alzheimers.gov/life with dementia
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.nia.nih.gov/health/getting-your-affairs-order-checklist-documents-prepare-future?utm_source=nia-eblast&utm_medium=email&utm_campaign=caregiving-2023019#important-papers"
                    target="_blank"
                    style={{ ...globals.body.smallText, fontSize: smallFontSize!!, color: "white" }}
                  >
                    Getting your affairs in order checklist
                  </Link>
                </li>
                <li>
                  <Link target="_blank" href='https://www.aarp.org/caregiving/financial-legal/free-printable-advance-directives/' style={{ ...globals.body.smallText, fontSize: smallFontSize!!, color: "white" }}>
                    AARP – State-specific Advance Directives
                  </Link>
                </li>
                <li>
                  <Link
                    href="/dementia_provision.pdf"
                    target="_blank"
                    style={{ ...globals.body.smallText, fontSize: smallFontSize!!, color: "white" }}
                  >
                    Dementia Provision (Advance Directive Addendum)
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.nia.nih.gov/sites/default/files/2023-03/advance-care-planning-myths.pdf"
                    target="_blank"
                    style={{ ...globals.body.smallText, fontSize: smallFontSize!!, color: "white" }}
                  >
                    National Institute on Aging – Five myths about advance care planning
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.nia.nih.gov/health/advance-care-planning/choosing-health-care-proxy"
                    target="_blank"
                    style={{ ...globals.body.smallText, fontSize: smallFontSize!!, color: "white" }}
                  >
                    National Institute on Aging - Choosing a health care proxy
                  </Link>
                </li>
              </ul>
            </Box>):null}
        
        </li>
        <br />
        <br />
        <li>
          <Typography
            className="resourcecontent"
            variant="h3"
            style={{ ...globals.heading.mediumText, fontSize: mediumFontSize!!, color: 'white' }}
            onClick={(e: any) => {
              e.preventDefault();
              handleMouseEnter('CAREGIVERS')
            }}
          >
            CAREGIVERS
          </Typography>
          {showChildren === 'CAREGIVERS' && (
            <Box mt={1} >
              <ul>
                <li>
                  <Link
                    href="https://www.nia.nih.gov/health/making-decisions-someone-end-life?utm_source=nia-eblast&utm_medium=email&utm_campaign=caregiving-20220407"
                    target="_blank"
                    style={{ ...globals.body.smallText, fontSize: smallFontSize!!, color: "white" }}
                  >
                    Making Decisions for Someone Else
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.nia.nih.gov/health/alzheimers/caregiving"
                    target="_blank"
                    style={{ ...globals.body.smallText, fontSize: smallFontSize!!, color: "white" }}
                  >
                    Alzheimer’s Caregiving
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.caregiveraction.org/medication-checklist-caregivers"
                    target="_blank"
                    style={{ ...globals.body.smallText, fontSize: smallFontSize!!, color: "white" }}
                  >
                    Medication Checklist
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.aarp.org/caregiving/life-balance/info-2021/support-groups.html"
                    target="_blank"
                    style={{ ...globals.body.smallText, fontSize: smallFontSize!!, color: "white" }}
                  >
                    Caregiver Support
                  </Link>
                </li>
              </ul>
            </Box>
          )}
        </li>
        <br />
        <br />
        <li>
          <Typography
            className="resourcecontent"
            variant="h3"
            style={{ ...globals.heading.mediumText, fontSize: mediumFontSize!!, color: 'white' }}
            onClick={(e: any) => {
              e.preventDefault();
              handleMouseEnter('ABOUT ALZHEIMER’S AND OTHER DEMENTIAS')
            }}
          >
            ABOUT ALZHEIMER’S AND OTHER DEMENTIAS
          </Typography>
          {showChildren === 'ABOUT ALZHEIMER’S AND OTHER DEMENTIAS' && (
            <Box mt={1} >
              <ul>
                <li>
                  <Link
                    href="https://www.youtube.com/watch?v=Eq_Er-tqPsA"
                    target="_blank"
                    style={{ ...globals.body.smallText, fontSize: smallFontSize!!, color: "white" }}
                  >
                    Understand Alzheimer’s in 3 minutes (video)
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.hopkinsmedicine.org/health/conditions-and-diseases/alzheimers-disease/stages-of-alzheimer-disease"
                    target="_blank"
                    style={{ ...globals.body.smallText, fontSize: smallFontSize!!, color: "white" }}
                  >
                    Stages of Alzheimer’s
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.nia.nih.gov/health/infographics/understanding-different-types-dementia?utm_source=nia-eblast&utm_medium=email&utm_campaign=alzgov-20221101"
                    target="_blank"
                    style={{ ...globals.body.smallText, fontSize: smallFontSize!!, color: "white" }}
                  >
                    Understanding different types of dementia
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.nia.nih.gov/health/frequently-asked-questions-about-alzheimers-disease?utm_source=nia-eblast&amp;utm_medium=email&amp;utm_campaign=healthyaging-20230605#difference"
                    target="_blank"
                    style={{ ...globals.body.smallText, fontSize: smallFontSize!!, color: "white" }}
                  >
                    8 Questions about Alzheimer’s
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.nia.nih.gov/sites/default/files/2020-09/next-steps-after-alzheimers-diagnosis.pdf"
                    target="_blank"
                    style={{ ...globals.body.smallText, fontSize: smallFontSize!!, color: "white" }}
                  >
                    National Institute on Aging
                  </Link>
                </li>
              </ul>
            </Box>
          )}
        </li>
      </ul>
    </Box>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMenuAnchor}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(mobileMenuAnchor)}
      onClose={handleMobileMenuClose}
    >
      {menuItems.map((item) => (
        <MenuItem
          key={item.index}
          className={selectedCard === item.index ? 'selected' : ''}
          onClick={() => handleCardClick(item.index)}
        >
          <Typography variant="h6">{item.label}</Typography>
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <>
      <Box className={isMobile ? classes.mobileNav : classes.navbar} sx={{ height: 30 + (largeFontSize!! * 1.5) }}>
        {isMobile ? (
          <Box ml={3} alignItems="center" justifyContent="center">
            <IconButton
              aria-label="menu"
              style={{ color: colors.dark }}
              edge="end"
              onClick={handleMobileMenuOpen}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        ) : (
          <>
            {renderMenuItems()}
          </>
        )}
      </Box>
      {isMobile && renderMobileMenu}
    </>
  );
};

export default TabBar;