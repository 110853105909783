import React, { useContext, useState } from 'react';
import { AuthContext } from '../../context/authcontext';
import { Card, Col, Divider, Layout, Menu, Progress, Row, Space, Table, Tag } from 'antd';
import Sider from 'antd/es/layout/Sider';
import Layouts from '../../components/Layout/index';
import { AdminPanelSettingsSharp, DashboardOutlined, OutboxOutlined, RecordVoiceOver, SettingsOutlined, VerifiedUserOutlined } from '@mui/icons-material';
import { Content } from 'antd/es/layout/layout';
import UserListTable from './Users';
import colors from '../../styles/colors';
import { LinearProgress, Typography } from '@mui/material';
import globals from '../../styles/globals';
import { getTasksCompletion, getUsersByType } from '../../api/auth';
import './styles.css'
import { ColumnsType, TableProps } from 'antd/es/table';
import UsersProgress from './UsersProgress';
import UsersZoomRecording from './UsersZoomRecording';
import Admins from './Admins';

interface ActivityType{
    activity1: number | 0,
    activity2: number | 0,
    activity3: number | 0,
    activity4: number | 0,
}

interface DataType {
    key: React.Key;
    name: string;
    email: string;
    activity1: string;
    activity2: string;
    activity3: string;
    activity4: string;
}

const SuperDashboard: React.FC = () => {
    const { user } = useContext(AuthContext);
    const [menu, setMenu] = useState("1");
    const [usersCount, setUsersCount] = useState<any | undefined>();
    const [loadingCount, setLoadingCount] = useState(false);
    const [loadingActCount, setLoadingActCount] = useState(false);
    const [actCompletion, setActCompletion] = useState<ActivityType>({
        activity1: 0,
        activity2: 0,
        activity3: 0,
        activity4: 0,
    });
    const [usersActivityDetail, setUsersActivityDetail] = useState([]);


    // Check if the user is logged in and set the selected card index to 0
    React.useEffect(() => {
        setLoadingCount(true);
        setLoadingActCount(true);
        getUsersByType(user?.token).then(res => {
            setLoadingCount(false);
            setUsersCount(res);
        }).catch(err => {
            setUsersCount({ patients: 0, care_partner: 0 });
            setLoadingCount(false);
        })
        getTasksCompletion(user?.token).then((res:any) => {
            setLoadingActCount(false);
            setActCompletion(res.completionPercentages)
            setUsersActivityDetail(res.usersActivityProgress)         
        }).catch(err => {
            setUsersActivityDetail([])         
            setLoadingActCount(false);   
        })
    }, []);

    const columns: ColumnsType<DataType> = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
        },
        {
            title: 'Activity 1',
            dataIndex: 'activity1',
            filters: [
                {
                    text: 'Completed',
                    value: 'Completed',
                },
                {
                    text: 'In Completed',
                    value: 'InComplete',
                },
            ],
            // @ts-ignore
            onFilter: (value: string | number | boolean, record: DataType) => record.activity1.indexOf(String(value)) === 0,
        },
        {
            title: 'Activity 2',
            dataIndex: 'activity2',
            filters: [
                {
                    text: 'Completed',
                    value: 'Completed',
                },
                {
                    text: 'In Completed',
                    value: 'InComplete',
                },
            ],
            // @ts-ignore
            onFilter: (value: string | number | boolean, record: DataType) => record.activity2.indexOf(String(value)) === 0,
        },
        {
            title: 'Activity 3',
            dataIndex: 'activity3',
            filters: [
                {
                    text: 'Completed',
                    value: 'Completed',
                },
                {
                    text: 'In Completed',
                    value: 'InComplete',
                },
            ],
            // @ts-ignore
            onFilter: (value: string | number | boolean, record: DataType) => record.activity3.indexOf(String(value)) === 0,
        },
        {
            title: 'Activity 4',
            dataIndex: 'activity4',
            filters: [
                {
                    text: 'Completed',
                    value: 'Completed',
                },
                {
                    text: 'In Completed',
                    value: 'InComplete',
                },
            ],
            // @ts-ignore
            onFilter: (value: string | number | boolean, record: DataType) => record.activity4.indexOf(String(value)) === 0,
        },
    ];

    const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {

    };

    const rowClassName = (record: DataType, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    const Dashboard = () => {
        return (
            <>
                <Row gutter={16}>
                    <Col span={6}>
                        <Card loading={loadingCount} headStyle={{ color: "white" }} hoverable style={{ height: "200px", background: colors.aqua, color: 'white' }} color='white' title="Patients" bordered={false}>
                            <Row gutter={2}>
                                <Col span={12}>
                                    <Typography sx={{ ...globals.heading.largeText, fontWeight: "600", fontSize: "xxx-large" }}>TOTAL</Typography>
                                </Col>
                                <Col span={12}>
                                    <Typography sx={{ ...globals.heading.largeText, fontWeight: "600", fontSize: "xxx-large", textAlign: "right" }}>{usersCount?.patients}</Typography>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card loading={loadingCount} headStyle={{ color: "white" }} hoverable style={{ height: "200px", background: colors.aqua, color: 'white' }} title="Care Partners" bordered={false}>
                            <Row gutter={2}>
                                <Col span={12}>
                                    <Typography sx={{ ...globals.heading.largeText, fontWeight: "600", fontSize: "xxx-large" }}>TOTAL</Typography>
                                </Col>
                                <Col span={12}>
                                    <Typography sx={{ ...globals.heading.largeText, fontWeight: "600", fontSize: "xxx-large", textAlign: "right" }}>{usersCount?.care_partner}</Typography>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card onClick={() => setMenu("2")} loading={loadingCount} headStyle={{ color: "white" }} hoverable style={{ height: "200px", background: colors.dark, color: 'white' }} title="Users" bordered={false}>
                            <Row gutter={2}>
                                <Col span={12}>
                                    <Typography sx={{ ...globals.heading.largeText, fontWeight: "600", fontSize: "xxx-large" }}>TOTAL</Typography>
                                </Col>
                                <Col span={12}>
                                    <Typography sx={{ ...globals.heading.largeText, fontWeight: "600", fontSize: "xxx-large", textAlign: "right" }}>{usersCount?.care_partner + usersCount?.patients}</Typography>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <br></br>
                <Divider />
                <br></br>
                <Row gutter={16} style={{ minHeight: '55vh' }}>
                    <Col span={12}>
                        <Card loading={loadingCount} headStyle={{ color: "white" }} hoverable style={{ height: "100%", background: colors.aqua, color: 'white' }} color='white' title="User Activities Completion" bordered={false}>
                        <Space wrap>

                            <Table tableLayout='fixed' pagination={{pageSize: 5}} style={{height: "100%", fontSize: "0.85vw"}} rowClassName={rowClassName} loading={loadingActCount} columns={columns} dataSource={usersActivityDetail} onChange={onChange} />
                        </Space>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Row gutter={[16, 16]} style={{ height: "100%" }}>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card
                                    loading={loadingActCount}
                                    headStyle={{ color: 'white' }}
                                    hoverable
                                    style={{ height: '100%', background: colors.dark, color: 'white' }}
                                    title="Activity 1 Completion"
                                    bordered={false}
                                >
                                    <Row justify="center" gutter={2}>
                                        <Space wrap>
                                            <Progress strokeColor={actCompletion?.activity1 === 100 ? 'green' : actCompletion?.activity1 > 50 ? "blue" : "red"} type="circle" percent={actCompletion?.activity1} trailColor="white" />
                                        </Space>
                                    </Row>
                                </Card>
                            </Col>

                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card
                                    loading={loadingActCount}
                                    headStyle={{ color: 'white' }}
                                    hoverable
                                    style={{ height: '100%', background: colors.dark, color: 'white' }}
                                    title="Activity 2 Completion"
                                    bordered={false}
                                >
                                    <Row justify="center" gutter={2}>
                                        <Space wrap>
                                            <Progress strokeColor={actCompletion?.activity2 === 100 ? 'green' : actCompletion?.activity2 > 50 ? "blue" : "red"} type="circle" percent={actCompletion?.activity2} trailColor="white" />
                                        </Space>
                                    </Row>
                                </Card>
                            </Col>

                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card
                                    loading={loadingActCount}
                                    headStyle={{ color: 'white' }}
                                    hoverable
                                    style={{ height: '100%', background: colors.dark, color: 'white' }}
                                    title="Activity 3 Completion"
                                    bordered={false}
                                >
                                    <Row justify="center" gutter={2}>
                                        <Space wrap>
                                            <Progress strokeColor={actCompletion?.activity3 === 100 ? 'green' : actCompletion?.activity3 > 50 ? "blue" : "red"} type="circle" percent={actCompletion?.activity3} trailColor="white" />
                                        </Space>
                                    </Row>
                                </Card>
                            </Col>

                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card
                                    loading={loadingActCount}
                                    headStyle={{ color: 'white' }}
                                    hoverable
                                    style={{ height: '100%', background: colors.dark, color: 'white' }}
                                    title="Activity 4 Completion"
                                    bordered={false}
                                >
                                    <Row justify="center" gutter={2}>
                                        <Space wrap>
                                            <Progress strokeColor={actCompletion?.activity4 === 100 ? 'green' : actCompletion?.activity4 > 50 ? "blue" : "red"} type="circle" percent={actCompletion?.activity4} trailColor="white" />
                                        </Space>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </>
        )
    }

    const ContentItemsSuperAdmin = () => {
        switch (menu) {
            case '1':
                return <Dashboard />;
            case '2':
                return <UserListTable />;
            case '3':
                return <UsersProgress />;
            case '4':
                return <UsersZoomRecording />;
            case '6':
                return <Admins/>;
            default:
                return <></>;
        }
    }
    const ContentItemsAdmin = () => {
        switch (menu) {
            case '1':
                return <Dashboard />;
            case '2':
                return <UserListTable />;
            case '3':
                return <UsersProgress />;
            case '4':
                return <UsersZoomRecording />;
            default:
                return <></>;
        }
    }

    return (
        <Layouts sendSelectedCard={() => { }}>
            <Layout style={{ minHeight: '90vh' }}>
                <Sider width={220} theme="dark">
                    <Menu onClick={(event) => setMenu(event.key)} mode="vertical" theme="dark" selectedKeys={[menu]} defaultSelectedKeys={['1']}>
                        <Menu.Item key="1" icon={<DashboardOutlined />}>Dashboard</Menu.Item>
                        <Menu.Item key="2" icon={<VerifiedUserOutlined />}>Users List</Menu.Item>
                        <Menu.Item key="3" icon={<LinearProgress style={{marginBottom: "5px"}}/>}>Users Activity Track</Menu.Item>
                        <Menu.Item key="4" icon={<RecordVoiceOver/>}>Users Zoom Recording Info</Menu.Item>
                        <Menu.Item key="5" icon={<SettingsOutlined />}>Settings</Menu.Item>
                        {user?._doc.adminType===0 && <Menu.Item key="6" icon={<AdminPanelSettingsSharp />}>Admins</Menu.Item>}
                    </Menu>
                </Sider>
                <Layout>
                    <Content style={{ padding: '20px' }}>
                    { user?._doc.adminType===0 ?
                        <ContentItemsSuperAdmin />
                        :
                        <ContentItemsAdmin />
                    }
                    </Content>
                </Layout>
            </Layout>
        </Layouts>
    );
};


export default SuperDashboard;