import React, { useContext, useEffect, useState } from 'react';
import { getUserProgressTasks } from '../../../api/auth';
import { AuthContext } from '../../../context/authcontext';
import Table, { ColumnsType, TableProps } from 'antd/es/table';


interface DataType {
    key: React.Key;
    name: string;
    email: string;
    activity1: string;
    activity2: string;
    activity3: string;
    activity4: string;
}

const UsersProgress = () => {

    const{user} = useContext(AuthContext);
    const [allUsersProgress, setAllUsersProgress] = useState([]);
    const [loading, setLoading] = useState(false);


    const columns: ColumnsType<DataType> = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
        },
        {
            title: 'Activity 1 Track',
            dataIndex: 'activity1',
            filters: [
                {
                    text: 'On Target',
                    value: 'On Target',
                },
                {
                    text: 'Not on Target',
                    value: 'Not on Target',
                },
            ],
            // @ts-ignore
            onFilter: (value: string | number | boolean, record: DataType) => record.activity1.indexOf(String(value)) === 0,
        },
        {
            title: 'Activity 2 Track',
            dataIndex: 'activity2',
            filters: [
                {
                    text: 'On Target',
                    value: 'On Target',
                },
                {
                    text: 'Not on Target',
                    value: 'Not on Target',
                },
            ],
            // @ts-ignore
            onFilter: (value: string | number | boolean, record: DataType) => record.activity2.indexOf(String(value)) === 0,
        },
        {
            title: 'Activity 3 Track',
            dataIndex: 'activity3',
            filters: [
                {
                    text: 'On Target',
                    value: 'On Target',
                },
                {
                    text: 'Not on Target',
                    value: 'Not on Target',
                },
            ],
            // @ts-ignore
            onFilter: (value: string | number | boolean, record: DataType) => record.activity3.indexOf(String(value)) === 0,
        },
        {
            title: 'Activity 4 Track',
            dataIndex: 'activity4',
            filters: [
                {
                    text: 'On Target',
                    value: 'On Target',
                },
                {
                    text: 'Not on Target',
                    value: 'Not on Target',
                },
            ],
            // @ts-ignore
            onFilter: (value: string | number | boolean, record: DataType) => record.activity4.indexOf(String(value)) === 0,
        },
    ];

    const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {

    };

    const rowClassName = (record: DataType, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    useEffect(()=> {
        setLoading(true)
        getUserProgressTasks(user?.token).then((res: any)=> {
            setLoading(false);
            if(res){
                setAllUsersProgress(res)
            }
        }).catch(err=> {
            setLoading(false);
        })

    }, [])

    return (
        <>
            <Table tableLayout='fixed' pagination={{pageSize: 5}} style={{height: "100%", fontSize: "0.85vw"}} rowClassName={rowClassName} loading={loading} columns={columns} dataSource={allUsersProgress} onChange={onChange} />
        </>
    )
}

export default UsersProgress;