import React, { useContext, useEffect, useState } from 'react';
import { Card, Typography, Grid, Box, Input, RadioGroup, FormControlLabel, Radio, FormGroup, Checkbox, Button } from '@mui/material';
import colors from '../../styles/colors';
import globals from '../../styles/globals';
import '../../styles/styles.css';
import leadGuideCover from '../../assets/lead assets/leadGuideCover.png';
import CPR from '../../assets/lead assets/CPR.png';
import DNR from '../../assets/lead assets/DNR.png';
import FeedingTube from '../../assets/lead assets/FeedingTube.png';
import vent from '../../assets/lead assets/vent.png';
import leadBack from '../../assets/lead assets/leadBack.png';
import { AuthContext } from '../../context/authcontext';
import { createLeadGuideApi, updateLeadGuideApi } from '../../api/auth';
import Loader from '../Loader';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { ToastContainer, toast } from 'react-toastify';
import TextArea from 'antd/es/input/TextArea';
interface CustomFormProps {
  activity: Number,
  isShowHeader: Boolean
}

const LeadGuide: React.FC<CustomFormProps> = ({ activity, isShowHeader }) => {

  const { user, formDataAll, setFormDataAll, selectedUser, largeFontSize, mediumFontSize, xSmallFontSize } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [months, setMonths] = useState(["Jan", "Feb", "March", "April", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]);
  const [name, setName] = useState(user?._doc.name);
  const [currentDate, setCurrentDate] = useState<Date>(new Date(formDataAll?.userLeadGuide?.date ?? new Date));
  const [day, setDay] = useState(currentDate.getDate());
  const [month, setMonth] = useState(months[currentDate.getMonth()]);
  const [year, setYear] = useState(currentDate.getFullYear());
  const [data, setData] = useState({
    livingWill: formDataAll?.userLeadGuide?.livingWill ?? null,
    dnr: formDataAll?.userLeadGuide?.dnr ?? null,
    power: formDataAll?.userLeadGuide?.power ?? null,
    who_poa: formDataAll?.userLeadGuide?.who_poa ?? '',
    qol_1: formDataAll?.userLeadGuide?.qol_1 ?? null,
    qol_2: formDataAll?.userLeadGuide?.qol_2 ?? null,
    qol_3: formDataAll?.userLeadGuide?.qol_3 ?? null,
    qol_4: formDataAll?.userLeadGuide?.qol_4 ?? null,
    qol_meanning: formDataAll?.userLeadGuide?.qol_meanning ?? '',
    burden_1: formDataAll?.userLeadGuide?.burden_1 ?? null,
    burden_2: formDataAll?.userLeadGuide?.burden_2 ?? null,
    burden_3: formDataAll?.userLeadGuide?.burden_3 ?? null,
    convo_1: formDataAll?.userLeadGuide?.convo_1 ?? 0,
    convo_2: formDataAll?.userLeadGuide?.convo_2 ?? 0,
    convo_3: formDataAll?.userLeadGuide?.convo_3 ?? 0,
    convo_4: formDataAll?.userLeadGuide?.convo_4 ?? 0,
    convo_5: formDataAll?.userLeadGuide?.convo_5 ?? 0,
    convo_other: formDataAll?.userLeadGuide?.convo_other ?? '',
    convo_names: formDataAll?.userLeadGuide?.convo_names ?? "",
    dec_maker: formDataAll?.userLeadGuide?.dec_maker ?? null,
    dec_maker_other: formDataAll?.userLeadGuide?.dec_maker_other ?? "",
    dec_maker_name: formDataAll?.userLeadGuide?.dec_maker_name ?? "",
    loc_care_1: formDataAll?.userLeadGuide?.loc_care_1 ?? 0,
    loc_care_2: formDataAll?.userLeadGuide?.loc_care_2 ?? 0,
    loc_care_3: formDataAll?.userLeadGuide?.loc_care_3 ?? 0,
    loc_care_4: formDataAll?.userLeadGuide?.loc_care_4 ?? 0,
    loc_care_5: formDataAll?.userLeadGuide?.loc_care_5 ?? 0,
    loc_care_other: formDataAll?.userLeadGuide?.loc_care_other ?? '',
    loc_death_1: formDataAll?.userLeadGuide?.loc_death_1 ?? 0,
    loc_death_2: formDataAll?.userLeadGuide?.loc_death_2 ?? 0,
    loc_death_3: formDataAll?.userLeadGuide?.loc_death_3 ?? 0,
    loc_death_4: formDataAll?.userLeadGuide?.loc_death_4 ?? 0,
    loc_death_5: formDataAll?.userLeadGuide?.loc_death_5 ?? 0,
    loc_death_other: formDataAll?.userLeadGuide?.loc_death_other ?? '',
    life_prolong_1: formDataAll?.userLeadGuide?.life_prolong_1 ?? 0,
    life_prolong_2: formDataAll?.userLeadGuide?.life_prolong_2 ?? 0,
    life_prolong_3: formDataAll?.userLeadGuide?.life_prolong_3 ?? 0,
    life_prolong_4: formDataAll?.userLeadGuide?.life_prolong_4 ?? 0,
    life_prolong_5: formDataAll?.userLeadGuide?.life_prolong_5 ?? 0,
    life_prolong_other: formDataAll?.userLeadGuide?.life_prolong_other ?? '',
    death_when_1: formDataAll?.userLeadGuide?.death_when_1 ?? 0,
    death_when_2: formDataAll?.userLeadGuide?.death_when_2 ?? 0,
    death_when_3: formDataAll?.userLeadGuide?.death_when_3 ?? 0,
    death_when_4: formDataAll?.userLeadGuide?.death_when_4 ?? 0,
    death_when_5: formDataAll?.userLeadGuide?.death_when_5 ?? 0,
    death_when_other: formDataAll?.userLeadGuide?.death_when_other ?? '',
    decision_text: formDataAll?.userLeadGuide?.decision_text ?? '',
    location_24hr: formDataAll?.userLeadGuide?.location_24hr ?? null,
    location_24hr_other: formDataAll?.userLeadGuide?.location_24hr_other ?? '',
    lifesup: formDataAll?.userLeadGuide?.lifesup ?? null,
    brain: formDataAll?.userLeadGuide?.brain ?? null,
    tube: formDataAll?.userLeadGuide?.tube ?? null,
    pain: formDataAll?.userLeadGuide?.pain ?? null,
    con_food: formDataAll?.userLeadGuide?.con_food ?? null,
    con_self: formDataAll?.userLeadGuide?.con_self ?? null,
    con_meds: formDataAll?.userLeadGuide?.con_meds ?? null,
    location_nodec: formDataAll?.userLeadGuide?.location_nodec ?? null,
    location_nodec_other: formDataAll?.userLeadGuide?.location_nodec_other ?? '',
    lifesup_nodec: formDataAll?.userLeadGuide?.lifesup_nodec ?? null,
    brain_nodec: formDataAll?.userLeadGuide?.brain_nodec ?? null,
    tube_nodec: formDataAll?.userLeadGuide?.tube_nodec ?? null,
    pain_nodec: formDataAll?.userLeadGuide?.pain_nodec ?? null,
    more_info_nodec: formDataAll?.userLeadGuide?.more_info_nodec ?? '',
    add_instruct_nodec: formDataAll?.userLeadGuide?.add_instruct_nodec ?? '',
    share_lead_1: formDataAll?.userLeadGuide?.share_lead_1 ?? 0,
    share_lead_2: formDataAll?.userLeadGuide?.share_lead_2 ?? 0,
    share_lead_3: formDataAll?.userLeadGuide?.share_lead_3 ?? 0,
    share_lead_4: formDataAll?.userLeadGuide?.share_lead_4 ?? 0,
    share_lead_5: formDataAll?.userLeadGuide?.share_lead_5 ?? 0,
    share_lead_6: formDataAll?.userLeadGuide?.share_lead_6 ?? 0,
    share_lead_7: formDataAll?.userLeadGuide?.share_lead_7 ?? 0
  });

  const [wishesAfterDeath, setWishesAfterDeath] = useState('');

  const [additionalLegalDoc, setAdditionalLegalDoc] = useState('');
  useEffect(() => {
    const timer = setTimeout(() => {
      const storedData = localStorage.getItem('userResponses');
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        // if (formDataAll) {
        const local_data = {
          livingWill: parsedData?.livingWill ?? null,
          dnr: parsedData?.dnr ?? null,
          power: parsedData?.power ?? null,
          who_poa: parsedData?.who_poa ?? '',
          qol_1: parsedData?.qol_1 ?? null,
          qol_2: parsedData?.qol_2 ?? null,
          qol_3: parsedData?.qol_3 ?? null,
          qol_4: parsedData?.qol_4 ?? null,
          qol_meanning: parsedData?.qol_meanning ?? '',
          burden_1: parsedData?.burden_1 ?? null,
          burden_2: parsedData?.burden_2 ?? null,
          burden_3: parsedData?.burden_3 ?? null,
          convo_1: parsedData?.convo_1 ?? 0,
          convo_2: parsedData?.convo_2 ?? 0,
          convo_3: parsedData?.convo_3 ?? 0,
          convo_4: parsedData?.convo_4 ?? 0,
          convo_5: parsedData?.convo_5 ?? 0,
          convo_other: parsedData?.convo_other ?? '',
          convo_names: parsedData?.convo_names ?? "",
          dec_maker: parsedData?.dec_maker ?? null,
          dec_maker_other: parsedData?.dec_maker_other ?? "",
          dec_maker_name: parsedData?.dec_maker_name ?? "",
          loc_care_1: parsedData?.loc_care_1 ?? 0,
          loc_care_2: parsedData?.loc_care_2 ?? 0,
          loc_care_3: parsedData?.loc_care_3 ?? 0,
          loc_care_4: parsedData?.loc_care_4 ?? 0,
          loc_care_5: parsedData?.loc_care_5 ?? 0,
          loc_care_other: parsedData?.loc_care_other ?? '',
          loc_death_1: parsedData?.loc_death_1 ?? 0,
          loc_death_2: parsedData?.loc_death_2 ?? 0,
          loc_death_3: parsedData?.loc_death_3 ?? 0,
          loc_death_4: parsedData?.loc_death_4 ?? 0,
          loc_death_5: parsedData?.loc_death_5 ?? 0,
          loc_death_other: parsedData?.loc_death_other ?? '',
          life_prolong_1: parsedData?.life_prolong_1 ?? 0,
          life_prolong_2: parsedData?.life_prolong_2 ?? 0,
          life_prolong_3: parsedData?.life_prolong_3 ?? 0,
          life_prolong_4: parsedData?.life_prolong_4 ?? 0,
          life_prolong_5: parsedData?.life_prolong_5 ?? 0,
          life_prolong_other: parsedData?.life_prolong_other ?? '',
          death_when_1: parsedData?.death_when_1 ?? 0,
          death_when_2: parsedData?.death_when_2 ?? 0,
          death_when_3: parsedData?.death_when_3 ?? 0,
          death_when_4: parsedData?.death_when_4 ?? 0,
          death_when_5: parsedData?.death_when_5 ?? 0,
          death_when_other: parsedData?.death_when_other ?? '',
          decision_text: parsedData?.decision_text ?? '',
          location_24hr: parsedData?.location_24hr ?? null,
          location_24hr_other: parsedData?.location_24hr_other ?? '',
          lifesup: parsedData?.lifesup ?? null,
          brain: parsedData?.brain ?? null,
          tube: parsedData?.tube ?? null,
          pain: parsedData?.pain ?? null,
          con_food: parsedData?.con_food ?? null,
          con_self: parsedData?.con_self ?? null,
          con_meds: parsedData?.con_meds ?? null,
          location_nodec: parsedData?.location_nodec ?? null,
          location_nodec_other: parsedData?.location_nodec_other ?? '',
          lifesup_nodec: parsedData?.lifesup_nodec ?? null,
          brain_nodec: parsedData?.brain_nodec ?? null,
          tube_nodec: parsedData?.tube_nodec ?? null,
          pain_nodec: parsedData?.pain_nodec ?? null,
          more_info_nodec: parsedData?.more_info_nodec ?? '',
          add_instruct_nodec: parsedData?.add_instruct_nodec ?? '',
          share_lead_1: parsedData?.share_lead_1 ?? 0,
          share_lead_2: parsedData?.share_lead_2 ?? 0,
          share_lead_3: parsedData?.share_lead_3 ?? 0,
          share_lead_4: parsedData?.share_lead_4 ?? 0,
          share_lead_5: parsedData?.share_lead_5 ?? 0,
          share_lead_6: parsedData?.share_lead_6 ?? 0,
          share_lead_7: parsedData?.share_lead_7 ?? 0
        }
        console.log("Parsed data :", local_data.livingWill)
        setData(local_data)
      // }
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [formDataAll]);
  const handleChange1 = (event: any) => {
    console.log("ev", event)
    setData((prevState) => ({ ...prevState, livingWill: parseInt(event.target.value) }));
    const updatedata = { ...data, livingWill: parseInt(event.target.value) };
    localStorage.setItem('userResponses', JSON.stringify(updatedata));
  }

  const handleChange2 = (event: any) => {
    setData((prevState) => ({ ...prevState, dnr: parseInt(event.target.value) }));
    const updatedata = { ...data, dnr: parseInt(event.target.value) };
    localStorage.setItem('userResponses', JSON.stringify(updatedata));
  };

  const handleChange3 = (event: any) => {
    if (event.target.value == 0)
      setData((prevState) => ({ ...prevState, who_poa: "" }));
    setData((prevState) => ({ ...prevState, power: parseInt(event.target.value) }));
    const updatedata = { ...data, power: parseInt(event.target.value) };
    localStorage.setItem('userResponses', JSON.stringify(updatedata));
  };

  const handleChange4 = (event: any) => {
    setData((prevState) => ({ ...prevState, qol_1: parseInt(event.target.value) }));
    const updatedata = { ...data, qol_1: parseInt(event.target.value) };
    localStorage.setItem('userResponses', JSON.stringify(updatedata));
  };

  const handleChange5 = (event: any) => {
    setData((prevState) => ({ ...prevState, qol_2: parseInt(event.target.value) }));
    const updatedata = { ...data, qol_2: parseInt(event.target.value) };
    localStorage.setItem('userResponses', JSON.stringify(updatedata));
  };

  const handleChange6 = (event: any) => {
    setData((prevState) => ({ ...prevState, qol_3: parseInt(event.target.value) }));
    const updatedata = { ...data, qol_3: parseInt(event.target.value) };
    localStorage.setItem('userResponses', JSON.stringify(updatedata));
  };

  const handleChange7 = (event: any) => {
    setData((prevState) => ({ ...prevState, qol_4: parseInt(event.target.value) }));
    const updatedata = { ...data, qol_4: parseInt(event.target.value) };
    localStorage.setItem('userResponses', JSON.stringify(updatedata));
  };

  const handleChange8 = (event: any) => {
    setData((prevState) => ({ ...prevState, burden_1: parseInt(event.target.value) }));
    const updatedata = { ...data, burden_1: parseInt(event.target.value) };
    localStorage.setItem('userResponses', JSON.stringify(updatedata));
  };

  const handleChange9 = (event: any) => {
    setData((prevState) => ({ ...prevState, burden_2: parseInt(event.target.value) }));
    const updatedata = { ...data, burden_2: parseInt(event.target.value) };
    localStorage.setItem('userResponses', JSON.stringify(updatedata));
  };

  const handleChange10 = (event: any) => {
    setData((prevState) => ({ ...prevState, burden_3: parseInt(event.target.value) }));
    const updatedata = { ...data, burden_3: parseInt(event.target.value) };
    localStorage.setItem('userResponses', JSON.stringify(updatedata));
  };

  const handleChange11 = (name: string, event: React.ChangeEvent<HTMLInputElement>) => {
    if (name === "convo_4")
      setData((prevState) => ({ ...prevState, convo_other: "" }));
    setData((prevState) => ({ ...prevState, [name]: event.target.checked ? 1 : 0 }));
    const updatedata = { ...data, [name]: event.target.checked ? 1 : 0 };
    localStorage.setItem('userResponses', JSON.stringify(updatedata));
  };

  const handleChange12 = (event: any) => {
    if (event.target.value != 3)
      setData((prevState) => ({ ...prevState, dec_maker_other: "" }));
    setData((prevState) => ({ ...prevState, dec_maker: parseInt(event.target.value) }));
    const updatedata = { ...data, dec_maker: parseInt(event.target.value) };
    localStorage.setItem('userResponses', JSON.stringify(updatedata));
  };

  const handleChange13 = (name: string, event: React.ChangeEvent<HTMLInputElement>) => {
    if (name === "loc_care_4")
      setData((prevState) => ({ ...prevState, loc_care_other: "" }));
    setData((prevState) => ({ ...prevState, [name]: event.target.checked ? 1 : 0 }));
    const updatedata = { ...data, [name]: event.target.checked ? 1 : 0 };
    localStorage.setItem('userResponses', JSON.stringify(updatedata));
  };

  const handleChange14 = (name: string, event: React.ChangeEvent<HTMLInputElement>) => {
    if (name === "loc_death_4")
      setData((prevState) => ({ ...prevState, loc_death_other: "" }));
    setData((prevState) => ({ ...prevState, [name]: event.target.checked ? 1 : 0 }));
    const updatedata = { ...data, [name]: event.target.checked ? 1 : 0 };
    localStorage.setItem('userResponses', JSON.stringify(updatedata));
  };

  const handleChange15 = (name: string, event: React.ChangeEvent<HTMLInputElement>) => {
    if (name === "life_prolong_4")
      setData((prevState) => ({ ...prevState, life_prolong_other: "" }));
    setData((prevState) => ({ ...prevState, [name]: event.target.checked ? 1 : 0 }));
    const updatedata = { ...data, [name]: event.target.checked ? 1 : 0 };
    localStorage.setItem('userResponses', JSON.stringify(updatedata));
  };

  const handleChange16 = (name: string, event: React.ChangeEvent<HTMLInputElement>) => {
    if (name === "death_when_4")
      setData((prevState) => ({ ...prevState, death_when_other: "" }));
    setData((prevState) => ({ ...prevState, [name]: event.target.checked ? 1 : 0 }));
    const updatedata = { ...data, [name]: event.target.checked ? 1 : 0 };
    localStorage.setItem('userResponses', JSON.stringify(updatedata));
  };

  const handleChange17 = (event: any) => {
    if (event.target.value != 2)
      setData((prevState) => ({ ...prevState, location_24hr_other: "" }));
    setData((prevState) => ({ ...prevState, location_24hr: parseInt(event.target.value) }));
    const updatedata = { ...data, location_24hr: parseInt(event.target.value) };
    localStorage.setItem('userResponses', JSON.stringify(updatedata));
  };

  const handleChange20 = (event: any) => {
    if (event.target.value != 2)
      setData((prevState) => ({ ...prevState, location_nodec_other: "" }));
    setData((prevState) => ({ ...prevState, location_nodec: parseInt(event.target.value) }));
    const updatedata = { ...data, location_nodec: parseInt(event.target.value) };
    localStorage.setItem('userResponses', JSON.stringify(updatedata));
  };

  const handleChangeShareLeadGuide = (name: string, event: React.ChangeEvent<HTMLInputElement>) => {
    setData((prevState) => ({ ...prevState, [name]: event.target.checked ? 1 : 0 }));
    const updatedata = { ...data, [name]: event.target.checked ? 1 : 0 };
    localStorage.setItem('userResponses', JSON.stringify(updatedata));
  }

  const showSuccessMessage = (message: string) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      hideProgressBar: false,
    });
  };

  const showErrorMessage = (message: string) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      hideProgressBar: false,
    });
  };
  useEffect(() => {
    const showAlert = () => {
        const storedData = localStorage.getItem('userResponses');
        if (storedData) {
            alert('Don’t forget to click "Save My Answers" to save your LEAD Guide answers. Your answers will not be saved until you click "Save My Answers."');
            
        }
    };
    const intervalId = setInterval(showAlert, 300000);
    return () => clearInterval(intervalId);
}, []);
  const handleSubmitLead = () => {
    const anyNullOrEmpty = Object.values(data).some(value => value == null || value == '');
    if (anyNullOrEmpty) {
      toast.success('Saved, but missing some answers.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
      });
    }
    setLoading(true);
    const dataSend = {
      ...data,
      record_id: user?._doc.record_id,
      form_mod: "activity2",
      user: user?._doc._id,
      userType: user?._doc.userType,
      name: name,
      sum_wishes_nodec: wishesAfterDeath,
      add_steps: additionalLegalDoc
    }
    if (formDataAll.userLeadGuide) {
      updateLeadGuideApi(dataSend, user?.token).then((res: object) => {
        showSuccessMessage("Successfully saved your LEAD Guide form");
        localStorage.removeItem('userResponses');
        setLoading(false);
      }).catch((err) => {
        const errorMessage = err.response?.data?.message || 'Invalid form values';
        showErrorMessage(errorMessage);
        setLoading(false);
      })
    } else
      createLeadGuideApi(dataSend, user?.token).then((res: any) => {
        if (res) {
          setFormDataAll((prevData: any) => ({ ...prevData, userLeadGuide: dataSend }));
          localStorage.removeItem('userResponses');
          showSuccessMessage("Successfully saved your LEAD Guide form");
          setLoading(false);
        }
      }).catch((err) => {
        const errorMessage = err.response?.data?.message || 'Invalid form values';
        showErrorMessage(errorMessage);
        setLoading(false);
      })
  }

  useEffect(() => {
    if (formDataAll?.userLeadGuide) {
      setName(formDataAll?.userLeadGuide?.name);
      setWishesAfterDeath(formDataAll?.userLeadGuide?.sum_wishes_nodec);
      setAdditionalLegalDoc(formDataAll?.userLeadGuide?.add_steps);
      setData(formDataAll?.userLeadGuide);
    }
  }, [formDataAll])

  const downloadPDF = async () => {
    const input = document.getElementById("leadGuideForm2") as HTMLElement;
    const pdf = new jsPDF('p', 'mm', 'a4');

    const sections = document.getElementsByClassName('breaksPage') as HTMLCollectionOf<Element>;  // Adjust the class name accordingly

    if (input) {
      for (let i = 0; i < sections.length; i++) {
        const section = sections[i] as HTMLElement;

        if (i > 0) {
          pdf.addPage(); // Add a new page for each section except the first one
        }

        await html2canvas(section, { scale: 2, scrollY: -window.scrollY }).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // Adjust width and height as needed
        });
      }

      pdf.save('Lead_Guide_Individual.pdf');
    }
  }

  return (
    <Card className='cardLeadGuide' id='leadGuideForm2'>
      <ToastContainer />
      {isShowHeader && <Button title='press me' onClick={() => downloadPDF()} />}
      <Grid style={{ justifySelf: "center", paddingRight: 15, paddingLeft: 0 }}>
        {isShowHeader &&
          <>
            <img className='breaksPage' alt='info icon' src={leadGuideCover} style={{ margin: "auto", display: "block", width: "-webkit-fill-available" }} />
            <Box className='breaksPage' sx={{ marginTop: 30 }}>
              <Box sx={{ height: "15px", width: "100%", background: colors.aqua, marginTop: 10 }}></Box>
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, whiteSpace: "break-spaces", textAlign: "center" }}>© 2019-2023 University of Utah</Typography>
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, whiteSpace: "break-spaces", textAlign: "center" }}>Prepared by:</Typography>
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, whiteSpace: "break-spaces", textAlign: "center" }}>Kara Dassel, PhD</Typography>
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, whiteSpace: "break-spaces", textAlign: "center" }}>Katherine Supiano, PhD, LCSW, FT</Typography>
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, whiteSpace: "break-spaces", textAlign: "center" }}>Rebecca Utz, PhD</Typography>
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, whiteSpace: "break-spaces", textAlign: "center" }}>Eli Lacob, PhD</Typography>
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, whiteSpace: "break-spaces", textAlign: "center" }}>Sara Bybee, PhD, LCSW</Typography>
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, whiteSpace: "break-spaces", textAlign: "center" }}>Nancy Aruscavage, MS</Typography>
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, whiteSpace: "break-spaces", textAlign: "center" }}>Jordana Clayton, BA</Typography>
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, whiteSpace: "break-spaces", textAlign: "center", marginTop: 10 }}>This planning guide is provided “as is” and is not intended as a substitute for care by a licensed medical professional.</Typography>
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, whiteSpace: "break-spaces", textAlign: "center" }}>The University of Utah assumes no liability for any damages from using this planning guide.</Typography>
            </Box>
            <Box className='breaksPage' sx={{ height: "15px", width: "100%", background: colors.aqua, marginTop: 60 }}></Box>
            <Box justifyContent="center" alignItems="center" display="flex" sx={{ height: "150px", width: "150px", background: "#a91927", marginY: 5 }}>
              <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, textTransform: "uppercase", color: "white" }}>Instructions</Typography>
            </Box>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black", fontWeight: "bold" }}>Please note that all words in red are in the glossary.</Typography>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>What is dementia?</Typography>
            <br></br>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>Dementia is a general term for loss of memory, language, problem-solving, and other thinking abilities that are severe enough to interfere with daily life. There are many types of dementia, such as Alzheimer’s disease, vascular dementia, and Lewy body dementia. Alzheimer’s disease is the most common type of dementia. This document uses the term “dementia” to include all types of dementia.</Typography>
            <br></br>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>What is the LEAD Guide?</Typography>
            <br></br>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>The LEAD Guide (Life-Planning in Early Alzheimer’s and Other Dementias) was created to help persons with dementia (or at risk for dementia) think about, document, and share their preferences for their end-of-life care. The LEAD Guide also lets you specify how and with whom you want to make your decisions about your care. The LEAD Guide asks whether your preferences and values may change if you develop dementia or your dementia worsens.</Typography>
            <br></br>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>1. Review</b> what you have done about planning for end-of-life care, such as naming a decision-maker or filling out advance directive documents.</Typography>
            <br></br>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>2. Share your values</b> about how you foresee the end of your life. For example, if you develop <b style={{ color: "#a91927" }}>dementia</b>, do you prefer to live longer or desire a shorter time with a better <b style={{ color: "red" }}>quality of life?</b></Typography>
            <br></br>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>3. Share your preferences</b> for your care; for example, where you wish to live and who will care for you.</Typography>
            <br></br>
            <Box className='breaksPage' sx={{ height: "15px", width: "100%", background: colors.aqua, marginY: 10 }}></Box>
            <Box justifyContent="center" alignItems="center" display="flex" sx={{ height: "150px", width: "150px", background: "#a91927", marginY: 5, padding: 2 }}>
              <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, textTransform: "uppercase", color: "white" }}>HOW DO I USE THE LEAD GUIDE?</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, fontWeight: 'bold' }}>There are many ways to use the LEAD Guide:</Typography>
                <br></br>
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>1. You can use it by yourself.</b> The LEAD Guide will take you through a series of questions about values and preferences associated with what types of care you want or do not want at the <b style={{ color: "#a91927" }}>end of life</b>. <i>Note: Even if you complete the LEAD Guide by yourself, it is a good idea to share it with your family, friends, or healthcare provider (doctor, nurse practitioner, physician assistant, etc.).</i></Typography>
                <br></br>
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>2. Use the LEAD Guide with the person you have named or wish to name as your</b> <b style={{ color: "#a91927" }}>medical power of attorney.</b> Using the guide will help your medical power of attorney understand what care you want to receive if you develop dementia.</Typography>
                <br></br>
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>3. You may also use it for a more general conversation with your family and friends. </b> This way, the people you trust will understand what care you want to receive if you develop dementia. Using the LEAD Guide will help your family and friends have a shared understanding of your preferences and may prevent conflict in the future.</Typography>
                <br></br>
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>4. You may use it to help your healthcare recommend completing both legal documents providers understand your preferences and the LEAD Guide. for your end-of-life care.</b> This information will help them honor your preferences for end- of-life care if you develop dementia.</Typography>
                <br></br>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>5. Regularly revisit the LEAD Guide as your circumstances and preferences may change.</b></Typography>
                <br></br>
                <Box sx={{ height: "10px", width: "100%", background: colors.aqua, marginY: 5 }}></Box>
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>Is the LEAD Guide the same as an advance directive?</b></Typography>
                <br></br>
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>No. An advance directive is a legal document that broadly states what care you want to receive at the end of your life. It also says whom you want to make decisions for you if you cannot do so for any reason. While important, advance directive documents do not cover all the end-of-life decisions that can arise with dementia. We advise everyone to complete advance directive documents in their home state. The advance directive documents are available on your state government's website.</Typography>
                <br></br>
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>Is the LEAD Guide a legally binding document?</b></Typography>
                <br></br>
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>No. The LEAD Guide is an end-of-life planning tool that supplements legal documents such as an advance directive, do-not-resuscitate order, or medical power of attorney. The LEAD Guide does not replace those documents. We recommend completing both legal documents and the LEAD Guide.</Typography>
                <br></br>
              </Grid>
            </Grid>
            <Box className='breaksPage' sx={{ height: "15px", width: "100%", background: colors.aqua, marginY: 10 }}></Box>
          </>
        }

        <Grid container spacing={6}>
          <Grid item xs={3}>
            <Box justifyContent="center" alignItems="center" display="flex" sx={{ height: "150px", width: "90%", background: "#a91927", marginBottom: 5, padding: 2 }}>
              <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, textTransform: "uppercase", color: "white" }}>END-OF-LIFE DOCUMENTS</Typography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize, color: "black" }}><b>The LEAD Guide</b></Typography>
            <br></br>
            <Box sx={{ height: "10px", width: "100%", background: colors.aqua, marginY: 5 }}></Box>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b style={{ color: "#a91927" }}>L</b>ife-Planning in <b style={{ color: "#a91927" }}>E</b>arly <b style={{ color: "#a91927" }}>A</b>lzheimer’s and Other <b style={{ color: "#a91927" }}>D</b>ementias</Typography>
            <br></br>
            <Grid container>
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black", marginRight: 2 }}>Name:</Typography>
              <Input style={{ fontSize: mediumFontSize!! }} value={name} disabled={true} />
            </Grid>
            <br></br>
            <Grid container>
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black", marginRight: 2 }}>Date of Completion:</Typography>
              <Input disabled value={months[currentDate?.getMonth()]} style={{ width: "90px", fontSize: mediumFontSize!! }} />
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black", marginX: 2 }}>/</Typography>
              <Input disabled value={currentDate?.getDate()} style={{ width: "90px", fontSize: mediumFontSize!! }} />
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black", marginX: 2 }}>/</Typography>
              <Input disabled value={currentDate?.getFullYear()} style={{ width: "120px", fontSize: mediumFontSize!! }} />
            </Grid>
            <br></br>
            {user?._doc.userType === 0 && <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black" }}><b><i>Remember that you are responding from the perspective of the patient. “Their” and “them” in the questions refer to the patient.</i></b></Typography>}
            <br></br>
            {<Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black" }}><b><i>Throughout the LEAD Guide, you’ll see <b style={{ color: "#a91927" }}>highlighted terms.</b> If you’re unsure what these terms mean, scroll down to find their definitions in the glossary at the end of the LEAD Guide.</i></b></Typography>}
            <br></br>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: colors.aqua }}><b>END-OF-LIFE DOCUMENTATION</b></Typography>
            <br></br>
            {user?._doc.userType === 1 ?
              <>
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>1. A <b style={{ color: "#a91927" }}>living will</b> or <b style={{ color: "#a91927" }}>advance directive</b> is a legal document that states the kind of medical care you would want if you could not speak for yourself.</b></Typography>
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>Have you completed this documentation?</Typography>
              </>
              :
              <>
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>1. A <b style={{ color: "#a91927" }}>living will</b> or <b style={{ color: "#a91927" }}>advance directive</b> is a legal document that states the kind of medical care they would want if they could not speak for themselves.</b></Typography>
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>Has <i style={{ color: "#112123" }}> {user?._doc.userAssociated[0].name} </i> completed this documentation?</Typography>
              </>
            }
            <RadioGroup
              row
              aria-label="Yes/No"
              name="yesNoGroup"
              value={data?.livingWill}
              onChange={handleChange1}

            >
              <FormControlLabel value={1} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Yes</span>} />
              <FormControlLabel value={0} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >No</span>} />
              {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.livingWill == data?.livingWill ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.livingWill ? formDataAll?.assocUserLeadGuide?.livingWill == data?.livingWill ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
            </RadioGroup>
            <br></br>
            {user?._doc.userType === 1 ?
              <>
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>2. A <b style={{ color: "#a91927" }}>do not resuscitate order</b> (DNR) indicates that you do not want to be revived if your heart stops.</b></Typography>
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>Have you completed this documentation?</Typography>
              </>
              :
              <>
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>2. A <b style={{ color: "#a91927" }}>do not resuscitate order</b> (DNR) indicates that they do not want to be revived if their heart stops.</b></Typography>
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>Has <i style={{ color: "#112123" }}> {user?._doc.userAssociated[0].name} </i> completed this documentation?</Typography>
              </>
            }
            <RadioGroup
              row
              aria-label="Yes/No"
              name="yesNoGroup"
              value={data?.dnr}
              onChange={handleChange2}
            >
              <FormControlLabel value={1} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Yes</span>} />
              <FormControlLabel value={0} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >No</span>} />
              {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.dnr == data?.dnr ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.dnr ? formDataAll?.assocUserLeadGuide?.dnr == data?.dnr ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
            </RadioGroup>
            <br></br>
            {user?._doc.userType === 1 ?
              <>
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>3. A <b style={{ color: "#a91927" }}>medical power of attorney</b> is a legal document that allows you to designate a person to make health care decisions for you if you cannot make your own decisions.</b></Typography>
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>Have you completed the documentation to appoint a medical power of attorney?</Typography>
              </>
              :
              <>
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>3. A <b style={{ color: "#a91927" }}>medical power of attorney</b> is a legal document that allows them to designate a person to make health care decisions for them if they cannot make their own decisions.</b></Typography>
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>Has <i style={{ color: "#112123" }}> {user?._doc.userAssociated[0].name} </i> completed the documentation to appoint a medical power of attorney?</Typography>
              </>
            }
            <RadioGroup
              row
              aria-label="Yes/No"
              name="yesNoGroup"
              value={data?.power}
              onChange={handleChange3}
            >
              <FormControlLabel value={1} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Yes</span>} />
              <FormControlLabel value={0} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >No</span>} />
              {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.power == data?.power ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.power ? formDataAll?.assocUserLeadGuide?.power == data?.power ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
            </RadioGroup>
            <br></br>
            {data?.power === 1 && <Grid>
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>If applicable, who is <i style={{ color: "#112123" }}> {user?._doc.userType === 1 ? "your" : user?._doc.userAssociated[0].name} </i> medical power of attorney?</Typography>
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>(Name and relationship)</Typography>
              <TextArea rows={6} style={{ ...globals.body.mediumText, fontSize: mediumFontSize!! }} placeholder='Type here' value={data?.who_poa} onChange={(event) => {
                setData((prevData) => ({ ...prevData, who_poa: event.target.value }))
                const updatedata = { ...data, who_poa: event.target.value };
                localStorage.setItem('userResponses', JSON.stringify(updatedata));

              }

              } />
            </Grid>}
            <br></br>
          </Grid>
        </Grid>
        <Box className='breaksPage' sx={{ height: "15px", width: "100%", background: colors.aqua, marginY: 10 }}></Box>
        <Box sx={{ display: "flex" }}>
          <Box justifyContent="center" alignItems="center" display="flex" sx={{ height: "150px", width: "350px", background: "#a91927", padding: 2 }}>
            <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, textTransform: "uppercase", color: "white" }}>END-OF-LIFE VALUES</Typography>
          </Box>
          <Box sx={{ marginLeft: 5 }}>
            <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize, color: "black" }}><b>END-OF-LIFE VALUES</b></Typography>
            <br></br>
            {user?._doc.userType === 1 ?
              <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black" }}><b><i>Below are a series of statements regarding your <b style={{ color: "#a91927" }}>attitudes</b> towards end-of-life care. These <b style={{ color: "#a91927" }}>values</b> are unique to you and mostly stay the same. Therefore, they can help guide a caregiver (i.e., a family member or close friend) to make decisions for you.</i></b></Typography>
              :
              <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black" }}><b><i>Below are a series of statements regarding their <b style={{ color: "#a91927" }}>attitudes</b> towards end-of-life care. These <b style={{ color: "#a91927" }}>values</b> are unique to them and mostly stay the same. Therefore, they can help guide a caregiver (i.e., a family member or close friend) to make decisions for them.</i></b></Typography>
            }
            <br></br>
          </Box>
        </Box>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b style={{ color: colors.aqua }}>QUALITY VERSUS LENGTH OF LIFE</b></Typography>
        {user?._doc.userType === 1 ?
          <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black" }}><b><i>Thinking about your end-of-life care, please respond to the following statements:</i></b></Typography>
          :
          <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black" }}><b><i>Thinking about their end-of-life care, please respond to the following statements:</i></b></Typography>
        }
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>4. <b style={{ color: "#a91927" }}>Quality of life</b> is more important than <b style={{ color: "#a91927" }}>length of life.</b></b></Typography>
        {user?._doc.userType === 1 ?
          <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><i>Please rate your level of agreement  (check one box)</i></Typography>
          :
          <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><i>Please rate <i style={{ color: "#112123" }}> {user?._doc.userAssociated[0].name}'s </i> level of agreement  (check one box)</i></Typography>
        }
        <RadioGroup
          row
          aria-label="quality-life"
          name="qualityoflife"
          value={data?.qol_1}
          onChange={handleChange4}
        >
          <FormControlLabel value={1} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Strongly Disagree</span>} />
          <FormControlLabel value={2} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Disagree</span>} />
          <FormControlLabel value={3} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Neither Agree nor Disagree</span>} />
          <FormControlLabel value={4} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Agree</span>} />
          <FormControlLabel value={5} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Strongly Agree</span>} />
          {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.qol_1 == data?.qol_1 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.qol_1 ? formDataAll?.assocUserLeadGuide?.qol_1 == data?.qol_1 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
        </RadioGroup>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>5.	Length of life is more important than quality of life.</b></Typography>
        {user?._doc.userType === 1 ?
          <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><i>Please rate your level of agreement  (check one box)</i></Typography>
          :
          <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><i>Please rate <i style={{ color: "#112123" }}> {user?._doc.userAssociated[0].name}'s </i> level of agreement  (check one box)</i></Typography>
        }
        <RadioGroup
          row
          aria-label="length-life"
          name="lenghtoflife"
          value={data?.qol_2}
          onChange={handleChange5}
        >
          <FormControlLabel value={1} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Strongly Disagree</span>} />
          <FormControlLabel value={2} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Disagree</span>} />
          <FormControlLabel value={3} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Neither Agree nor Disagree</span>} />
          <FormControlLabel value={4} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Agree</span>} />
          <FormControlLabel value={5} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Strongly Agree</span>} />
          {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.qol_2 == data?.qol_2 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.qol_2 ? formDataAll?.assocUserLeadGuide?.qol_2 == data?.qol_2 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
        </RadioGroup>
        <br></br>
        {user?._doc.userType === 1 ?
          <>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>6. Given a choice, I prefer to live a shorter but more satisfying life.</b></Typography>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><i>Please rate your level of agreement  (check one box)</i></Typography>
          </>
          :
          <>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>6. Given a choice, they prefer to live a shorter but more satisfying life.</b></Typography>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><i>Please rate <i style={{ color: "#112123" }}> {user?._doc.userAssociated[0].name}'s </i> level of agreement  (check one box)</i></Typography>
          </>
        }
        <RadioGroup
          row
          aria-label="length-life"
          name="lenghtoflife"
          value={data?.qol_3}
          onChange={handleChange6}
        >
          <FormControlLabel value={1} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Strongly Disagree</span>} />
          <FormControlLabel value={2} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Disagree</span>} />
          <FormControlLabel value={3} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Neither Agree nor Disagree</span>} />
          <FormControlLabel value={4} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Agree</span>} />
          <FormControlLabel value={5} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Strongly Agree</span>} />
          {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.qol_3 == data?.qol_3 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.qol_3 ? formDataAll?.assocUserLeadGuide?.qol_3 == data?.qol_3 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
        </RadioGroup>
        <br></br>
        {user?._doc.userType === 1 ?
          <>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>7. I prefer to live as long as possible, even if that longer life is lower quality.</b></Typography>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><i>Please rate your level of agreement  (check one box)</i></Typography>
          </>
          :
          <>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>7. They prefer to live as long as possible, even if that longer life is lower quality.</b></Typography>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><i>Please rate <i style={{ color: "#112123" }}> {user?._doc.userAssociated[0].name}'s </i> level of agreement  (check one box)</i></Typography>
          </>
        }
        <RadioGroup
          row
          aria-label="length-life"
          name="lenghtoflife"
          value={data?.qol_4}
          onChange={handleChange7}
        >
          <FormControlLabel value={1} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Strongly Disagree</span>} />
          <FormControlLabel value={2} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Disagree</span>} />
          <FormControlLabel value={3} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Neither Agree nor Disagree</span>} />
          <FormControlLabel value={4} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Agree</span>} />
          <FormControlLabel value={5} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Strongly Agree</span>} />
          {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.qol_4 == data?.qol_4 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.qol_4 ? formDataAll?.assocUserLeadGuide?.qol_4 == data?.qol_4 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
        </RadioGroup>
        <br></br>
        {user?._doc.userType === 1 ?
          <>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>What does “quality of life” mean to you?</b></Typography>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>For example, you can list activities and abilities that contribute most to a good quality of life.</Typography>
          </>
          :
          <>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>What does “quality of life” mean to them?</b></Typography>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>For example, you can list activities and abilities that you feel <i style={{ color: "#112123" }}> {user?._doc.userAssociated[0].name} </i> believes contribute the most to their quality of life.</Typography>
          </>
        }
        <TextArea rows={6} style={{ ...globals.body.mediumText, fontSize: mediumFontSize!!, width: "100%" }} value={data?.qol_meanning}
          onChange={(event) => {
            setData((prevData) => ({ ...prevData, qol_meanning: event.target.value }))
            const updatedata = { ...data, qol_meanning: event.target.value };
            localStorage.setItem('userResponses', JSON.stringify(updatedata));
          }} placeholder='Type here' />
        <br></br>
        <Box sx={{ height: "15px", width: "100%", background: colors.aqua, marginY: 10 }}></Box>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b style={{ color: colors.aqua }}>BURDEN</b></Typography>
        {user?._doc.userType === 1 ?
          <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black" }}><b><i>Thinking about your end-of-life care, please respond to the following statements:</i></b></Typography>
          :
          <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black" }}><b><i>Thinking about their end-of-life care, please respond to the following statements:</i></b></Typography>
        }
        <br></br>
        {user?._doc.userType === 1 ?
          <>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>8. I am concerned about being a <b style={{ color: "#a91927" }}>financial burden</b> to family or close friends.</b></Typography>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><i>Please rate your level of agreement  (check one box)</i></Typography>
          </>
          :
          <>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>8. They are concerned about being a <b style={{ color: "#a91927" }}>financial burden</b> to family or close friends.</b></Typography>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><i>Please rate <i style={{ color: "#112123" }}> {user?._doc.userAssociated[0].name}'s </i> level of agreement  (check one box)</i></Typography>
          </>
        }
        <RadioGroup
          row
          aria-label="length-life"
          name="lenghtoflife"
          value={data?.burden_1}
          onChange={handleChange8}
        >
          <FormControlLabel value={1} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Strongly Disagree</span>} />
          <FormControlLabel value={2} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Disagree</span>} />
          <FormControlLabel value={3} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Neither Agree nor Disagree</span>} />
          <FormControlLabel value={4} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Agree</span>} />
          <FormControlLabel value={5} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Strongly Agree</span>} />
          {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.burden_1 == data?.burden_1 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.burden_1 ? formDataAll?.assocUserLeadGuide?.burden_1 == data?.burden_1 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
        </RadioGroup>
        <br></br>
        {user?._doc.userType === 1 ?
          <>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>9. I am concerned about being an <b style={{ color: "#a91927" }}>emotional burden</b> to family or close friends.</b></Typography>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><i>Please rate your level of agreement  (check one box)</i></Typography>
          </>
          :
          <>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>9. They are concerned about being an <b style={{ color: "#a91927" }}>emotional burden</b> to their family or close friends.</b></Typography>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><i>Please rate <i style={{ color: "#112123" }}> {user?._doc.userAssociated[0].name}'s </i> level of agreement  (check one box)</i></Typography>
          </>
        }
        <RadioGroup
          row
          aria-label="length-life"
          name="lenghtoflife"
          value={data?.burden_2}
          onChange={handleChange9}
        >
          <FormControlLabel value={1} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Strongly Disagree</span>} />
          <FormControlLabel value={2} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Disagree</span>} />
          <FormControlLabel value={3} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Neither Agree nor Disagree</span>} />
          <FormControlLabel value={4} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Agree</span>} />
          <FormControlLabel value={5} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Strongly Agree</span>} />
          {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.burden_2 == data?.burden_2 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.burden_2 ? formDataAll?.assocUserLeadGuide?.burden_2 == data?.burden_2 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
        </RadioGroup>
        <br></br>
        {user?._doc.userType === 1 ?
          <>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>10. I am concerned about being a <b style={{ color: "#a91927" }}>physical burden</b> to family or close friends (such as needing assistance with bathing, toileting, and eating).</b></Typography>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><i>Please rate your level of agreement  (check one box)</i></Typography>
          </>
          :
          <>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>10. They are concerned about being a <b style={{ color: "#a91927" }}>physical burden</b> to their family or close friends (such as needing assistance with bathing, toileting, and eating).</b></Typography>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><i>Please rate <i style={{ color: "#112123" }}> {user?._doc.userAssociated[0].name}'s </i> level of agreement  (check one box)</i></Typography>
          </>
        }
        <RadioGroup
          row
          aria-label="length-life"
          name="lenghtoflife"
          value={data?.burden_3}
          onChange={handleChange10}
        >
          <FormControlLabel value={1} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Strongly Disagree</span>} />
          <FormControlLabel value={2} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Disagree</span>} />
          <FormControlLabel value={3} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Neither Agree nor Disagree</span>} />
          <FormControlLabel value={4} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Agree</span>} />
          <FormControlLabel value={5} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Strongly Agree</span>} />
          {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.burden_3 == data?.burden_3 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.burden_3 ? formDataAll?.assocUserLeadGuide?.burden_3 == data?.burden_3 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
        </RadioGroup>
        <Box sx={{ height: "15px", width: "100%", background: colors.aqua, marginY: 10 }}></Box>
        <Grid container spacing={6}>
          <Grid item xs={3}>
            <Box justifyContent="center" alignItems="center" display="flex" sx={{ height: "150px", width: "90%", background: "#a91927", marginBottom: 5, padding: 2 }}>
              <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, textTransform: "uppercase", color: "white" }}><b>Decision </b>Making</Typography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize, color: "black" }}><b>DECISION MAKING</b></Typography>
            <br></br>
            {user?._doc.userType === 1 ?
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b><i>Thinking about your end-of-life care, please respond to the following statements:</i></b></Typography>
              :
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b><i>Thinking about their end-of-life care, please respond to the following statements:</i></b></Typography>
            }
            <br></br>
            {user?._doc.userType === 1 ?
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>11. I want to have conversations about my end-of-life care options with </b><i>(check all that apply)</i></Typography>
              :
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>11. They want to have conversations about their end-of-life care options with </b><i>(who would <i style={{ color: "#112123" }}> {user?._doc.userAssociated[0].name} </i> choose? Check all that apply)</i></Typography>
            }
            <FormGroup>
              <FormControlLabel

                control={
                  <>
                    <Checkbox checked={data?.convo_1 === 1 ? true : false} onChange={(event) => handleChange11('convo_1', event)} />
                    <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>{user?._doc.userType === 1 ? "my" : "their"} spouse/partner</Typography>
                    {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.convo_1 == data?.convo_1 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.convo_1 ? formDataAll?.assocUserLeadGuide?.convo_1 == data?.convo_1 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
                  </>}
                label=""
              />
              <FormControlLabel

                control={
                  <>
                    <Checkbox checked={data?.convo_2 === 1 ? true : false} onChange={(event) => handleChange11('convo_2', event)} />
                    <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>{user?._doc.userType === 1 ? "my" : "their"} child(ren)</Typography>
                    {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.convo_2 == data?.convo_2 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.convo_2 ? formDataAll?.assocUserLeadGuide?.convo_2 == data?.convo_2 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
                  </>
                }
                label=""
              />
              <FormControlLabel

                control={
                  <>
                    <Checkbox checked={data?.convo_3 === 1 ? true : false} onChange={(event) => handleChange11('convo_3', event)} />
                    <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>{user?._doc.userType === 1 ? "my" : "their"} healthcare provider</Typography>
                    {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.convo_3 == data?.convo_3 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.convo_3 ? formDataAll?.assocUserLeadGuide?.convo_3 == data?.convo_3 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
                  </>
                }
                label=""
              />
              <Grid container>
                <FormControlLabel

                  control={
                    <>
                      <Checkbox checked={data?.convo_4 === 1 ? true : false} onChange={(event) => handleChange11('convo_4', event)} />
                      <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize }}>other</Typography>
                    </>
                  }
                  label=""
                />
                {data?.convo_4 === 1 && <><p style={{ fontSize: mediumFontSize!! }}>:</p><Input style={{ fontSize: mediumFontSize!!, marginRight: "10px" }} value={data?.convo_other}
                  onChange={(event) => {
                    setData((prevData) => ({ ...prevData, convo_other: event.target.value }))
                    const updatedata = { ...data, convo_other: event.target.value };
                    localStorage.setItem('userResponses', JSON.stringify(updatedata));
                  }
                  } /></>}
                {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.convo_4 == data?.convo_4 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.convo_4 ? formDataAll?.assocUserLeadGuide?.convo_4 == data?.convo_4 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
              </Grid>
              <FormControlLabel

                control={
                  <>
                    <Checkbox checked={data?.convo_5 === 1 ? true : false} onChange={(event) => handleChange11('convo_5', event)} />
                    <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>uncertain at this time</Typography>
                    {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.convo_5 == data?.convo_5 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.convo_5 ? formDataAll?.assocUserLeadGuide?.convo_5 == data?.convo_5 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
                  </>
                }
                label=""
              />

            </FormGroup>
            <br></br>
            {user?._doc.userType === 1 ?
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>List the name(s) of persons you’d like involved in these conversations:</Typography>
              :
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>List the name(s) of persons you think they would like involved in these conversations:</Typography>
            }
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><i>(name and relationship)</i></Typography>
            <TextArea rows={6} style={{ ...globals.body.mediumText, fontSize: mediumFontSize!! }} value={data?.convo_names}
              onChange={(event) => {
                setData((prevData) => ({ ...prevData, convo_names: event.target.value }))
                const updatedata = { ...data, convo_names: event.target.value };
                localStorage.setItem('userResponses', JSON.stringify(updatedata));

              }} placeholder='Type here' />
            <br></br>
            <br></br>
            {user?._doc.userType === 1 ?
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>12. When I cannot express my care preferences, I want the primary decision-maker regarding my end-of-life care to be</b></Typography>
              :
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>12. When they cannot express their care preferences, they want the primary decision-maker regarding their end-of-life care to be</b><i>(who would <i style={{ color: "#112123" }}> {user?._doc.userAssociated[0].name} </i> choose?)</i></Typography>
            }
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><i>(check one)</i></Typography>
            <RadioGroup

              aria-label="care-pref"
              name="carepref"
              value={data?.dec_maker}
              onChange={handleChange12}
            >
              <FormControlLabel value={1} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >{`${user?._doc.userType === 1 ? "my" : "their"} spouse/partner`}</span>} />
              <FormControlLabel value={2} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >{`${user?._doc.userType === 1 ? "my" : "their"}  child(ren)`}</span>} />
              <Grid>
                <FormControlLabel value={3} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >other:</span>} />
                {data?.dec_maker === 3 && <Input value={data?.dec_maker_other} style={{ fontSize: mediumFontSize!! }}
                  onChange={(event) => {
                    setData((prevData) => ({ ...prevData, dec_maker_other: event.target.value }))
                    const updatedata = { ...data, dec_maker_other: event.target.value };
                    localStorage.setItem('userResponses', JSON.stringify(updatedata));
                  }} />}
              </Grid>
              {/* <FormControlLabel  value={4} control={<Radio />} label={<span style={{fontSize: mediumFontSize+"px"}} >Agree"</span>} /> */}
              <FormControlLabel value={4} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Uncertain at this time</span>} />
            </RadioGroup>
            {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, width: "fit-content", background: formDataAll?.assocUserLeadGuide?.dec_maker == data?.dec_maker ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.dec_maker ? formDataAll?.assocUserLeadGuide?.dec_maker == data?.dec_maker ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
            <br></br>
            {user?._doc.userType === 1 ?
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>List the name(s) of persons you’d like involved in these conversations:</Typography>
              :
              <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>List the name(s) of persons you think they would like involved in these conversations:</Typography>
            }
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><i>(name and relationship)</i></Typography>
            <TextArea rows={6} style={{ ...globals.body.mediumText, fontSize: mediumFontSize!! }} placeholder='Type here' value={data?.dec_maker_name} onChange={(event) => {
              setData((prevData) => ({ ...prevData, dec_maker_name: event.target.value }))
              const updatedata = { ...data, dec_maker_name: event.target.value };
              localStorage.setItem('userResponses', JSON.stringify(updatedata));
            }} />
            <br></br>
          </Grid>
        </Grid>
        <Box sx={{ height: "15px", width: "100%", background: colors.aqua, marginY: 10 }}></Box>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><i><b>Now, think about specific types of decisions made during the end of life and respond to the following statements:</b></i></Typography>
        <br></br>
        <br></br>
        {user?._doc.userType === 1 ?
          <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>13. I want decisions related to the <b style={{ color: "#a91927" }}>location of my ongoing care</b> to be made by:</b></Typography>
          :
          <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>13. They want decisions related to the <b style={{ color: "#a91927" }}>location of their ongoing care</b> to be made by:</b></Typography>
        }
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><i>(check all that apply)</i></Typography>
        <FormGroup>
          <FormControlLabel

            control={
              <>
                <Checkbox checked={data?.loc_care_1 === 1 ? true : false} onChange={(event) => handleChange13('loc_care_1', event)} />
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>{user?._doc.userType === 1 ? "myself" : "themselves"}, if able</Typography>
                {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.loc_care_1 == data?.loc_care_1 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.loc_care_1 ? formDataAll?.assocUserLeadGuide?.loc_care_1 == data?.loc_care_1 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
              </>
            }
            label=""
          />
          <FormControlLabel

            control={
              <>
                <Checkbox checked={data?.loc_care_2 === 1 ? true : false} onChange={(event) => handleChange13('loc_care_2', event)} />
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>spouse/partner</Typography>
                {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.loc_care_2 == data?.loc_care_2 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.loc_care_2 ? formDataAll?.assocUserLeadGuide?.loc_care_2 == data?.loc_care_2 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
              </>
            }
            label=""
          />
          <FormControlLabel

            control={
              <>
                <Checkbox checked={data?.loc_care_3 === 1 ? true : false} onChange={(event) => handleChange13('loc_care_3', event)} />
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>child(ren)</Typography>
                {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.loc_care_3 == data?.loc_care_3 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.loc_care_3 ? formDataAll?.assocUserLeadGuide?.loc_care_3 == data?.loc_care_3 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
              </>
            }
            label=""
          />
          <Grid container>
            <FormControlLabel

              control={
                <>
                  <Checkbox checked={data?.loc_care_4 === 1 ? true : false} onChange={(event) => handleChange13('loc_care_4', event)} />
                  <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize }}>other</Typography>
                </>
              }
              label=""
            />
            {data?.loc_care_4 === 1 && <><p style={{ fontSize: mediumFontSize!! }}>:</p><Input style={{ fontSize: mediumFontSize!! }} value={data?.loc_care_other}
              onChange={(event) => {
                setData((prevData) => ({ ...prevData, loc_care_other: event.target.value }))
                const updatedata = { ...data, loc_care_other: event.target.value };
                localStorage.setItem('userResponses', JSON.stringify(updatedata));

              }} /></>}
            {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.loc_care_4 == data?.loc_care_4 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.loc_care_4 ? formDataAll?.assocUserLeadGuide?.loc_care_4 == data?.loc_care_4 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
          </Grid>
          <FormControlLabel

            control={
              <>
                <Checkbox checked={data?.loc_care_5 === 1 ? true : false} onChange={(event) => handleChange13('loc_care_5', event)} />
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>uncertain at this time</Typography>
                {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.loc_care_5 == data?.loc_care_5 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.loc_care_5 ? formDataAll?.assocUserLeadGuide?.loc_care_5 == data?.loc_care_5 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
              </>
            }
            label=""
          />

        </FormGroup>
        <br></br>
        {user?._doc.userType === 1 ?
          <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>14. I want decisions related to the <b style={{ color: "#a91927" }}>location of my death</b> to be made by:</b></Typography>
          :
          <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>14. They want decisions related to the <b style={{ color: "#a91927" }}>location of their death</b> to be made by:</b></Typography>
        }
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><i>(check all that apply)</i></Typography>
        <FormGroup>
          <FormControlLabel

            control={
              <>
                <Checkbox checked={data?.loc_death_1 === 1 ? true : false} onChange={(event) => handleChange14('loc_death_1', event)} />
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>{user?._doc.userType === 1 ? "myself" : "themselves"}, if able</Typography>
                {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.loc_death_1 == data?.loc_death_1 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.loc_death_1 ? formDataAll?.assocUserLeadGuide?.loc_death_1 == data?.loc_death_1 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
              </>
            }
            label=""
          />
          <FormControlLabel

            control={
              <>
                <Checkbox checked={data?.loc_death_2 === 1 ? true : false} onChange={(event) => handleChange14('loc_death_2', event)} />
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>spouse/partner</Typography>
                {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.loc_death_2 == data?.loc_death_2 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.loc_death_2 ? formDataAll?.assocUserLeadGuide?.loc_death_2 == data?.loc_death_2 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
              </>
            }
            label=""
          />
          <FormControlLabel

            control={
              <>
                <Checkbox checked={data?.loc_death_3 === 1 ? true : false} onChange={(event) => handleChange14('loc_death_3', event)} />
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>child(ren)</Typography>
                {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.loc_death_3 == data?.loc_death_3 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.loc_death_3 ? formDataAll?.assocUserLeadGuide?.loc_death_3 == data?.loc_death_3 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
              </>
            }
            label=""
          />
          <Grid container>
            <FormControlLabel

              control={<Checkbox checked={data?.loc_death_4 === 1 ? true : false} onChange={(event) => handleChange14('loc_death_4', event)} />}
              label={<span style={{ fontSize: mediumFontSize + "px" }} >other:</span>}
            />
            {data?.loc_death_4 === 1 && <><p style={{ fontSize: mediumFontSize!! }}>:</p><Input style={{ fontSize: mediumFontSize!! }} value={data?.loc_death_other}
              onChange={(event) => {
                setData((prevData) => ({ ...prevData, loc_death_other: event.target.value }))
                const updatedata = { ...data, loc_death_other: event.target.value };
                localStorage.setItem('userResponses', JSON.stringify(updatedata));
              }} /></>}
            {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.loc_death_4 == data?.loc_death_4 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.loc_death_4 ? formDataAll?.assocUserLeadGuide?.loc_death_4 == data?.loc_death_4 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
          </Grid>
          <FormControlLabel

            control={
              <>
                <Checkbox checked={data?.loc_death_5 === 1 ? true : false} onChange={(event) => handleChange14('loc_death_5', event)} />
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>uncertain at this time</Typography>
                {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.loc_death_5 == data?.loc_death_5 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.loc_death_5 ? formDataAll?.assocUserLeadGuide?.loc_death_5 == data?.loc_death_5 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
              </>
            }
            label=""
          />

        </FormGroup>
        <br></br>
        {user?._doc.userType === 1 ?
          <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>15. I want decisions related to my <b style={{ color: "#a91927" }}>life-prolonging measures</b> to be made by:</b></Typography>
          :
          <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>15. They would want decisions related to their <b style={{ color: "#a91927" }}>life-prolonging measures</b> to be made by:</b></Typography>
        }<Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><i>(check all that apply)</i></Typography>
        <FormGroup>
          <FormControlLabel

            control={
              <>
                <Checkbox checked={data?.life_prolong_1 === 1 ? true : false} onChange={(event) => handleChange15('life_prolong_1', event)} />
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>{user?._doc.userType === 1 ? "myself" : "themselves"}, if able</Typography>
                {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.life_prolong_1 == data?.life_prolong_1 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.life_prolong_1 ? formDataAll?.assocUserLeadGuide?.life_prolong_1 == data?.life_prolong_1 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
              </>
            }
            label=""
          />
          <FormControlLabel

            control={
              <>
                <Checkbox checked={data?.life_prolong_2 === 1 ? true : false} onChange={(event) => handleChange15('life_prolong_2', event)} />
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>spouse/partner</Typography>
                {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.life_prolong_2 == data?.life_prolong_2 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.life_prolong_2 ? formDataAll?.assocUserLeadGuide?.life_prolong_2 == data?.life_prolong_2 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
              </>
            }
            label=""
          />
          <FormControlLabel

            control={
              <>
                <Checkbox checked={data?.life_prolong_3 === 1 ? true : false} onChange={(event) => handleChange15('life_prolong_3', event)} />
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>child(ren)</Typography>
                {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.life_prolong_3 == data?.life_prolong_3 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.life_prolong_3 ? formDataAll?.assocUserLeadGuide?.life_prolong_3 == data?.life_prolong_3 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
              </>
            }
            label=""
          />
          <Grid container>
            <FormControlLabel

              control={<Checkbox checked={data?.life_prolong_4 === 1 ? true : false} onChange={(event) => handleChange15('life_prolong_4', event)} />}
              label={<span style={{ fontSize: mediumFontSize + "px" }} >other</span>}
            />
            {data?.life_prolong_4 === 1 && <><p style={{ fontSize: mediumFontSize!! }}>:</p><Input style={{ fontSize: mediumFontSize!! }} value={data?.life_prolong_other}
              onChange={(event) => {
                setData((prevData) => ({ ...prevData, life_prolong_other: event.target.value }))
                const updatedata = { ...data, life_prolong_other: event.target.value };
                localStorage.setItem('userResponses', JSON.stringify(updatedata));
              }
              } /></>}
            {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.life_prolong_4 == data?.life_prolong_4 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.life_prolong_4 ? formDataAll?.assocUserLeadGuide?.life_prolong_4 == data?.life_prolong_4 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
          </Grid>
          <FormControlLabel

            control={
              <>
                <Checkbox checked={data?.life_prolong_5 === 1 ? true : false} onChange={(event) => handleChange15('life_prolong_5', event)} />
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>uncertain at this time</Typography>
                {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.life_prolong_5 == data?.life_prolong_5 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.life_prolong_5 ? formDataAll?.assocUserLeadGuide?.life_prolong_5 == data?.life_prolong_5 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
              </>
            }
            label=""
          />
        </FormGroup>
        <br></br>
        {user?._doc.userType === 1 ?
          <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>16. I want decisions related to <b style={{ color: "#a91927" }}>controlling when I die</b> to be made by:</b></Typography>
          :
          <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>16. They would want decisions related to <b style={{ color: "#a91927" }}>controlling when they die</b> to be made by:</b></Typography>
        }
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><i>(check all that apply)</i></Typography>
        <FormGroup>
          <FormControlLabel

            control={
              <>
                <Checkbox checked={data?.death_when_1 === 1 ? true : false} onChange={(event) => handleChange16('death_when_1', event)} />
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>{user?._doc.userType === 1 ? "myself" : "themselves"}, if able</Typography>
                {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.death_when_1 == data?.death_when_1 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.death_when_1 ? formDataAll?.assocUserLeadGuide?.death_when_1 == data?.death_when_1 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
              </>
            }
            label=""
          />
          <FormControlLabel

            control={
              <>
                <Checkbox checked={data?.death_when_2 === 1 ? true : false} onChange={(event) => handleChange16('death_when_2', event)} />
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>spouse/partner</Typography>
                {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.death_when_2 == data?.death_when_2 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.death_when_2 ? formDataAll?.assocUserLeadGuide?.death_when_2 == data?.death_when_2 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
              </>
            }
            label=""
          />
          <FormControlLabel

            control={
              <>
                <Checkbox checked={data?.death_when_3 === 1 ? true : false} onChange={(event) => handleChange16('death_when_3', event)} />
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>child(ren)</Typography>
                {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.death_when_3 == data?.death_when_3 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.death_when_3 ? formDataAll?.assocUserLeadGuide?.death_when_3 == data?.death_when_3 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
              </>
            }
            label=""
          />
          <Grid container>
            <FormControlLabel

              control={<Checkbox checked={data?.death_when_4 === 1 ? true : false} onChange={(event) => handleChange16('death_when_4', event)} />}
              label={<span style={{ fontSize: mediumFontSize + "px" }} >other</span>}
            />
            {data?.death_when_4 === 1 && <><p style={{ fontSize: mediumFontSize!! }}>:</p><Input style={{ fontSize: mediumFontSize!! }} value={data?.death_when_other} onChange={(event) => {
              setData((prevData) => ({ ...prevData, death_when_other: event.target.value }))
              const updatedata = { ...data, death_when_other: event.target.value };
              localStorage.setItem('userResponses', JSON.stringify(updatedata));

            }} /></>}
            {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.death_when_4 == data?.death_when_4 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.death_when_4 ? formDataAll?.assocUserLeadGuide?.death_when_4 == data?.death_when_4 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
          </Grid>
          <FormControlLabel

            control={
              <>
                <Checkbox checked={data?.death_when_5 === 1 ? true : false} onChange={(event) => handleChange16('death_when_5', event)} />
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>uncertain at this time</Typography>
                {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.death_when_5 == data?.death_when_5 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.death_when_5 ? formDataAll?.assocUserLeadGuide?.death_when_5 == data?.death_when_5 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
              </>
            }
            label=""
          />
        </FormGroup>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>Additional Information</b></Typography>
        {user?._doc.userType === 1 ?
          <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>Is there anything else you want your family or healthcare providers to know about your end-of-life values?</Typography>
          :
          <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>Is there anything else you think they would want their family or healthcare providers to know about their end-of-life values?</Typography>
        }
        <TextArea rows={6} value={data?.decision_text} onChange={(event) => {
          setData((prevData) => ({ ...prevData, decision_text: event.target.value }))
          const updatedata = { ...data, decision_text: event.target.value };
          localStorage.setItem('userResponses', JSON.stringify(updatedata));
        }} style={{ width: "100%", fontSize: mediumFontSize!! }} placeholder='Type here' />
        <Box sx={{ height: "15px", width: "100%", background: colors.aqua, marginY: 10 }}></Box>
        <Box sx={{ display: "flex" }}>
          <Box justifyContent="center" alignItems="center" display="flex" sx={{ width: "350px", background: "#a91927", padding: 2 }}>
            <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, textTransform: "uppercase", color: "white" }}>END-OF-LIFE PREFERENCES</Typography>
          </Box>
          <Box sx={{ marginLeft: 5 }}>
            <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize, color: "black" }}><b>END-OF-LIFE PREFERENCES</b></Typography>
            <br></br>
            <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black" }}><b><i>Below are a series of statements regarding {user?._doc.userType === 1 ? "your" : "their"} <b style={{ color: "#a91927" }}>preferences</b> towards end-of-life care. These preferences may change based on {user?._doc.userType === 1 ? "your" : "their"} circumstances. {user?._doc.userType === 1 ? "You" : "They"} are to complete the following information based on two situations.</i></b></Typography>
            <br></br>
          </Box>
        </Box>
        <br></br>
        <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize, color: "black" }}><b style={{ color: colors.aqua }}>SITUATION 1:</b></Typography>
        <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black" }}><b><i>What are {user?._doc.userType === 1 ? "your" : "their"} end-of-life care preferences today, when {user?._doc.userType === 1 ? "you" : "they"} can communicate {user?._doc.userType === 1 ? "your" : "their"} choices and make decisions?</i></b></Typography>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b style={{ color: colors.aqua }}>LOCATION OF ONGOING CARE</b></Typography>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>17. If {user?._doc.userType === 1 ? "you" : "they"} were to require 24-hour care and supervision for medical needs today, where is {user?._doc.userType === 1 ? "your" : "their"} preferred location to receive care?</b></Typography>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><i>(check one)</i></Typography>
        <RadioGroup

          aria-label="care-pref-loc"
          name="careprefloc"
          value={data?.location_24hr}
          onChange={handleChange17}
        >
          <FormControlLabel value={1} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >{`In ${user?._doc.userType === 1 ? "my" : "their"} home`}</span>} />
          <Grid>
            <FormControlLabel value={2} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >In someone else’s home (specify):</span>} />
            {data?.location_24hr === 2 && <Input style={{ fontSize: mediumFontSize!! }} value={data?.location_24hr_other} onChange={(event) => {
              setData((prevData) => ({ ...prevData, location_24hr_other: event.target.value }))
              const updatedata = { ...data, location_24hr_other: event.target.value };
              localStorage.setItem('userResponses', JSON.stringify(updatedata));
            }} />}
          </Grid>
          <FormControlLabel value={3} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >In a residential hospice center, if available</span>} />
          <FormControlLabel value={4} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >In a nursing home</span>} />
          <FormControlLabel value={5} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >In a hospital</span>} />
          <FormControlLabel value={6} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Uncertain at this time</span>} />
        </RadioGroup>
        {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, width: "fit-content", background: formDataAll?.assocUserLeadGuide?.location_24hr == data?.location_24hr ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.location_24hr ? formDataAll?.assocUserLeadGuide?.location_24hr == data?.location_24hr ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b style={{ color: colors.aqua }}>LIFE PROLONGING MEASURES</b></Typography>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>18. What are {user?._doc.userType === 1 ? "your" : "their"} preferences if {user?._doc.userType === 1 ? "you" : "they"} were to require life-prolonging measures today?</b></Typography>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>{user?._doc.userType === 1 ? "I" : "They"} would want to live as long as possible, even if {user?._doc.userType === 1 ? "I" : "they"} had to be on life support or a breathing machine.</Typography>
        <RadioGroup
          row
          aria-label="care-pref-loc"
          name="careprefloc"
          value={data?.lifesup}
          onChange={(event) => {
            setData((prevData) => ({ ...prevData, lifesup: parseInt(event.target.value) }));
            const updatedata = { ...data, lifesup: parseInt(event.target.value) };
            localStorage.setItem('userResponses', JSON.stringify(updatedata));
          }}
        >
          <FormControlLabel value={1} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Yes</span>} />
          <FormControlLabel value={0} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >No</span>} />
          <FormControlLabel value={2} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Uncertain at this time</span>} />
          {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, width: "fit-content", background: formDataAll?.assocUserLeadGuide?.lifesup == data?.lifesup ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.lifesup ? formDataAll?.assocUserLeadGuide?.lifesup == data?.lifesup ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
        </RadioGroup>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>{user?._doc.userType === 1 ? "I" : "They"} would want to live as long as possible, even if {user?._doc.userType === 1 ? "my" : "their"} brain stops working.</Typography>
        <RadioGroup
          row
          aria-label="care-pref-loc"
          name="careprefloc"
          value={data?.brain}
          onChange={(event) => {
            setData((prevData) => ({ ...prevData, brain: parseInt(event.target.value) }));
            const updatedata = { ...data, brain: parseInt(event.target.value) };
            localStorage.setItem('userResponses', JSON.stringify(updatedata));
          }}
        >
          <FormControlLabel value={1} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Yes</span>} />
          <FormControlLabel value={0} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >No</span>} />
          <FormControlLabel value={2} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Uncertain at this time</span>} />
          {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, width: "fit-content", background: formDataAll?.assocUserLeadGuide?.brain == data?.brain ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.brain ? formDataAll?.assocUserLeadGuide?.brain == data?.brain ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
        </RadioGroup>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>{user?._doc.userType === 1 ? "I" : "They"} would want to live as long as possible, even if fed through a tube.</Typography>
        <RadioGroup
          row
          aria-label="care-pref-loc"
          name="careprefloc"
          value={data?.tube}
          onChange={(event) => {
            setData((prevData) => ({ ...prevData, tube: parseInt(event.target.value) }));
            const updatedata = { ...data, tube: parseInt(event.target.value) };
            localStorage.setItem('userResponses', JSON.stringify(updatedata));
          }}
        >
          <FormControlLabel value={1} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Yes</span>} />
          <FormControlLabel value={0} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >No</span>} />
          <FormControlLabel value={2} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Uncertain at this time</span>} />
          {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, width: "fit-content", background: formDataAll?.assocUserLeadGuide?.tube == data?.tube ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.tube ? formDataAll?.assocUserLeadGuide?.tube == data?.tube ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
        </RadioGroup>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>{user?._doc.userType === 1 ? "I" : "They"} would want to live as long as possible, even in severe pain.</Typography>
        <RadioGroup
          row
          aria-label="care-pref-loc"
          name="careprefloc"
          value={data?.pain}
          onChange={(event) => {
            setData((prevData) => ({ ...prevData, pain: parseInt(event.target.value) }));
            const updatedata = { ...data, pain: parseInt(event.target.value) };
            localStorage.setItem('userResponses', JSON.stringify(updatedata));
          }}
        >
          <FormControlLabel value={1} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Yes</span>} />
          <FormControlLabel value={0} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >No</span>} />
          <FormControlLabel value={2} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Uncertain at this time</span>} />
          {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, width: "fit-content", background: formDataAll?.assocUserLeadGuide?.pain == data?.pain ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.pain ? formDataAll?.assocUserLeadGuide?.pain == data?.pain ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
        </RadioGroup>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b style={{ color: colors.aqua }}>CONTROLLING WHEN YOU DIE</b></Typography>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>19. What are {user?._doc.userType === 1 ? "your" : "their"} preferences today regarding controlling when {user?._doc.userType === 1 ? "you" : "they"} die?</b></Typography>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>{user?._doc.userType === 1 ? "I" : "They"} would consider ending {user?._doc.userType === 1 ? "my" : "their"} own life by not eating or drinking.</Typography>
        <RadioGroup
          row
          aria-label="care-pref-loc"
          name="careprefloc"
          value={data?.con_food}
          onChange={(event) => {
            setData((prevData) => ({ ...prevData, con_food: parseInt(event.target.value) }));
            const updatedata = { ...data, con_food: parseInt(event.target.value) };
            localStorage.setItem('userResponses', JSON.stringify(updatedata));
          }}
        >
          <FormControlLabel value={1} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Yes</span>} />
          <FormControlLabel value={0} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >No</span>} />
          <FormControlLabel value={2} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Uncertain at this time</span>} />
          {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, width: "fit-content", background: formDataAll?.assocUserLeadGuide?.con_food == data?.con_food ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.con_food ? formDataAll?.assocUserLeadGuide?.con_food == data?.con_food ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
        </RadioGroup>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>{user?._doc.userType === 1 ? "I" : "They"} would consider independently ending {user?._doc.userType === 1 ? "my" : "their"} own life through self-directed means.</Typography>
        <RadioGroup
          row
          aria-label="care-pref-loc"
          name="careprefloc"
          value={data?.con_self}
          onChange={(event) => {
            setData((prevData) => ({ ...prevData, con_self: parseInt(event.target.value) }));
            const updatedata = { ...data, con_self: parseInt(event.target.value) };
            localStorage.setItem('userResponses', JSON.stringify(updatedata));
          }}
        >
          <FormControlLabel value={1} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Yes</span>} />
          <FormControlLabel value={0} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >No</span>} />
          <FormControlLabel value={2} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Uncertain at this time</span>} />
          {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, width: "fit-content", background: formDataAll?.assocUserLeadGuide?.con_self == data?.con_self ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.con_self ? formDataAll?.assocUserLeadGuide?.con_self == data?.con_self ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
        </RadioGroup>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>{user?._doc.userType === 1 ? "I" : "They"} would consider taking a prescription medication to end {user?._doc.userType === 1 ? "my" : "their"} life under the supervision of a physician (if legal in {user?._doc.userType === 1 ? "my" : "their"} state and if {user?._doc.userType === 1 ? "I" : "they"} were deemed competent).</Typography>
        <RadioGroup
          row
          aria-label="care-pref-loc"
          name="careprefloc"
          value={data?.con_meds}
          onChange={(event) => {
            setData((prevData) => ({ ...prevData, con_meds: parseInt(event.target.value) }));
            const updatedata = { ...data, con_meds: parseInt(event.target.value) };
            localStorage.setItem('userResponses', JSON.stringify(updatedata));
          }}
        >
          <FormControlLabel value={1} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Yes</span>} />
          <FormControlLabel value={0} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >No</span>} />
          <FormControlLabel value={2} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Uncertain at this time</span>} />
          {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, width: "fit-content", background: formDataAll?.assocUserLeadGuide?.con_meds == data?.con_meds ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.con_meds ? formDataAll?.assocUserLeadGuide?.con_meds == data?.con_meds ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
        </RadioGroup>
        <br></br>
        <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize, color: "black" }}><b style={{ color: colors.aqua }}>SITUATION 2:</b></Typography>
        <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black" }}><b><i>In the later stages of dementia, the ability to understand, make decisions, and communicate is impaired. {user?._doc.userType === 1 ? "You" : <i style={{ color: "#112123" }}> {user?._doc.userAssociated[0].name} </i>} will need someone else to make decisions for {user?._doc.userType === 1 ? "you" : "them"} in this situation.</i></b></Typography>
        <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black" }}><b><i>What end-of-life care decisions {user?._doc.userType === 1 ? "would you" : "do you think they would"} like someone else to make for {user?._doc.userType === 1 ? "you" : "them"} when {user?._doc.userType === 1 ? "you" : "they"} are in the later stages of dementia and cannot express {user?._doc.userType === 1 ? "your" : "their"} care preferences?</i></b></Typography>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b style={{ color: colors.aqua }}>LOCATION OF ONGOING CARE</b></Typography>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>20. In the later stages of dementia, where is {user?._doc.userType === 1 ? "your" : "their"} preferred location to receive care if {user?._doc.userType === 1 ? "you" : "they"} require 24-hour care and supervision for medical needs?</b><i>(check one)</i></Typography>
        <RadioGroup

          aria-label="care-pref-loc"
          name="careprefloc"
          value={data?.location_nodec}
          onChange={handleChange20}
        >
          <FormControlLabel value={1} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >{`In ${user?._doc.userType === 1 ? "my" : "their"} home`}</span>} />
          <Grid>
            <FormControlLabel value={2} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >In someone else’s home (specify):</span>} />
            {data?.location_nodec === 2 && <Input style={{ fontSize: mediumFontSize!! }} value={data?.location_nodec_other}
              onChange={(event) => {
                setData((prevData) => ({ ...prevData, location_nodec_other: event.target.value }))
                const updatedata = { ...data, location_nodec_other: event.target.value };
                localStorage.setItem('userResponses', JSON.stringify(updatedata));
              }} />}
          </Grid>
          <FormControlLabel value={3} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >In a residential hospice center, if available</span>} />
          <FormControlLabel value={4} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >In a nursing home</span>} />
          <FormControlLabel value={5} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >In a hospital</span>} />
          <FormControlLabel value={6} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Uncertain at this time</span>} />
        </RadioGroup>
        {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, width: "fit-content", background: formDataAll?.assocUserLeadGuide?.location_nodec == data?.location_nodec ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.location_nodec ? formDataAll?.assocUserLeadGuide?.location_nodec == data?.location_nodec ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
        <Box sx={{ height: "15px", width: "100%", background: colors.aqua, marginY: 5 }}></Box>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b style={{ color: colors.aqua }}>LOCATION OF ONGOING CARE</b></Typography>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>21. In the later stages of dementia, what are {user?._doc.userType === 1 ? "your" : "their"} preferences regarding life-prolonging measures?</b></Typography>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>{user?._doc.userType === 1 ? "I" : "They"} would want to live as long as possible, even if {user?._doc.userType === 1 ? "I" : "they"} had to be on life support or a breathing machine.</Typography>
        <RadioGroup
          row
          aria-label="care-pref-loc"
          name="careprefloc"
          value={data?.lifesup_nodec}
          onChange={(event) => {
            setData((prevData) => ({ ...prevData, lifesup_nodec: parseInt(event.target.value) }));
            const updatedata = { ...data, lifesup_nodec: parseInt(event.target.value) };
            localStorage.setItem('userResponses', JSON.stringify(updatedata));
          }}
        >
          <FormControlLabel value={1} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Yes</span>} />
          <FormControlLabel value={0} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >No</span>} />
          <FormControlLabel value={2} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Uncertain at this time</span>} />
          {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, width: "fit-content", background: formDataAll?.assocUserLeadGuide?.lifesup_nodec == data?.lifesup_nodec ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.lifesup_nodec ? formDataAll?.assocUserLeadGuide?.lifesup_nodec == data?.lifesup_nodec ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
        </RadioGroup>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>{user?._doc.userType === 1 ? "I" : "They"} would want to live as long as possible, even if {user?._doc.userType === 1 ? "my" : "their"} brain stops working.</Typography>
        <RadioGroup
          row
          aria-label="care-pref-loc"
          name="careprefloc"
          value={data?.brain_nodec}
          onChange={(event) => {
            setData((prevData) => ({ ...prevData, brain_nodec: parseInt(event.target.value) }));
            const updatedata = { ...data, brain_nodec: parseInt(event.target.value) };
            localStorage.setItem('userResponses', JSON.stringify(updatedata));
          }}
        >
          <FormControlLabel value={1} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Yes</span>} />
          <FormControlLabel value={0} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >No</span>} />
          <FormControlLabel value={2} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Uncertain at this time</span>} />
          {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, width: "fit-content", background: formDataAll?.assocUserLeadGuide?.brain_nodec == data?.brain_nodec ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.brain_nodec ? formDataAll?.assocUserLeadGuide?.brain_nodec == data?.brain_nodec ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
        </RadioGroup>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>{user?._doc.userType === 1 ? "I" : "They"} would want to live as long as possible, even if fed through a tube.</Typography>
        <RadioGroup
          row
          aria-label="care-pref-loc"
          name="careprefloc"
          value={data?.tube_nodec}
          onChange={(event) => {
            setData((prevData) => ({ ...prevData, tube_nodec: parseInt(event.target.value) }));
            const updatedata = { ...data, tube_nodec: parseInt(event.target.value) };
            localStorage.setItem('userResponses', JSON.stringify(updatedata));
          }}
        >
          <FormControlLabel value={1} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Yes</span>} />
          <FormControlLabel value={0} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >No</span>} />
          <FormControlLabel value={2} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Uncertain at this time</span>} />
          {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, width: "fit-content", background: formDataAll?.assocUserLeadGuide?.tube_nodec == data?.tube_nodec ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.tube_nodec ? formDataAll?.assocUserLeadGuide?.tube_nodec == data?.tube_nodec ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
        </RadioGroup>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>{user?._doc.userType === 1 ? "I" : "They"} would want to live as long as possible, even in severe pain.</Typography>
        <RadioGroup
          row
          aria-label="care-pref-loc"
          name="careprefloc"
          value={data?.pain_nodec}
          onChange={(event) => {
            setData((prevData) => ({ ...prevData, pain_nodec: parseInt(event.target.value) }));
            const updatedata = { ...data, pain_nodec: parseInt(event.target.value) };
            localStorage.setItem('userResponses', JSON.stringify(updatedata));
          }}
        >
          <FormControlLabel value={1} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Yes</span>} />
          <FormControlLabel value={0} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >No</span>} />
          <FormControlLabel value={2} control={<Radio />} label={<span style={{ fontSize: mediumFontSize + "px" }} >Uncertain at this time</span>} />
          {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, width: "fit-content", background: formDataAll?.assocUserLeadGuide?.pain_nodec == data?.pain_nodec ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.pain_nodec ? formDataAll?.assocUserLeadGuide?.pain_nodec == data?.pain_nodec ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
        </RadioGroup>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>Additional Information</b></Typography>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>Is there anything else {user?._doc.userType === 1 ? "you" : <i style={{ color: "#112123" }}> {user?._doc.userAssociated[0].name} </i>} want {user?._doc.userType === 1 ? "your" : "their"} family or healthcare providers to know about {user?._doc.userType === 1 ? "your" : "their"} end-of-life preferences?</Typography>
        <TextArea rows={6} value={data?.more_info_nodec}
          onChange={(event) => {
            setData((prevData) => ({ ...prevData, more_info_nodec: event.target.value }))
            const updatedata = { ...data, more_info_nodec: event.target.value };
            localStorage.setItem('userResponses', JSON.stringify(updatedata));
          }} style={{ width: "100%", fontSize: mediumFontSize!! }} placeholder='Type here' />
        <br></br>
        <br></br>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>Please add any additional instructions {user?._doc.userType === 1 ? "you" : "you think they may"} have for {user?._doc.userType === 1 ? "your" : "their"} end-of-life care. This can include, for example, grooming instructions for {user?._doc.userType === 1 ? "your" : "their"} hair or preferred clothing style, religious observances, music preferences, or people {user?._doc.userType === 1 ? "you" : "they"} would like included or excluded from visiting or being involved in {user?._doc.userType === 1 ? "your" : "their"} end-of-life care.</Typography>
        <TextArea rows={6} value={data?.add_instruct_nodec}
          onChange={(event) => {
            setData((prevData) => ({ ...prevData, add_instruct_nodec: event.target.value }))
            const updatedata = { ...data, add_instruct_nodec: event.target.value };
            localStorage.setItem('userResponses', JSON.stringify(updatedata));
          }} style={{ width: "100%", fontSize: mediumFontSize!! }} placeholder='Type here' />
        <br></br>
        <br></br>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>Please summarize {user?._doc.userType === 1 ? "your" : "their"} wishes for after {user?._doc.userType === 1 ? "your" : "their"} death, such as funeral and burial plans.</Typography>
        <TextArea rows={6} value={wishesAfterDeath}
          onChange={(event) => setWishesAfterDeath(event.target.value)} style={{ width: "100%", fontSize: mediumFontSize!! }} placeholder='Type here' />
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black" }}><b style={{ color: colors.aqua }}>NEXT STEPS: SHARING YOUR LEAD GUIDE</b></Typography>
        <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black" }}><b><i>It is critical that this guide, and any legal documents for {user?._doc.userType === 1 ? "your" : "their"} end-of-life care, be shared with the person(s) who will make decisions for {user?._doc.userType === 1 ? "you" : "them"} if {user?._doc.userType === 1 ? "you" : "they"} cannot express {user?._doc.userType === 1 ? "your" : "their"} end-of-life care preferences.</i></b></Typography>
        <br></br>
        <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black" }}><b><i>This section will help {user?._doc.userType === 1 ? "you" : "them"} make a plan to share this document and any legal documentation {user?._doc.userType === 1 ? "you" : "they"} have.</i></b></Typography>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>Place a checkmark next to all the steps {user?._doc.userType === 1 ? "you" : "you think they"} will take to share {user?._doc.userType === 1 ? "your" : "their"} LEAD Guide.</Typography>
        <FormGroup>
          <FormControlLabel

            control={
              <>
                <Checkbox checked={data?.share_lead_1 === 1 ? true : false} onChange={(event) => handleChangeShareLeadGuide('share_lead_1', event)} />
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>Send to {user?._doc.userType === 1 ? "my" : "their"} healthcare provider(s)</Typography>
                {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.share_lead_1 == data?.share_lead_1 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.share_lead_1 ? formDataAll?.assocUserLeadGuide?.share_lead_1 == data?.share_lead_1 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
              </>
            }
            label=""
          />
          <FormControlLabel

            control={
              <>
                <Checkbox checked={data?.share_lead_2 === 1 ? true : false} onChange={(event) => handleChangeShareLeadGuide('share_lead_2', event)} />
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>Send to {user?._doc.userType === 1 ? "my" : "their"} spouse/partner</Typography>
                {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.share_lead_2 == data?.share_lead_2 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.share_lead_2 ? formDataAll?.assocUserLeadGuide?.share_lead_2 == data?.share_lead_2 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
              </>
            }
            label=""
          />
          <FormControlLabel

            control={
              <>
                <Checkbox checked={data?.share_lead_3 === 1 ? true : false} onChange={(event) => handleChangeShareLeadGuide('share_lead_3', event)} />
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>Send to {user?._doc.userType === 1 ? "my" : "their"} children</Typography>
                {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.share_lead_3 == data?.share_lead_3 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.share_lead_3 ? formDataAll?.assocUserLeadGuide?.share_lead_3 == data?.share_lead_3 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
              </>
            }
            label=""
          />
          <Grid container>
            <FormControlLabel

              control={
                <>
                  <Checkbox checked={data?.share_lead_4 === 1 ? true : false} onChange={(event) => handleChangeShareLeadGuide('share_lead_4', event)} />
                  <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>Send to {user?._doc.userType === 1 ? "my" : "their"} care manager</Typography>
                  {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.share_lead_4 == data?.share_lead_4 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.share_lead_4 ? formDataAll?.assocUserLeadGuide?.share_lead_4 == data?.share_lead_4 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
                </>
              }
              label=""
            />
          </Grid>
          <FormControlLabel

            control={
              <>
                <Checkbox checked={data?.share_lead_5 === 1 ? true : false} onChange={(event) => handleChangeShareLeadGuide('share_lead_5', event)} />
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>Send to a close friend</Typography>
                {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.share_lead_5 == data?.share_lead_5 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.share_lead_5 ? formDataAll?.assocUserLeadGuide?.share_lead_5 == data?.share_lead_5 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
              </>
            }
            label=""
          />
          <FormControlLabel

            control={
              <>
                <Checkbox checked={data?.share_lead_6 === 1 ? true : false} onChange={(event) => handleChangeShareLeadGuide('share_lead_6', event)} />
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>Add to {user?._doc.userType === 1 ? "my" : "their"} legal paperwork, such as {user?._doc.userType === 1 ? "my" : "their"} will or {user?._doc.userType === 1 ? "my" : "their"} advance directive</Typography>
                {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.share_lead_6 == data?.share_lead_6 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.share_lead_6 ? formDataAll?.assocUserLeadGuide?.share_lead_6 == data?.share_lead_6 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
              </>
            }
            label=""
          />
          <FormControlLabel

            control={
              <>
                <Checkbox checked={data?.share_lead_7 === 1 ? true : false} onChange={(event) => handleChangeShareLeadGuide('share_lead_7', event)} />
                <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, marginRight: 10 }}>Discuss at a family meeting</Typography>
                {activity == 3 && <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, background: formDataAll?.assocUserLeadGuide?.share_lead_7 == data?.share_lead_7 ? "green" : "firebrick", color: "white", padding: "0px 10px", alignSelf: "center" }}>{formDataAll?.assocUserLeadGuide?.share_lead_7 ? formDataAll?.assocUserLeadGuide?.share_lead_7 == data?.share_lead_7 ? 'Agree' : 'Disagree' : "Your activity partner did not answer this question."}</Typography>}
              </>
            }
            label=""
          />
        </FormGroup>
        <br></br>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>Fill in below any additional steps {user?._doc.userType === 1 ? "you" : "you think they"} will take to share {user?._doc.userType === 1 ? "your" : "their"} LEAD Guide and any additional legal documents.</Typography>
        <TextArea rows={6} value={additionalLegalDoc} onChange={(event) => setAdditionalLegalDoc(event.target.value)} style={{ width: "100%", fontSize: mediumFontSize!! }} placeholder='Type here' />
        <br></br>
        <br></br>
        <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black" }}><b style={{ color: colors.aqua }}>UPDATING {user?._doc.userType === 1 ? "YOUR" : "THEIR"} LEAD GUIDE</b></Typography>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>Changes in {user?._doc.userType === 1 ? "your" : "their"} thinking skills or health may alter {user?._doc.userType === 1 ? "your" : "their"} preferences for end-of-life care.</Typography>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>We recommend revisiting the LEAD Guide at least yearly during {user?._doc.userType === 1 ? "your" : "their"} physical exam with {user?._doc.userType === 1 ? "your" : "their"} healthcare provider or when {user?._doc.userType === 1 ? "you" : "they"} or {user?._doc.userType === 1 ? "your" : "their"} care partner see a change in {user?._doc.userType === 1 ? "your" : "their"} medical condition.</Typography>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}>Below, write the date of {user?._doc.userType === 1 ? "your" : "their"} next physical exam or another date that will be convenient to review {user?._doc.userType === 1 ? "your" : "their"} LEAD Guide.</Typography>
        <br></br>
        <Grid container>
          <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black", marginRight: 2 }}>Date:</Typography>
          <Input value={month} onChange={(e) => setMonth(e.target.value)} placeholder="MM" style={{ width: "90px", fontSize: mediumFontSize!! }} />
          <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black", marginX: 2 }}>/</Typography>
          <Input value={day} onChange={(e) => setDay(parseInt(e.target.value))} placeholder="DD" style={{ width: "90px", fontSize: mediumFontSize!! }} />
          <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black", marginX: 2 }}>/</Typography>
          <Input value={year} onChange={(e) => setYear(parseInt(e.target.value))} placeholder="YYYY" style={{ width: "120px", fontSize: mediumFontSize!! }} />
        </Grid>
        <Button disabled={loading || (user?._doc.userType === 0 && user._doc._id !== selectedUser) || (user?._doc.userType === 0 && activity === 3)} onClick={handleSubmitLead} variant="contained" type='submit' sx={{ ...globals.button, fontSize: xSmallFontSize, marginTop: 10 }}>
          {loading && <div><Loader /></div>}
          Save My Answers
        </Button>
        <Box sx={{ height: "15px", width: "100%", background: colors.aqua, marginY: 10 }}></Box>
        <Box sx={{ display: "flex" }}>
          <Box justifyContent="center" alignItems="center" display="flex" sx={{ height: "150px", width: "350px", background: "#a91927", padding: 2 }}>
            <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize, textTransform: "uppercase", color: "white" }}>Glossary</Typography>
          </Box>
          <Box sx={{ marginLeft: 5 }}>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>Advance Directive/Living Will </b>– a legal document describing a person’s desires regarding their medical care in situations where they can no longer express their preferences.</Typography>
            <br></br>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>Attitudes </b>– a settled way of thinking or feeling about someone or something, typically reflected in a person's behavior.</Typography>
            <br></br>
          </Box>
        </Box>
        <br></br>
        <Grid container>
          <Grid item xs={9}>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>Cardiopulmonary Resuscitation (CPR) </b>– is an emergency life-saving procedure performed when the heart stops beating. Common CPR methods are chest compressions and mouth-to-mouth breathing. Sometimes an electric shock to the heart is used.</Typography>
          </Grid>
          <Grid item xs={3}>
            <img alt='info icon' src={CPR} style={{ margin: "auto", display: "block", width: '-webkit-fill-available' }} />

          </Grid>
        </Grid>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>Controlling when I die </b>– voluntarily stopping eating and drinking to hasten death or assistance provided by a healthcare provider. This care is for patients who are going to die and seek help in controlling the timing of their death through medical intervention.</Typography>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>Dementia </b>– usually a progressive condition (such as Alzheimer's disease) that is associated with multiple impairments in thinking skills, such as memory, language skills, and the inability to plan and initiate complex behavior.</Typography>
        <br></br>
        <Grid container>
          <Grid item xs={9}>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>Do Not Resuscitate Order (DNR) </b>– is an order that informs medical staff that they should not attempt to restart your heart if it stops beating (CPR). This order helps prevent unnecessary and unwanted invasive treatment at the end of life. This order may be a part of your state’s POLST form (Provider Order for Life-Sustaining Treatment).</Typography>
          </Grid>
          <Grid item xs={3}>
            <img alt='info icon' src={DNR} style={{ margin: "auto", display: "block", width: '-webkit-fill-available' }} />

          </Grid>
        </Grid>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>Emotional burden </b>– a situation where a person experiences emotional stress due to a variety of reasons such as guilt, obligation, or difficulty making decisions.</Typography>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>End of life </b>–refers to the final period of hours, days, weeks, and months in a person's life in which it is medically apparent that death is forthcoming.</Typography>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>End-of-life care </b>– is the term used to describe the support and medical care given during the time surrounding death.</Typography>
        <br></br>
        <Grid container>
          <Grid item xs={9}>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>Feeding tube </b>– a flexible tube is inserted through the nose or stomach area to provide nutrients by delivering liquid nutrition directly into the stomach or small intestine. This is used when a person does not eat or is not getting enough nutrition through eating.</Typography>
          </Grid>
          <Grid item xs={3}>
            <img alt='info icon' src={FeedingTube} style={{ margin: "auto", display: "block", width: '-webkit-fill-available' }} />
          </Grid>
        </Grid>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>Financial burden </b>– in the context of healthcare, this term describes a patient's monetary problems related to the cost of medical care that may lead to debt or bankruptcy.</Typography>
        <br></br>
        <Box sx={{ height: "15px", width: "100%", background: colors.aqua, marginY: 5 }}></Box>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>Length of life </b>– how long a person lives.</Typography>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>Life-prolonging measures or treatments </b>– medical care that prolongs life when organs stop working. Life-prolonging treatments can include a ventilator, a device to help your kidneys function (dialysis), a feeding tube, or a tube put into your vein to give fluids and medicines (intravenous, IV tube).</Typography>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>Location of death </b>– where you die, such as your home, the hospital, a long-term care center (nursing home, assisted living), or a residential hospice center.</Typography>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>Location of ongoing care </b>– where you receive care when you cannot care for yourself (home, nursing home, hospital, etc.).</Typography>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>Medical or health care power of attorney </b>– The person you legally appoint to make health care decisions when you cannot do so. Sometimes referred to as a health care proxy or proxy decision maker.</Typography>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>Nursing home </b>– is a public or private residential facility that provides a high level of long-term personal or nursing care for persons (such as the aged or the chronically ill) who cannot care for themselves properly.</Typography>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>Physical burden </b>– caregiver stress related to assisting with feeding, bathing, toileting, transferring from the bed to a chair, or other daily tasks.</Typography>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>Preferences </b>– things that you give priority to, or like better or best.</Typography>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>Quality of life </b>– the way each person describes how good or bad their life situation is.</Typography>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>Residential hospice center </b>– is a peaceful home-like residence where terminally ill people receive short-term hospice comfort care care.</Typography>
        <br></br>
        <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>Values </b>– what you find important or valuable in life, such as your ideals, your principles, and morals</Typography>
        <br></br>
        <Grid container>
          <Grid item xs={9}>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><b>Ventilator </b>– is a type of therapy that helps you breathe or breathes for you when you can’t breathe on your own. You might be on a ventilator during surgery or if your lungs aren’t working properly. Mechanical ventilation keeps your airways open, delivers oxygen, and removes carbon dioxide.</Typography>
            <br></br>
            <Typography sx={{ ...globals.body.mediumText, fontSize: mediumFontSize, color: "black" }}><i>Definitions provided or adapted from Merriam-Webster, Merck Manual, Centers for Disease Control, Medline Plus, & National Institutes of Health.</i></Typography>
          </Grid>
          <Grid item xs={3}>
            <img alt='info icon' src={vent} style={{ margin: "auto", display: "block", width: '-webkit-fill-available' }} />
          </Grid>
        </Grid>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        {/* <img alt='info icon' src={leadBack} style={{margin: "auto", display: "block", width: '-webkit-fill-available'}}/> */}
      </Grid>

    </Card>
  );
};

export default LeadGuide;