const colors = {
    // Default colors
    primary: '#007bff',
    secondary: '#6c757d',
    success: '#28a745',
    warning: '#ffc107',
    error: '#dc3545',

    // App theme colors
    white: '#ffffff',
    black: '#000000',
    dark: '#112123',
    aqua: '#03888E',
    gold: '#FFBF00',
    cyan: '#A3DEE0',
    grey: 'rgba(30, 30, 30, 0.5)',
  };
  
  export default colors;  