import { Card, CardContent, CardMedia, Tooltip, TooltipProps, Typography, tooltipClasses } from '@mui/material';
import globals from '../../styles/globals';
import colors from '../../styles/colors';
import { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../context/authcontext';
import { styled } from '@mui/styles';

// Define CSS styles for the card
const styles = {
  root: {
    position: "relative",
    // maxWidth: 700, // Adjust the width as needed
    height: 450,
    margin: 1, // Add margin for spacing between cards
    padding: '1rem',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Box shadow
    // borderRadius: '10px', // Rounded corners
    textAlign: 'center', // Centered content
  },
  media: {
    height: 250, // Adjust the height as needed for the profile picture
  },
}

interface TeamMemberProps {
  name: string;
  position: string;
  description: string;
  imageSrc: string;
}

const TeamMemberCard: React.FC<TeamMemberProps> = ({ name, position, description, imageSrc }) => {
  const [cardHeight, setCardHeight] = useState(450);
  const [cardWidth, setCardWidth] = useState(300);
  const { largeFontSize, mediumFontSize, xSmallFontSize } = useContext(AuthContext);

  useEffect(() => {
    if (largeFontSize !== null && mediumFontSize !== null) {
      console.log('largeFontSize :', largeFontSize);
      const baseHeight = 450;
      const baseWidth = 300;
      const fontSizeMultiplier = largeFontSize / 25;

      const descriptionLines = 3;
      const lineHeight = mediumFontSize * 1.2;

      let calculatedHeight = baseHeight + (lineHeight * (descriptionLines - 1) * fontSizeMultiplier);
      let calculatedWidth = baseWidth + (fontSizeMultiplier * 30); 
      if (largeFontSize > 30) {
        calculatedHeight += (largeFontSize - 30) * 10; 
        calculatedWidth += (largeFontSize - 30); 
      }

      setCardHeight(calculatedHeight);
      setCardWidth(calculatedWidth);
    }
  }, [largeFontSize, mediumFontSize]);

  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 1400,
    },
  });

  return (
    // <Tooltip title={<Typography style={{ fontSize: xSmallFontSize!! }}> {description} </Typography>} arrow >
      <Card sx={styles.root} style={{  maxHeight: `${cardHeight}px`, height: "auto", maxWidth: `${cardWidth}px`, overflow: 'visible'}}>
        <CardMedia sx={{...styles.media, height: 225+(largeFontSize!!*2.5), backgroundPosition: name === "Nancy Aruscavage"? "0px 6px": name === "Sara Bybee"? "0px -70px": name === "Jordana Clayton" ? "0px -120px": name === "Kara Dassel" ? "0px -45px": "0px 0px"}} image={imageSrc} title={name} />
        <CardContent>
          <Typography className="resourcecontent" variant="h5" style={{ ...globals.heading.largeText, fontSize: largeFontSize!! }}>
            {name}
          </Typography>
          <Typography variant="h5" style={{ ...globals.heading.mediumText, fontSize: mediumFontSize!!, color: colors.grey }}>
            {position}
          </Typography>
          <CustomWidthTooltip placement='top-start' title={<Typography style={{fontSize: xSmallFontSize!!}}> {description} </Typography>} arrow 
          PopperProps={{
            modifiers: [
              {
                name: 'zIndex',
                options: {
                  zIndex: 1500, // Adjust as necessary
                },
              },
            ],
          }}>
          <Typography
            style={{
              ...globals.body.mediumText,
              fontSize: mediumFontSize!!,
              color: colors.aqua,
              marginTop: 5,
              display: '-webkit-box',
              WebkitLineClamp: 3, // Number of lines to show
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {description}
          </Typography>
          </CustomWidthTooltip>
        </CardContent>
      </Card>
    // </Tooltip>
  );
};

export default TeamMemberCard;