import React, { useContext } from 'react';
import { Box, Card, List, Typography, useMediaQuery, useTheme } from '@mui/material';
import globals from '../../styles/globals';
import color from '../../styles/colors';
import { IMAGES_URL } from '../../constants/constants';
import { AuthContext } from '../../context/authcontext';

interface Props {
    count: number[];
    current: number;
    completed: number[];
    progress: any[];
    cardSelected: (param:any) => void;
  }
const Pagination: React.FC<Props> = ({count, completed, current, progress, cardSelected}) => {
  const { largeFontSize, smallFontSize} = useContext(AuthContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));    

  return (
    <List>
        <Box sx={{display:"flex", justifyContent: "center"}}>
          {progress.slice(0, 4).map((item, index) => (
            <Card onClick={()=> (item.currentTask ===1 || item.progress === 1) && cardSelected(index)} className={`card2 ${current === index && "selectedCard"}`} key={index} sx={{...globals.pageCard, width: isMobile?"35px":"80px", marginRight:index===6?0:'2px', backgroundColor: item.progress===1 ? color.aqua: item.currentTask === 1?color.gold:color.cyan, borderRadius: index===0? "25px 0px 0px 25px":index===3?"0px 25px 25px 0px":0,color:item.currentTask===1?'white':'black'}}>
              { item.progress===1 ? <img alt='check' style={{width: isMobile? "18px":"30px"}} src={`${IMAGES_URL}completed.png`}/> : <Typography sx={ {... isMobile? globals.heading.smallText : globals.heading.largeText, fontSize: isMobile ? smallFontSize : largeFontSize}}> {index+1}</Typography>}
            </Card>
          ))}
        </Box>
        
        <Box sx={{display:"flex", marginTop: "20px", justifyContent:"center"}}>
            <Typography style={{...globals.body.smallText, fontWeight: "bold", fontSize: smallFontSize!!}}>{(progress.filter(it=> it.progress===1)).length}&nbsp;</Typography>
            <Typography style={{...globals.body.smallText, fontSize: smallFontSize!!}}>out of&nbsp;</Typography>
            <Typography style={{...globals.body.smallText, fontWeight: "bold",fontSize: smallFontSize!!}}>{progress.slice(0,4).length}&nbsp;</Typography>
            <Typography style={{...globals.body.smallText, fontSize: smallFontSize!!}}>activities completed</Typography>
        </Box>
    </List>
  );
};

export default Pagination;